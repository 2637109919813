import { db, storage } from "../firebase";
import {
  query,
  endAt,
  setDoc,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  where,
  collectionGroup,
} from "firebase/firestore";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { setTotalPages, setFirstPage, setResetPage } from "./pagination";
import uuid from "uuid";
// ADD ORDER
export const addOrder = (order) => ({
  type: "ADD_ORDER",
  order,
});

export const startAddOrder = (orderData = {}) => {
  return async (dispatch) => {
    const {
      receiptNo = "",
      hn = "",
      amount = "",
      salePackage = "",
      mask = "",
      maskSize = "",
      accessories = "",
      receiptDate = "",
      refHospital = "",
      refPhysician = "",
      note = "",
      productId = "",
      productSn = "",
      deviceNo = "",
      status = "",
      activity = "",
      warrantyPeriod = "",
      warrantyStartDate = "",
      warrantyEndDate = "",
      createdBy = "",
      updatedAt = 0,
      createdAt = 0,
      firstname = "",
      lastname = "",
      sales = "",
      nodeRef = "",
      refCustomer = "",
      mobile = "",
      address = "",
    } = orderData;
    const order = {
      receiptNo,
      hn,
      amount,
      salePackage,
      mask,
      maskSize,
      accessories,
      receiptDate,
      refHospital,
      refPhysician,
      note,
      productId,
      productSn,
      deviceNo,
      status,
      activity,
      warrantyPeriod,
      warrantyStartDate,
      warrantyEndDate,
      createdBy,
      updatedAt,
      createdAt,
      firstname,
      lastname,
      sales,
      nodeRef,
      refCustomer,
      mobile,
      address,
    };
    // console.log("action note", note);

    const docRef = doc(collection(db, "orders"));
    await setDoc(docRef, order)
      .then(async () => {
        // console.log("set doc", docRef);
        updateDoc(doc(db, "orders", docRef.id), {
          id: docRef.id,
        });
        const patientRef = doc(db, "patients", order.hn, "orders", docRef.id);
        await setDoc(patientRef, {
          receiptNo: order.receiptNo,
          productId: order.productId,
          productSn: order.productSn,
          activity: order.activity,
          receiptDate: order.receiptDate,
          status: order.status,
          createdAt: order.createdAt,
          sales: order.sales,
          createdBy: order.createdBy,
        });
        //create logs
        const productRef = doc(
          db,
          "products",
          order.productId,
          "logs",
          docRef.id
        );
        await setDoc(productRef, {
          hn: order.hn,
          firstname: order.firstname,
          lastname: order.lastname,
          receiptNo: order.receiptNo,
          productId: order.productId,
          productSn: order.productSn,
          activity: order.activity,
          status: order.status,
          createdAt: order.createdAt,
          sales: order.sales,
          createdBy: order.createdBy,
        });

        dispatch(
          addOrder({
            id: docRef.id,
            ...order,
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

// REMOVE ORDER
export const removeOrder = ({ id } = {}) => ({
  type: "REMOVE_ORDER",
  id,
});

export const startRemoveOrder = ({ id }) => {
  return async (dispatch) => {
    const docRef = doc(db, "orders", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      docSnap.data();
      if (docSnap.data().activity === "purchase") {
        const cpapRef = doc(db, "products", docSnap.data().productId);
        await updateDoc(cpapRef, {
          status: "ว่าง",
        });
      }

      // await updateDoc(docRef, { status: "ยกเลิก", updatedAt: new Date() });
      // const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");
      // await updateDoc(sequenceRef, {
      //   reserve: [...sequenceRef.data().reserve, docSnap.data().receiptNo],
      // });
    } else {
      console.log("No such document!");
    }

    return await deleteDoc(docRef).then(() => {
      dispatch(removeOrder({ id }));
    });
  };
};

// EDIT ORDER
export const editOrder = (id, updates) => ({
  type: "EDIT_ORDER",
  id,
  updates,
});

export const startEditOrder = (id, updates) => {
  return async (dispatch) => {
    const docRef = doc(db, "orders", id);

    // console.log("startEditCpap", updates);
    const f = [];

    return await updateDoc(docRef, updates).then(async () => {
      const patientRef = doc(db, "patients", updates.hn, "orders", id);

      await updateDoc(patientRef, {
        activity: updates.activity,
        status: updates.status,
        receiptDate: updates.receiptDate,
        createdAt: updates.updatedAt,
        sales: updates.sales,
      });

      const productRef = doc(db, "products", updates.productId, "logs", id);
      await updateDoc(productRef, {
        activity: updates.activity,
        status: updates.status,
        createdAt: updates.updatedAt,
        sales: updates.sales,
      });

      dispatch(editOrder(id, updates));
    });
  };
};

// SET ORDERS
export const setOrders = (orders) => ({
  type: "SET_ORDER",
  orders,
});

export const startSetOrders = ({ text, searchBy, limitPage, sortByStatus }) => {
  return async (dispatch) => {
    let queryConstraints = [];
    dispatch(setResetPage());
    const docRef = collection(db, "orders");
    queryConstraints.push(orderBy("receiptDate", "desc"));
    queryConstraints.push(limit(limitPage));

    if (text != "") {
      queryConstraints.push(where(searchBy, ">=", text));
      queryConstraints.push(where(searchBy, "<=", text + "\uf8ff"));
    }
    if (sortByStatus !== "ทั้งหมด") {
      queryConstraints.push(where("status", "==", sortByStatus));
    }

    const queryRef = query(docRef, ...queryConstraints);
    const count = await getCountFromServer(
      query(
        collection(db, "orders"),
        orderBy("receiptDate", "desc"),
        where(searchBy, ">=", text),
        where(searchBy, "<=", text + "\uf8ff")
      )
    );

    return await getDocs(queryRef).then((snapshot) => {
      const orders = [];
      const latestPage = snapshot.docs[snapshot.docs.length - 1];
      const firstPage = snapshot.docs[0];
      snapshot.forEach(async (childSnapshot) => {
        orders.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });
      const totalPages = Math.ceil(count.data().count / limitPage);

      dispatch(setTotalPages(totalPages, latestPage, firstPage));
      dispatch(setOrders(orders));
    });
  };
};

export const startSetNextOrders = ({
  text,
  searchBy,
  limitPage,
  latestPage,
  sortByStatus,
}) => {
  return async (dispatch) => {
    let queryConstraints = [];

    const docRef = collection(db, "orders");
    queryConstraints.push(orderBy(searchBy));
    queryConstraints.push(limit(limitPage));

    if (text != "") {
      queryConstraints.push(where(searchBy, ">=", text));
      queryConstraints.push(where(searchBy, "<=", text + "\uf8ff"));
    }
    if (sortByStatus !== "ทั้งหมด") {
      queryConstraints.push(where("status", "==", sortByStatus));
    }

    queryConstraints.push(startAfter(latestPage));

    const queryRef = query(docRef, ...queryConstraints);
    const count = await getCountFromServer(
      query(
        collection(db, "orders"),
        orderBy(searchBy, "desc"),
        where(searchBy, ">=", text),
        where(searchBy, "<=", text + "\uf8ff")
      )
    );

    return await getDocs(queryRef).then((snapshot) => {
      const orders = [];
      const latestPage = snapshot.docs[snapshot.docs.length - 1];
      const firstPage = snapshot.docs[0];
      snapshot.forEach(async (childSnapshot) => {
        orders.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });
      const totalPages = Math.ceil(count.data().count / limitPage);

      dispatch(setTotalPages(totalPages, latestPage, firstPage));
      dispatch(setOrders(orders));
    });
  };
};
export const startSetPrevOrders = ({
  text,
  searchBy,
  limitPage,
  firstPage,
  sortByStatus,
}) => {
  return async (dispatch) => {
    let queryConstraints = [];

    const docRef = collection(db, "orders");
    queryConstraints.push(orderBy("receiptDate", "desc"));
    queryConstraints.push(limitToLast(limitPage));

    if (text !== "") {
      queryConstraints.push(where(searchBy, ">=", text));
      queryConstraints.push(where(searchBy, "<=", text + "\uf8ff"));
    }
    if (sortByStatus !== "ทั้งหมด") {
      queryConstraints.push(where("status", "==", sortByStatus));
    }

    queryConstraints.push(endBefore(firstPage));

    const queryRef = query(docRef, ...queryConstraints);
    const count = await getCountFromServer(
      query(
        collection(db, "orders"),
        orderBy("receiptDate", "desc"),
        where(searchBy, ">=", text),
        where(searchBy, "<=", text + "\uf8ff")
      )
    );

    return await getDocs(queryRef).then((snapshot) => {
      const orders = [];
      const latestPage = snapshot.docs[snapshot.docs.length - 1];
      const newFirstPage = snapshot.docs[0]; // Changed to newFirstPage
      snapshot.forEach((childSnapshot) => {
        orders.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });
      const totalPages = Math.ceil(count.data().count / limitPage);

      dispatch(setTotalPages(totalPages, latestPage, newFirstPage));
      dispatch(setOrders(orders));
    });
  };
};
