import React, { Fragment, useRef } from "react";
import _ from "lodash";

import RegisterFields from "./RegisterFields";
import orderFormFields from "./orderFormFields";

export const OrderForm = (props) => {
  const inputRef = useRef({});

  const renderFields = () => {
    return _.map(
      orderFormFields,
      ({ label, name, type, options, required, disabled, description }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={
              name === "receiptNo"
                ? props.saleOrderNumber
                : name === "warrantyEndDate"
                ? props.warrantyEnd
                  ? new Date(props.warrantyEnd).toLocaleDateString("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : ""
                : name === "warrantyStartDate"
                ? props.warrantyStart
                  ? new Date(props.warrantyStart).toLocaleDateString("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : ""
                : name === "receiptDate"
                ? props.warrantyStart
                  ? new Date(props.receiptDate).toLocaleDateString("fr-CA", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : ""
                : inputRef.current.name
            }
            fileNames={props.fileNames}
            options={options}
            required={required}
            onChange={props.onChange(name)}
            disabled={disabled}
            description={description}
          />
        );
      }
    );
  };

  return <Fragment>{renderFields()}</Fragment>;
};
