import React, { useEffect, useState } from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { uuid } from "uuidv4";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Error404 from "./pages/Error404";

import Home from "./components/Admin/Home"; ///doctor-details
import AdminList from "./components/Admin/AdminList"; ///doctors

import TechItemList from "./components/Items/TechnicianItems/TechItemList";

import NodeList from "./components/Node/NodeList";

// import BookingSummary from "./components/Booking/BookingSummary"; ///Calendar
import Booking from "./components/Booking/Booking"; ///Calendar
import BookingTable from "./components/Booking/BookingTable"; ///Calendar
// import BookingForm from "./components/Booking/BookingForm"; ///jQueryValidationForm

import PatientList from "./components/Patient/PatientList"; /// customer
import PatientPage from "./components/Patient/PatientPage"; ///patient-detail
import PatientRegister from "./components/Patient/PatientRegister"; ///jQueryValidationForm

import TrialCalendar from "./components/CPAP/TrialCalendar"; ///jQueryValidationForm

// import EquipmentList from "./components/Equipment/EquipmentList"; //order
// import EquipmentDetail from "./components/Equipment/EquipmentDetail"; //datatable
// import EquipmentBorrow from "./components/Equipment/EquipmentBorrow"; ///jQueryValidationForm
// import EquipmentRegister from "./components/Equipment/EquipmentRegister"; ///jQueryValidationForm
import { useAuth } from "../context/AuthContext";
import PrivateRoute from "./PrivateRoute";

// Form
import Demographic from "./components/Patient/form/DemographicRevC"; //personal information rev.C
import PreSleepInformation from "./components/Patient/form/PreSleepInformation";
import SleepHabit from "./components/Patient/form/SleepHabit";
import NightTimeHabit from "./components/Patient/form/NightTimeHabit";
import BerlinQuestionnaire from "./components/Patient/form/BerlinQuestionnaire";
import DaytimeBehavior from "./components/Patient/form/DaytimeBehavior";
import StopbangQuestionnaire from "./components/Patient/form/STOPBANGQuestionnaire";
import PostQuestionnaires from "./components/Patient/form/PostQuestionnaires";
import ShortSAQLI from "./components/Patient/form/ShortSAQLI";
import Followup from "./components/Followup/Calendar";

import CpapRegister from "./components/CPAP/CpapRegister";
import CpapList from "./components/CPAP/CpapList";
import CpapPage from "./components/CPAP/CpapPage";

import BookingList from "./components/Booking/BookingList";
import FollowupList from "./components/Followup/FollowupList";
import ProductList from "./components/Sales/ProductList";
import ProductRegister from "./components/Sales/ProductRegister";
import ProductOrderRegister from "./components/Sales/ProductOrderRegister";
import ProductOrderList from "./components/Sales/ProductOrderList";
import ProductPage from "./components/Sales/ProductPage";
import ItemList from "./components/Items/ItemList";
import DoctorItemList from "./components/Items/DoctorItems/DoctorItemList";
import ExportPage from "./components/Export/Export";
import SaleItemList from "./components/Items/SaleItems/SaleItemList";
import Backup from "./components/Backup/Backup";
import Dashboard from "./components/Admin/Dashboard";
import AccountantItemList from "./components/Items/Accountants/AccItemList";
import Setting from "./components/Settings/Settings";
import BookingUrgent from "./components/Booking/BookingUrgent";

const Markup = () => {
  const { currenUser } = useAuth();
  let path = window.location.pathname;
  // console.log("page => ", path);
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  // console.log("pagePath => ", pagePath);

  return (
    // <Router basename="/demo/fito/">
    <Router>
      <Switch>
        <Route key={uuid()} exact path={"/page-login"} component={Login} />
        <Route
          key={uuid()}
          exact
          path={"/page-forgot-password"}
          component={ForgotPassword}
        />
        <Route key={uuid()} path="/404" component={Error404} />
        <div
          id={`${!pagePath ? "main-wrapper" : ""}`}
          className={`${!pagePath ? "show" : "mh100vh"}`}
        >
          {!pagePath && <Nav />}
          <div
            className={` ${!pagePath ? "content-body" : ""}`}
            style={{ paddingTop: "0px", marginTop: "3rem" }}
          >
            <div
              className={`${!pagePath ? "container-fluid " : ""}`}
              style={{ minHeight: "720px", "margin-bottom": "80px" }}
            >
              <PrivateRoute
                key={uuid()}
                exact
                path={"/dashboard"}
                component={Dashboard}
              />
              <PrivateRoute key={uuid()} exact path={"/"} component={Home} />
              <PrivateRoute
                key={uuid()}
                path={"/admin-list"}
                component={AdminList}
                disableRoles={[""]}
              />

              <PrivateRoute
                key={uuid()}
                path={"/node-list"}
                component={NodeList}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/booking-calendar"}
                component={Booking}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/booking-table"}
                component={BookingList}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/booking-urgent"}
                component={BookingUrgent}
                disableRoles={[""]}
              />


              <PrivateRoute
                key={uuid()}
                exact
                path={"/followup-calendar"}
                component={Followup}
                disableRoles={["staff"]}
              />

              <PrivateRoute
                key={uuid()}
                exact
                path={"/followup-table"}
                component={FollowupList}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/patient-list"}
                component={PatientList}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/patient-page/:id"}
                component={PatientPage}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                path={"/patient-register"}
                component={PatientRegister}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/demographic/:id/:uuid"}
                component={Demographic}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/preSleepInformation/:id/:uuid"}
                component={PreSleepInformation}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/sleephabit/:id/:uuid"}
                component={SleepHabit}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/nightTimeHabit/:id/:uuid"}
                component={NightTimeHabit}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/daytimeBehavior/:id/:uuid"}
                component={DaytimeBehavior}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/BerlinQuestionnaire/:id/:uuid"}
                component={BerlinQuestionnaire}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/stopbangQuestionnaire/:id/:uuid"}
                component={StopbangQuestionnaire}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/postQuestionnaires/:id/:uuid"}
                component={PostQuestionnaires}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/shortSAQLI/:id/:uuid"}
                component={ShortSAQLI}
                disableRoles={[""]}
              />

              <PrivateRoute
                key={uuid()}
                exact
                path={"/cpap-register"}
                component={CpapRegister}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/cpap-page/:id"}
                component={CpapPage}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/cpap-list"}
                component={CpapList}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/product-register"}
                component={ProductRegister}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/product-list"}
                component={ProductList}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/product-order/:id/:sn/:dn"}
                component={ProductOrderRegister}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/product-page/:id"}
                component={ProductPage}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/order-list"}
                component={ProductOrderList}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/item-list"}
                component={ItemList}
                disableRoles={["staff"]}
              />
              <PrivateRoute
                key={uuid()}
                exact
                path={"/trial-calendar"}
                component={TrialCalendar}
                disableRoles={["staff"]}
              />

              <PrivateRoute
                key={uuid()}
                path={"/sale-list"}
                component={SaleItemList}
                disableRoles={[""]}
              />

              <PrivateRoute
                key={uuid()}
                path={"/tech-list"}
                component={TechItemList}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                path={"/doctor-list"}
                component={DoctorItemList}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                path={"/accountant-list"}
                component={AccountantItemList}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                path={"/export"}
                component={ExportPage}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                path={"/backup"}
                component={Backup}
                disableRoles={[""]}
              />
              <PrivateRoute
                key={uuid()}
                path={"/settings"}
                component={Setting}
                disableRoles={[""]}
              />
            </div>
          </div>
          {!pagePath && <Footer />}
        </div>
      </Switch>
      {/* <Setting /> */}
    </Router>
  );
};

export default Markup;
