import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Modal,
  Button,
  Dropdown,
  Table,
} from "react-bootstrap";

import { db } from "../../../firebase";
import Toast from "../toast/SetToast";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

import {
  query,
  endAt,
  getDocs,
  collection,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  startAt,
  getCountFromServer,
  where,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  getDoc,
  collectionGroup,
} from "firebase/firestore";
import { notifyError, notifyTopRight } from "../toast/SetToast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";

const nodeList = ["BD"];
const typeList = [
  "Full Night Sleep Study",
  "Split Night Sleep Study",
  "CPAP Titration",
  "Home Full Night Sleep Study",
  "Overnight Pulse Oximetry Study",
  // "MSLT",
  // "MWT",
];

const statusList = [
  "Sleep Test Scheduled",
  "Technician Scored",
  "Doctor Scored",
  "Post-Consult",
  "Sale Follow Up",
  "Ship",
  "Ship [CPAP Purchase]",
  "Ship [CPAP Trial]",
  "Ship [CPAP Rental]",
  "Cancel",
  "Failure",
];
const statusOrder = {
  "Sleep Test Scheduled": 1,
  "Technician Scored": 2,
  "Doctor Scored": 3,
  "Post-Consult": 4,
  "Sale Follow Up": 5,
  Ship: 6,
  "Ship [CPAP Purchase]": 7,
  "Ship [CPAP Trial]": 8,
  "Ship [CPAP Rental]": 9,
  Cancel: 10,
  Failure: 11,
};
const colorList = [
  "#005DEA",
  // brgiht blue
  "#00CEAB",
  // light blue
  "#711685",
  //  primary color
  "#F4B400",
  //  post consult yellow | Google yellow
  "#FF99CC",
  "#64a338",
  "#64a338",
  "#64a338",
  "#64a338",
  // ship green
  "#a8a8a8",
  // cancel gray
  "#dd0102",
  // failure red
];
const orderList = ["R1", "R2", "R3", "R4", "R5", "จองคิว"];
const paymentPrivilages = [
  "กรุณาเลือก",
  "ชำระเงินเอง",
  "ประกันสุขภาพถ้วนหน้า",
  "ประกันสังคม",
  "กรมบัญชีกลาง",
  "กรุงเทพมหานคร",
  "ต้นสังกัด",
  "นักศึกษา มธ.",
  "โครงการวิจัยรับค่าบริการล่วงหน้า",
  "องค์การปกครองส่วนท้องถิ่น",
  "โครงการตรวจสุขภาพรับค่าบริการล่วงหน้า",
  "ผ่อนชำระ/นิติกร",
  "อุบัติเหตุและฉุกเฉินนอกโรงพยาบาล(EMS)",
  "ปลัดกระทรวงสาธารณะสุข",
  "โครงการ 85 ปี",
  "อื่นๆ",
];
const eventTmp = {
  title: "",
  start: "",
  id: "",
  extendedProps: {
    order: "",
    hn: "",
    type: "",
    status: "",
    firstname: "",
    lastname: "",
    nationalId: "",
    healthInsurance: "กรุณาเลือก",
    phone: "",
    note: "",
    node: "",
    customer: "",
    items: [],
    totalValue: 0,
    time: "",
    technician: "",
    doctor: "",
  },
};
export const sendNotification = httpsCallable(functions, "sendNotification");

export const PatientDropDownForm = ({
  title = "",
  htmlFor = "",
  id = "",
  name = "",
  refvalue,
  placeholder = "Please select",
  selected = "",
  options = [""],
  onChange,
  disabled,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor={htmlFor}>
        {title}
        <span className="text-danger"></span>
      </label>
      <div className="col-lg-5">
        <select
          className="form-control"
          id={id}
          name={name}
          ref={refvalue}
          onChange={onChange}
          disabled={disabled}
        >
          {options.map((value) => (
            <option
              value={value}
              selected={selected == value ? "selected" : ""}
            >
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const PatientCheckboxForm = ({
  title = "",
  options = [""],
  name = "",
  defaultCheckedValues = [],
  mt = "row mt-3",
  refValue, // Pass the ref for managing state
}) => {
  return (
    <fieldset className="form-group">
      <div className={mt}>
        <label className="col-xl-3 col-form-label pt-0">{title}</label>
        <div className="col-xl-5">
          {options.map((value, index) => (
            <div className="form-check" key={index}>
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={name}
                  value={value}
                  defaultChecked={defaultCheckedValues.includes(value)} // Pre-check based on default values
                  onChange={(e) => {
                    if (e.target.checked) {
                      refValue.current = value; // Update ref when checked
                    } else {
                      refValue.current = ""; // Clear ref when unchecked
                    }
                    console.log("Checked value:", refValue.current); // Debug log
                  }}
                />
                {value}
              </label>
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
};

export const PatientTextInfo = ({ title = "", value = "", bold = false }) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor="val-username">
        {title}
      </label>
      <div className="col-lg-9">
        {bold ? (
          <>
            <b>{value}</b>
          </>
        ) : (
          <>
            <text>{value}</text>
          </>
        )}
      </div>
    </div>
  );
};
export const PatientLinkInfo = ({
  title = "",
  value = "",
  bold = false,
  path,
}) => {
  return (
    <div className="form-group row">
      <label className="col-lg-3 col-form-label" htmlFor="val-username">
        {title}
      </label>
      <div className="col-lg-8">
        {bold ? (
          <>
            <Link to={path}>
              <b>{value}</b>
            </Link>
          </>
        ) : (
          <>
            <Link to={path}>
              <text>{value}</text>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

const setEvent = async (
  eventAdd,
  oldEvent = undefined,
  nodeIdDocs,
  nodeNameDocs
) => {
  if (oldEvent == undefined) {
    oldEvent = eventAdd;
  } else {
    console.log("oldEvent not undefined");
  }
  try {
    if (
      eventAdd.extendedProps.uuid == undefined ||
      eventAdd.extendedProps.uuid == null ||
      eventAdd.extendedProps.node != oldEvent.extendedProps.node
    ) {
      const nodeIndex = nodeNameDocs.indexOf(eventAdd.extendedProps.node);
      const nodeId = nodeIdDocs[nodeIndex];
      const ref = doc(collection(db, "nodes", nodeId, "bookings"));
      await setDoc(ref, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        end: eventAdd.end,
        color: eventAdd.color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          nationalId: eventAdd.extendedProps.nationalId,
          healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          node: eventAdd.extendedProps.node,
          customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          techrecord: eventAdd.extendedProps.techrecord,
          referDoctor: eventAdd.extendedProps.referDoctor,
          latestSleepStudyDate: eventAdd.extendedProps.latestSleepStudyDate,
          ahi: eventAdd.extendedProps.ahi,
          hospitalNumber: eventAdd.extendedProps.hospitalNumber,
          createBy: eventAdd.extendedProps.createBy
            ? eventAdd.extendedProps.createBy
            : "",
          gender: eventAdd.extendedProps.gender
            ? eventAdd.extendedProps.gender
            : "",
        },
      });
      eventAdd.extendedProps.uuid = ref.id;
      const refUpdate = doc(
        db,
        "nodes",
        nodeId,
        "bookings",
        eventAdd.extendedProps.uuid
      );

      await updateDoc(refUpdate, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        color: eventAdd.color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          uuid: eventAdd.extendedProps.uuid,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          nationalId: eventAdd.extendedProps.nationalId,
          healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          node: eventAdd.extendedProps.node,
          customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          techrecord: eventAdd.extendedProps.techrecord,
          referDoctor: eventAdd.extendedProps.referDoctor,
          latestSleepStudyDate: eventAdd.extendedProps.latestSleepStudyDate,
          ahi: eventAdd.extendedProps.ahi,
          hospitalNumber: eventAdd.extendedProps.hospitalNumber,
          createBy: eventAdd.extendedProps.createBy
            ? eventAdd.extendedProps.createBy
            : "",
          gender: eventAdd.extendedProps.gender
            ? eventAdd.extendedProps.gender
            : "",
        },
      });
      if (
        eventAdd.extendedProps.status == "Sleep Test Scheduled" ||
        eventAdd.extendedProps.status == "Cancel" ||
        eventAdd.extendedProps.status == "Technician Scored"
      ) {
        // setNotifyTech(eventAdd);
      }
      if (eventAdd.extendedProps.node != oldEvent.extendedProps.node) {
        const nodeIndexOld = nodeNameDocs.indexOf(oldEvent.extendedProps.node);
        const nodeIdOld = nodeIdDocs[nodeIndexOld];
        const refDelete = doc(
          db,
          "nodes",
          // this.nodeName,
          nodeIdOld,
          "bookings",
          oldEvent.extendedProps.uuid
        );
        await deleteDoc(refDelete);
        console.log("deleted from old node !");

        // notify doctor and tech
        if (
          eventAdd.extendedProps.status == "Sleep Test Scheduled" ||
          eventAdd.extendedProps.status == "Cancel" ||
          eventAdd.extendedProps.status == "Technician Scored"
        ) {
          // setNotifyTech(eventAdd);
        }
        if (
          eventAdd.extendedProps.status == "Technician Scored" ||
          eventAdd.extendedProps.status == "Doctor Scored"
        ) {
          setNotifyDoctor(eventAdd);
        }
      }
    } else {
      const nodeIndex = nodeNameDocs.indexOf(eventAdd.extendedProps.node);
      const nodeId = nodeIdDocs[nodeIndex];
      const refUpdate = doc(
        db,
        "nodes",
        nodeId,
        "bookings",
        eventAdd.extendedProps.uuid
      );
      const docSnap = await getDoc(refUpdate);

      await updateDoc(refUpdate, {
        title: eventAdd.title,
        id: eventAdd.id,
        start: new Date(eventAdd.start),
        color: eventAdd.color,
        extendedProps: {
          order: eventAdd.extendedProps.order,
          hn: eventAdd.extendedProps.hn,
          type: eventAdd.extendedProps.type,
          status: eventAdd.extendedProps.status,
          uuid: eventAdd.extendedProps.uuid,
          firstname: eventAdd.extendedProps.firstname,
          lastname: eventAdd.extendedProps.lastname,
          nationalId: eventAdd.extendedProps.nationalId,
          healthInsurance: eventAdd.extendedProps.healthInsurance,
          phone: eventAdd.extendedProps.phone,
          node: eventAdd.extendedProps.node,
          customer: eventAdd.extendedProps.customer,
          note: eventAdd.extendedProps.note,
          items: eventAdd.extendedProps.items,
          totalValue: eventAdd.extendedProps.totalValue,
          time: eventAdd.extendedProps.time,
          doctor: eventAdd.extendedProps.doctor,
          technician: eventAdd.extendedProps.technician,
          techrecord: eventAdd.extendedProps.techrecord,
          referDoctor: eventAdd.extendedProps.referDoctor,
          latestSleepStudyDate:
            eventAdd.extendedProps.latestSleepStudyDate !== undefined
              ? eventAdd.extendedProps.latestSleepStudyDate
              : docSnap.data().extendedProps.latestSleepStudyDate,
          ahi: eventAdd.extendedProps.ahi,
          hospitalNumber: eventAdd.extendedProps.hospitalNumber,
          createBy: eventAdd.extendedProps.createBy
            ? eventAdd.extendedProps.createBy
            : "",
          gender: eventAdd.extendedProps.gender
            ? eventAdd.extendedProps.gender
            : "",
        },
      });
      if (
        eventAdd.extendedProps.status == "Sleep Test Scheduled" ||
        eventAdd.extendedProps.status == "Cancel" ||
        eventAdd.extendedProps.status == "Technician Scored"
      ) {
        // setNotifyTech(eventAdd);
      }
      if (
        eventAdd.extendedProps.status == "Technician Scored" ||
        eventAdd.extendedProps.status == "Doctor Scored"
      ) {
        setNotifyDoctor(eventAdd);
      }
    }
    notifyTopRight("Update booking success !");
    return "OK";
  } catch (err) {
    console.log(err);
    notifyError("Please fill out patient information !");
    return "Err";
  }
};

const setNotifyDoctor = async (event) => {
  const sleepstudyInfo = {
    title: event.title,
    date: event.startDate,
    status: event.extendedProps.status,
    type: event.extendedProps.type,
    firstname: event.extendedProps.firstname,
    lastname: event.extendedProps.lastname,
    doctor: event.extendedProps.doctor,
    note: event.extendedProps.note,
    node: event.extendedProps.node,
    latestSleepStudyDate: event.extendedProps.latestSleepStudyDate,
  };
  const statusToCheck = sleepstudyInfo.status;
  const titleToCheck = sleepstudyInfo.title;
  const currentDoctorId = sleepstudyInfo.doctor; // Assuming this contains the current doctor's ID

  // Create a collection group query to find sleep study cases with the same title
  const matchingTitlesQuery = query(
    collectionGroup(db, "sleepStudyCases"),
    where("title", "==", titleToCheck)
  );
  const querySnapshot = await getDocs(matchingTitlesQuery);
  if (statusToCheck == "Doctor Scored") {
    if (querySnapshot.empty) {
      console.log("no matching documents found !");
    } else {
      for (const doc of querySnapshot.docs) {
        // Delete the document
        await deleteDoc(doc.ref);
        console.log(`Deleted sleep study case with title "${titleToCheck}" `);
      }
    }
  } else if (statusToCheck == "Technician Scored") {
    if (querySnapshot.empty) {
      console.log("no matching documents found !");
    } else {
      for (const doc of querySnapshot.docs) {
        // Check if the document is under a different doctor
        const docDoctorId = doc.ref.path.split("/")[1]; // Extracting doctor ID from the document reference path
        if (docDoctorId !== currentDoctorId) {
          // Delete the document
          await deleteDoc(doc.ref);
          console.log(
            `Deleted sleep study case with title "${titleToCheck}" under a different doctor (ID: ${docDoctorId}).`
          );
        }
      }
    }
    const DoctorsQuery = query(
      collection(db, "doctors"),
      where("name", "==", sleepstudyInfo.doctor)
    );
    const DoctorDocuments = await getDocs(DoctorsQuery);
    var DoctorList = [];
    DoctorDocuments.forEach(async (document) => {
      const doctorId = document.id;
      const doctorInfo = document.data();

      const sleepStudyCasesCollectionRef = doc(
        collection(db, `doctors/${doctorId}/sleepStudyCases`)
      );
      await setDoc(sleepStudyCasesCollectionRef, sleepstudyInfo);
      const message = `\n${sleepstudyInfo.title} : ${sleepstudyInfo.firstname} ${sleepstudyInfo.lastname} \nStudy Date : ${sleepstudyInfo.latestSleepStudyDate} \n${sleepstudyInfo.type} \n${sleepstudyInfo.status}`;
      const accessToken = doctorInfo.accessToken;
      if (querySnapshot.empty) {
        sendNotification({ accessToken, message });
      }
    });
  }
};

const setNotifyTech = async (event) => {
  const sleepstudyInfo = {
    title: event.title,
    date: event.start,
    status: event.extendedProps.status,
    type: event.extendedProps.type,
    firstname: event.extendedProps.firstname,
    lastname: event.extendedProps.lastname,
    techrecord: event.extendedProps.techrecord,
    note: event.extendedProps.note,
    node: event.extendedProps.node,
    customer: event.extendedProps.customer,
    healthInsurance: event.extendedProps.healthInsurance,
    phone: event.extendedProps.phone,
  };
  const statusToCheck = sleepstudyInfo.status;
  const titleToCheck = sleepstudyInfo.title;
  const currentTechId = sleepstudyInfo.techrecord; // Assuming this contains the current doctor's ID

  // Create a collection group query to find sleep study cases with the same title
  const matchingTitlesQuery = query(
    collectionGroup(db, "sleepStudyCases"),
    where("title", "==", titleToCheck)
  );
  const querySnapshot = await getDocs(matchingTitlesQuery);
  if (statusToCheck == "Technician Scored") {
    if (querySnapshot.empty) {
      console.log("no matching documents found !");
    } else {
      for (const doc of querySnapshot.docs) {
        // Delete the document
        await deleteDoc(doc.ref);
        console.log(`Deleted sleep study case with title "${titleToCheck}" `);
      }
    }
  } else if (statusToCheck == "Sleep Test Scheduled") {
    if (querySnapshot.empty) {
      console.log("no matching documents found !");
    } else {
      for (const doc of querySnapshot.docs) {
        // Check if the document is under a different doctor
        const docTechId = doc.ref.path.split("/")[1]; // Extracting doctor ID from the document reference path
        if (docTechId !== currentTechId) {
          // Delete the document
          await deleteDoc(doc.ref);
          console.log(
            `Deleted sleep study case with title "${titleToCheck}" under a different doctor (ID: ${docTechId}).`
          );
        }
      }
    }
    const TechQuery = query(
      collection(db, "sleeptechnicians"),
      where("name", "==", sleepstudyInfo.techrecord)
    );
    const TechDocuments = await getDocs(TechQuery);
    var TechList = [];
    TechDocuments.forEach(async (document) => {
      const techId = document.id;
      const techInfo = document.data();

      const sleepStudyCasesCollectionRef = doc(
        collection(db, `sleeptechnicians/${techId}/sleepStudyCases`)
      );
      await setDoc(sleepStudyCasesCollectionRef, sleepstudyInfo);
      const message = `\nเคสตรวจการนอนหลับที่ ${sleepstudyInfo.customer} \nStudy Date: ${sleepstudyInfo.date}\nSleep Technician: ${sleepstudyInfo.techrecord}
       \n${sleepstudyInfo.title}: ${sleepstudyInfo.firstname} ${sleepstudyInfo.lastname} \nTel: ${sleepstudyInfo.phone} \nHealth Insurance: ${sleepstudyInfo.healthInsurance} \n${sleepstudyInfo.type} \n${sleepstudyInfo.status}`;
      const accessToken = techInfo.accessToken;
      if (querySnapshot.empty) {
        sendNotification({ accessToken, message });
      }
    });
  } else if (statusToCheck == "Cancel") {
    for (const doc of querySnapshot.docs) {
      // Delete the document
      await deleteDoc(doc.ref);
      console.log(`Deleted sleep study case with title "${titleToCheck}" `);
    }
    const TechQuery = query(
      collection(db, "sleeptechnicians"),
      where("name", "==", sleepstudyInfo.techrecord)
    );
    const TechDocuments = await getDocs(TechQuery);
    TechDocuments.forEach(async (document) => {
      const techInfo = document.data();
      const accessToken = techInfo.accessToken;
      const message =
        "Cancel เคสตรวจการนอนหลับที่ " +
        sleepstudyInfo.customer +
        "\nวันที่ " +
        sleepstudyInfo.date +
        "\n" +
        sleepstudyInfo.title +
        ": " +
        sleepstudyInfo.firstname +
        " " +
        sleepstudyInfo.lastname;
      sendNotification({ accessToken, message });
    });
  }
};

export {
  setEvent,
  eventTmp,
  paymentPrivilages,
  orderList,
  colorList,
  statusList,
  typeList,
  nodeList,
  statusOrder,
};
