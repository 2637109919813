import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";

import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  query,
  where,
  getDoc,
  updateDoc,
  doc,
  collection,
  getDocs,
  setDoc,
} from "firebase/firestore";

import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";
import { startAddOrder } from "../../../actions/orders";
import { startEditCpap } from "../../../actions/cpaps";
import selectCpaps from "../../../selectors/cpaps";
import { OrderForm } from "./OrderForm";
import { startAddNote } from "../../../actions/notes";
import { OrderEditForm } from "./OrderEditForm";

const ProductOrderRegister = (props) => {
  const { currentUser } = useAuth();
  const [isPurchase, setIsPurchase] = useState(false);
  const [saleOrderNumber, setSaleOrderNumber] = useState();
  const [patient, setPatient] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFilenames] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const { id, sn, dn } = useParams();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [receiptDate, setReceiptDate] = useState(new Date());

  const inputRef = useRef({
    // receiptNo: "",
    hn: "",
    amount: "",
    salePackage: "",
    mask: "",
    maskSize: "",
    receiptDate: "",
    refHospital: "",
    refPhysician: "",
    note: "",
    status: null,
    activity: null,
    warrantyPeriod: "",
    warrantyStartDate: null,
    warrantyEndDate: null,
    sales: null,
  });

  async function getNextSequence() {
    const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");

    try {
      const sequenceDoc = await getDoc(sequenceRef);

      if (!sequenceDoc.exists()) {
        throw new Error("Sequence document does not exist!");
      }

      const data = sequenceDoc.data();
      const currentDate = new Date();
      const currentMonth = (currentDate.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = currentDate.getFullYear() + 543;

      // Check if the month has changed and reset if necessary
      if (data.month !== currentMonth || data.year !== currentYear) {
        await setDoc(sequenceRef, {
          current: 1,
          month: currentMonth,
          year: currentYear,
        });
        return "001";
      }

      // Get the current sequence
      const current = data.current;
      return current.toString().padStart(3, "0");
    } catch (e) {
      console.error("Failed to get sequence: ", e);
      throw e;
    }
  }

  async function updateSequence(newSequence) {
    const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");

    try {
      await updateDoc(sequenceRef, { current: newSequence });
    } catch (e) {
      console.error("Failed to update sequence: ", e);
      throw e;
    }
  }

  const generateId = async () => {
    // const currentDate = new Date();
    // const thaiYear = currentDate.getFullYear() + 543;
    // const year = thaiYear.toString().slice(-2);
    // const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");

    const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");
    const sequenceDoc = await getDoc(sequenceRef);
    const data = sequenceDoc.data();

    const year = data.year.toString().slice(2);
    const month = data.month;

    const sequence = await getNextSequence();

    return `IGC${year}${month}${sequence}`;
  };

  const calculateEndDate = (period) => {
    const now = new Date(receiptDate);
    console.log("now", now);
    let calculatedDate;

    switch (period) {
      case "6M":
        calculatedDate = new Date(new Date(now).setMonth(now.getMonth() + 6));
        // calculatedDate = new Date(now.setMonth(now.getMonth() + 6));
        break;
      case "1Y":
        calculatedDate = new Date(
          new Date(now).setFullYear(now.getFullYear() + 1)
        );
        // calculatedDate = new Date(now.setFullYear(now.getFullYear() + 1));
        break;
      case "2Y":
        calculatedDate = new Date(
          new Date(now).setFullYear(now.getFullYear() + 2)
        );
        // calculatedDate = new Date(now.setFullYear(now.getFullYear() + 2));
        break;
      default:
        calculatedDate = null;
    }

    setStartDate(now); // Reset to now
    setEndDate(calculatedDate);

    console.log("start", now);
    console.log("end", calculatedDate);
  };

  const handleChange = (name) => (e) => {
    if (name === "nationalImage") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
        console.log(f.name);
      }
    } else if (name === "warrantyPeriod") {
      calculateEndDate(e.target.value);
      inputRef.current[name] = e.target.value;
    } else if (name === "receiptDate") {
      setReceiptDate(e.target.value);
      inputRef.current[name] = e.target.value;
      console.log("receiptDate", e.target.value);
    } else {
      inputRef.current[name] = e.target.value;
    }
    // console.log(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ref = doc(db, "patients", inputRef.current.hn);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      //check if purchase order already created
      const orderQuery = query(
        collection(db, "orders"),
        where("productSn", "==", sn),
        where("activity", "==", "purchase")
      );
      const orderDoc = await getDocs(orderQuery);
      if (!orderDoc.empty) {
        if (inputRef.current.activity === "purchase") {
          notifyError(
            "Purchase order already created, Re-create please delete the old purchase order"
          );
          return;
        }
      }

      getPatient(inputRef.current.hn);

      // console.log("patient", patient);

      const formData = {
        // receiptNo: inputRef.current.receiptNo,
        receiptNo: saleOrderNumber,
        hn: inputRef.current.hn,
        amount: inputRef.current.amount,

        salePackage: inputRef.current.salePackage,
        mask: inputRef.current.mask,
        maskSize: inputRef.current.maskSize,

        receiptDate: inputRef.current.receiptDate
          ? new Date(inputRef.current.receiptDate)
          : new Date(receiptDate),
        // receiptDate: inputRef.current.receiptDate
        //   ? new Date(inputRef.current.receiptDate)
        //   : null,
        // refHospital: inputRef.current.refHospital,
        refHospital: docSnap.data().refHn,
        refPhysician: inputRef.current.refPhysician,
        sales: inputRef.current.sales,
        note: inputRef.current.note,
        productId: id,
        productSn: sn,
        deviceNo: dn,
        status: inputRef.current.status,
        activity: inputRef.current.activity,
        createdBy: currentUser.displayName,
        warrantyPeriod: inputRef.current.warrantyPeriod,
        warrantyStartDate: endDate
          ? startDate
          : new Date(inputRef.current.warrantyStartDate),

        // warrantyStartDate: inputRef.current.warrantyStartDate
        //   ? new Date(inputRef.current.warrantyStartDate)
        //   : null,
        warrantyEndDate: endDate
          ? endDate
          : new Date(inputRef.current.warrantyEndDate),
        // warrantyEndDate: inputRef.current.warrantyEndDate
        //   ? new Date(inputRef.current.warrantyEndDate)
        //   : null,
        createdAt: new Date(),
        updatedAt: new Date(),
        firstname: docSnap.data().firstnameTh,
        lastname: docSnap.data().lastnameTh,
        nodeRef:
          docSnap.data().nodeRef === undefined ? "" : docSnap.data().nodeRef,
        refCustomer:
          docSnap.data().refCustomer === undefined
            ? ""
            : docSnap.data().refCustomer,
        mobile: docSnap.data().mobile,
        address: docSnap.data().address1,
      };

      if (fileNames.length > 0) {
        props.startAddNote(
          {
            title: "National ID Card",
            detail: "",
            filename: fileNames,
            hn: inputRef.current.hn,
            createBy: currentUser.displayName,
            updateAt: new Date(),
            createAt: new Date(),
          },
          selectedFiles
        );
      }

      props
        .startAddOrder(formData)
        .then(() => notifyTopRight("บันทึกข้อมูลสำเร็จ"))
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด ", err);
        });

      // update status "ขาย"
      const docRef = doc(db, "products", id);
      await updateDoc(docRef, { status: "ขาย" });

      // update next sale order number
      const currentSequence = await getNextSequence();
      const newSequence = parseInt(currentSequence, 10) + 1;
      await updateSequence(newSequence);
    } else {
      notifyError("Invalid HN !");
    }
  };

  const getPatient = async (hn) => {
    const ref = doc(db, "patients", hn);

    await getDoc(ref).then((item) => {
      setPatient(item.data());
    });
  };

  const getDoctors = async () => {
    const ref = collection(db, "doctors");

    const docSnap = await getDocs(ref);
    const temp = [];
    docSnap.forEach((item) => {
      temp.push(item.data());
    });
    setDoctorOptions(
      temp.filter((doctor) => {
        return doctor.status === "Available" && doctor.doctorType === "Other";
      })
    );
  };

  useEffect(() => {
    // Generate the sale order number when the component mounts
    generateId().then((id) => setSaleOrderNumber(id));
    getDoctors();
  }, []);

  return (
    <Fragment>
      <Toast />
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Order Form</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <form
                  className="form-valide"
                  action="#"
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <OrderForm
                        onChange={handleChange}
                        saleOrderNumber={saleOrderNumber}
                        fileNames={fileNames}
                        warrantyStart={startDate}
                        warrantyEnd={endDate}
                        receiptDate={receiptDate}
                      />
                      <br /> <br />
                      <div className="form-group row">
                        <div className="col-lg-8 ml-auto">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                          <Link
                            to="/product-list"
                            type="submit"
                            className="btn btn-light ml-2"
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  cpap: selectCpaps(state.cpaps, { text: props.match.params.sn }),
});

const mapDispatchToProps = (dispatch) => ({
  startAddOrder: (order) => dispatch(startAddOrder(order)),
  startEditCpap: (id, updates) => dispatch(startEditCpap(id, updates)),
  startAddNote: (note, files) => dispatch(startAddNote(note, files)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductOrderRegister);
