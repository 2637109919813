import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Dropdown, Table } from "react-bootstrap";
import { db } from "../../../firebase";
import { collectionGroup, getDocs } from "firebase/firestore";
// import { statusList } from "./BookingUtils";

const BookingUrgent = () => {
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("ทั้งหมด"); // Default to "ทั้งหมด"
  const statusList = ["ทั้งหมด", "Sleep Test Scheduled", "Technician Scored"];
  useEffect(() => {
    setLoading(true);
    const fetchAndFilterBookings = async () => {
      try {
        const bookingsCollectionGroupRef = collectionGroup(db, "bookings");
        const snapshot = await getDocs(bookingsCollectionGroupRef);

        const todayDate = new Date(); // Today's date

        // Map and filter the data
        const filteredData = snapshot.docs
          .map((doc) => {
            const data = doc.data();
            const { healthInsurance, latestSleepStudyDate, status } =
              data.extendedProps || {};
            const latestSleepStudyDateObj = new Date(latestSleepStudyDate);

            // Skip entries where latestSleepStudyDate is in the future
            if (latestSleepStudyDateObj > todayDate) return null;

            // Add alert field based on healthInsurance and the difference in days
            let alert = false;

            // Case 1: Self-payment and more than 8 days past latestSleepStudyDate
            if (
              healthInsurance === "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) > 8
            ) {
              alert = true;
            }

            // Case 2: Non-self-payment and more than 31 days past latestSleepStudyDate
            else if (
              healthInsurance !== "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) > 31
            ) {
              alert = true;
            }

            // Return data with the alert field
            return {
              id: doc.id,
              ...data,
              alert,
            };
          })
          .filter((data) => data !== null) // Remove null values from the result
          .filter((data) => {
            const { healthInsurance, latestSleepStudyDate, status } =
              data.extendedProps || {};
            const statusConditions = [
              "Sleep Test Scheduled",
              "Technician Scored",
            ];

            // Parse latestSleepStudyDate string to Date object
            const latestSleepStudyDateObj = new Date(latestSleepStudyDate);

            // Case 1: Self-payment and latestSleepStudyDate - todayDate > 3 days
            if (
              healthInsurance === "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) >
                4 &&
              statusConditions.includes(status)
            ) {
              return true;
            }

            // Case 2: Non-self-payment and latestSleepStudyDate - todayDate > 20 days
            if (
              healthInsurance !== "ชำระเงินเอง" &&
              (todayDate - latestSleepStudyDateObj) / (1000 * 60 * 60 * 24) >
                21 &&
              statusConditions.includes(status)
            ) {
              return true;
            }

            return false;
          })
          .sort((a, b) => {
            // Sort by healthInsurance: 'ชำระเงินเอง' first
            if (
              a.extendedProps.healthInsurance === "ชำระเงินเอง" &&
              b.extendedProps.healthInsurance !== "ชำระเงินเอง"
            ) {
              return -1; // 'ชำระเงินเอง' comes first
            } else if (
              a.extendedProps.healthInsurance !== "ชำระเงินเอง" &&
              b.extendedProps.healthInsurance === "ชำระเงินเอง"
            ) {
              return 1; // Non-'ชำระเงินเอง' comes after
            }

            // Then sort by the time difference, most difference first
            const aTimeDiff =
              (todayDate - new Date(a.extendedProps.latestSleepStudyDate)) /
              (1000 * 60 * 60 * 24); // In days
            const bTimeDiff =
              (todayDate - new Date(b.extendedProps.latestSleepStudyDate)) /
              (1000 * 60 * 60 * 24); // In days

            return bTimeDiff - aTimeDiff; // Sort in descending order (most difference first)
          });

        // Apply status filter based on selected status
        const statusConditions =
          selectedStatus === "ทั้งหมด"
            ? ["Sleep Test Scheduled", "Technician Scored"]
            : [selectedStatus];

        const finalFilteredData = filteredData.filter((data) =>
          statusConditions.includes(data.extendedProps.status)
        );

        setFilteredBookings(finalFilteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching and filtering bookings: ", error);
        setLoading(false);
      }
    };

    fetchAndFilterBookings();
  }, [selectedStatus]); // Re-run when selectedStatus changes

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  return (
    <div>
      <h4>{"Filter only status Sleep Test Scheduled and Technician Scored"}</h4>
      <h4>{"ชำระเงินเอง > 3 days, ประกันสังคม > 20 days"}</h4>
      <h4>
        <i class="flaticon-381-alarm-clock"></i>{" "}
        {"ชำระเงินเอง > 7 days, ประกันสังคม > 30 days"}
      </h4>

      {/* Dropdown for Status Filter */}
      <Dropdown className="m-3">
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Select Status {selectedStatus}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {statusList.map((status, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => setSelectedStatus(status)}
            >
              {status}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {!loading && (
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body className="card-body">
                <Table className="table-responsive-12 mb-0 table-striped">
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Health Insurance</th>
                      <th>Sleep Study</th>
                      <th>Name</th>
                      <th>HN</th>
                      <th>TYPE</th>
                      <th>Tech Record</th>
                      <th>Doctor</th>
                      <th>STATUS</th>
                      <th>Sleep Study Date</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="booking-table">
                    {filteredBookings.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td className="py-0">{index + 1}</td>
                          <td>{element.extendedProps.healthInsurance}</td>
                          <td className="py-0">
                            <Link to="#">{element.extendedProps.order}</Link>
                          </td>
                          <td className="py-0">
                            <Link
                              to={`/patient-page/${element.extendedProps.hn}`}
                            >
                              {element.extendedProps.firstname}{" "}
                              {element.extendedProps.lastname}
                            </Link>
                          </td>
                          <td className="py-0">
                            <Link
                              to={`/patient-page/${element.extendedProps.hn}`}
                            >
                              {element.extendedProps.hn}
                            </Link>
                          </td>
                          <td className="py-0">{element.extendedProps.type}</td>
                          <td className="py-0">
                            {element.extendedProps.techrecord}
                          </td>
                          <td className="py-0">
                            {element.extendedProps.doctor}
                          </td>
                          <td className="py-0">
                            {element.extendedProps.status}
                          </td>
                          <td>{element.extendedProps.latestSleepStudyDate}</td>
                          <td>
                            {element.alert && (
                              <i className="flaticon-381-alarm-clock"></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BookingUrgent;
