import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  where,
  orderBy,
  query,
  limit,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

import { notifyTopRight, notifyWarning, notifyError } from "../toast/SetToast";
import Toast from "../toast/SetToast";

const Settings = () => {
  const [sequence, setSequence] = useState();
  const currentRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const submitHandler = async (e) => {
    e.preventDefault();

    const updates = {
      current: Number(currentRef.current.value),
      month: monthRef.current.value,
      year: Number(yearRef.current.value),
    };
    const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");
    await updateDoc(sequenceRef, { ...updates })
      .then(() => notifyTopRight("Updated success"))
      .catch((error) => notifyError(error));
  };

  async function getSequence() {
    const sequenceRef = doc(db, "sequenceTracker", "saleOrderSequence");

    try {
      const sequenceDoc = await getDoc(sequenceRef);
      if (!sequenceDoc.exists()) {
        throw new Error("Sequence document does not exist!");
      }
      const data = sequenceDoc.data();
      console.log("data", data);
      setSequence(data);
    } catch (e) {
      console.error("Failed to get sequence: ", e);
      throw e;
    }
  }

  useEffect(() => {
    getSequence();
  }, []);

  return (
    <Fragment>
      <PageTitle
        activeMenu="Settings"
        motherMenu="Settings"
        motherLink="/settings"
      />
      <Toast />
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Sale order no. index</div>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <label className="col-sm-2 col-form-label">ปี พ.ศ.</label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control input-default "
                        defaultValue={sequence ? sequence.year : ""}
                        ref={yearRef}
                      />
                    </div>
                    <label className="col-sm-2 col-form-label">เดือน</label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control input-default "
                        defaultValue={sequence ? sequence.month : ""}
                        ref={monthRef}
                      />
                    </div>
                    <label className="col-sm-2 col-form-label">ลำดับ</label>
                    <div className="col-sm-3">
                      <input
                        type="text"
                        className="form-control input-default "
                        defaultValue={sequence ? sequence.current : ""}
                        ref={currentRef}
                      />
                    </div>
                    <div className="col-sm-3 mt-3">
                      <button type="submit" className="btn btn-primary mb-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Settings;
