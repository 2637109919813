import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  setTextFilter,
  sortByDate,
  setSearchByFilter,
  setSortByStatus,
} from "../../../actions/filters";

export class ProductOrderListFilters extends React.Component {
  state = {
    calendarFocused: null,
    searchByOption: "Name",
    sortByStatus: "ทั้งหมด",
  };

  activitySelect = ["ทั้งหมด", "purchase", "maintenance", "cleaning", "repair"];
  searchBySelect = ["Name", "HN", "Serial Number", "Receipt No."];
  statusSelect = [
    "ทั้งหมด",
    "เสร็จสิ้น",
    "กำลังดำเนินการ",
    "รอเอกสาร",
    "ยกเลิก",
  ];

  onTextChange = (e) => {
    this.props.setTextFilter(e.target.value);
  };

  onSearchByChange = (option) => {
    if (option === "HN") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("hn");
    } else if (option === "Serial Number") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("productSn");
    } else if (option === "Receipt No.") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("receiptNo");
    } else if (option === "Name") {
      this.setState({ searchByOption: option });
      this.props.setSearchByFilter("firstname");
    }
  };

  onStatusChange = (option) => {
    if (option === "ทั้งหมด") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("ทั้งหมด");
    } else if (option === "เสร็จสิ้น") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("เสร็จสิ้น");
    } else if (option === "กำลังดำเนินการ") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("กำลังดำเนินการ");
    } else if (option === "รอเอกสาร") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("รอเอกสาร");
    } else if (option === "ยกเลิก") {
      this.setState({ sortByStatus: option });
      this.props.setSortByStatus("ยกเลิก");
    }
  };

  render() {
    return (
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
        <div className="input-group search-area ml-auto d-inline-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search Here"
            value={this.props.filters.text}
            onChange={this.onTextChange}
          />
          <div className="input-group-append">
            <span className="input-group-text c-pointer">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </div>
        <Dropdown className="ml-3 width200" onSelect={this.onSearchByChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> by{" "}
            {this.state.searchByOption}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            {this.searchBySelect.map((element, index) => (
              <Dropdown.Item href="#" eventKey={element} key={index}>
                {element}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="width200" onSelect={this.onStatusChange}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
            <i className="flaticon-381-controls-3 "></i> Status{" "}
            {this.state.sortByStatus}
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            {this.statusSelect.map((element, index) => (
              <Dropdown.Item href="#" eventKey={element} key={index}>
                {element}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
});

const mapDispatchToProps = (dispatch) => ({
  setTextFilter: (text) => dispatch(setTextFilter(text)),
  sortByDate: () => dispatch(sortByDate()),
  setSearchByFilter: (searchBy) => dispatch(setSearchByFilter(searchBy)),
  setSortByStatus: (sortByStatus) => dispatch(setSortByStatus(sortByStatus)), // Updated this line
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductOrderListFilters);
