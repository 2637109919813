import React, { Component, Fragment, useState } from "react";
import { Col, Row, Card, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import { Calendar } from "@fullcalendar/core";
import { db, functions, auth } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  collection,
  query,
  where,
  getCollections,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { async } from "@firebase/util";
import Alert from "sweetalert2";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
import { AuthContext } from "../../../context/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { connectStorageEmulator } from "firebase/storage";
import {
  paymentPrivilages,
  PatientDropDownForm,
  PatientTextInfo,
  PatientLinkInfo,
  PatientInputTextArea,
  PatientInputText,
  colorList,
  statusList,
  ItemListTable,
} from "./TrialUtils";
import {
  CpapBorrowingData,
  CpapBorrowingOptions,
  Masks,
  MaskSizes,
  RampTimeOptions,
  PressureOptions,
  HumidifierOptions,
  TypeOptions,
} from "../CPAP/formdata/CpapBorrowingData";

// import "react-datepicker/dist/react-datepicker-cssmodules.css";

class TrialCalendar extends Component {
  constructor(props) {
    super(props);
  }
  calendarComponentRef = React.createRef();
  static contextType = AuthContext;
  state = {
    calendarEvents: [],
    events: [
      {
        title: "Event",
        id: "6",
        order: "",
        color: "green",
      },
    ],
    eventTmp: {
      title: "",
      startStr: "",
      id: "",
      extendedProps: {
        order: "",
        healthInsurance: "",
        type: "",
        orderStatus: "",
        technician: "",
        referDoctor: "",
        time: "",
        items: [],
        totalValue: 0,
        ...CpapBorrowingData,
      },
    },
    technicianList: [],
    cpapList: [],
    availableCpapList: [],
    notAvailableCpapList: [],
    itemList: [],
    items: [],
    totalValue: 0,
    isEditBookingDetail: false,
    isEditEventModal: false,
    isBorrowing: false,
    isNewPatient: false,
    isAddEventModal: false,
    isDatetime: false,
    isEndDate: false,
    isLoading: true,
    createModal: false,
    currentActivity: "",
    currentOrderStatus: "",
    dateTmp: new Date().toISOString().slice(0, 10),
    borrowDateTmp: new Date().toISOString().slice(0, 10),
    dueDateTmp: new Date().toISOString().slice(0, 10),
    returnDateTmp: new Date().toISOString().slice(0, 10),
    resetDateTmp: new Date().toISOString().slice(0, 10),
  };
  orderRef = React.createRef();
  serialRef = React.createRef();
  hnRef = React.createRef();
  firstnameRef = React.createRef();
  lastnameRef = React.createRef();
  phoneRef = React.createRef();
  healthInsuranceRef = React.createRef();
  typeRef = React.createRef();
  orderStatusRef = React.createRef();
  activityRef = React.createRef();
  techRef = React.createRef();
  docRef = React.createRef();
  statusRef = React.createRef();
  maskRef = React.createRef();
  masksizeRef = React.createRef();
  humidifierRef = React.createRef();
  ramptimeRef = React.createRef();
  initpressureRef = React.createRef();
  minpressureRef = React.createRef();
  maxpressureRef = React.createRef();
  borrowdateRef = React.createRef();
  duedateRef = React.createRef();
  returndateRef = React.createRef();
  borrowconditionRef = React.createRef();
  returnconditionRef = React.createRef();
  noteRef = React.createRef();
  timeRef = React.createRef();

  PatientDropDownForm = PatientDropDownForm;
  PatientTextInfo = PatientTextInfo;
  PatientLinkInfo = PatientLinkInfo;
  PatientInputText = PatientInputText;
  PatientInputTextArea = PatientInputTextArea;
  ItemListTable = ItemListTable;

  paymentPrivilages = paymentPrivilages;
  CpapBorrowingOptions = CpapBorrowingOptions;
  Masks = Masks;
  MaskSizes = MaskSizes;
  RampTimeOptions = RampTimeOptions;
  PressureOptions = PressureOptions;
  HumidifierOptions = HumidifierOptions;
  TypeOptions = TypeOptions;
  colorList = colorList;
  statusList = statusList;

  /**
   * adding dragable properties to external events through javascript
   */
  getPatienBySearch = httpsCallable(functions, "getPatienBySearch");
  createPatient = httpsCallable(functions, "createPatient");
  getSignedUrl = httpsCallable(functions, "getSignedUrl");

  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchData = async () => {
    try {
      const startFetchTime = Date.now();

      const [techDocs, itemsDocs, trialDocs] = await Promise.all([
        getDocs(collection(db, "sleeptechnicians")),
        getDocs(collection(db, "items")),
        getDocs(collection(db, "trial")),
      ]);

      if (!this._isMounted) return;

      this.logFetchTime("Fetching techs, items, and trials", startFetchTime);

      const allItems = itemsDocs.docs.map((doc) => ({
        ...doc.data(),
        itemcode: doc.id,
      }));
      const allTechs = [
        "กรุณาเลือก",
        ...techDocs.docs.map((doc) => doc.data().name),
      ];

      const allEvents = trialDocs.docs.map((trial) => {
        const trialData = trial.data();
        const dateStart = new Date(trialData.start.toDate());
        dateStart.setHours(dateStart.getHours() + 7);
        trialData.start = dateStart.toISOString().slice(0, 10);
        if (trialData.end != null) {
          const dateEnd = new Date(trialData.end.toDate());
          dateEnd.setHours(dateEnd.getHours() + 7);
          trialData.end = dateEnd.toISOString().slice(0, 10);
        }
        return trialData;
      });

      this.setState({
        calendarEvents: allEvents,
        technicianList: allTechs,
        itemList: allItems,
        isLoading: false,
      });

      this.getCpap();

      let draggableEl = document.getElementById("external-events");
      if (draggableEl) {
        new Draggable(draggableEl, {
          itemSelector: ".fc-event",
          eventData: function (eventEl) {
            let title = eventEl.getAttribute("title");
            let id = eventEl.getAttribute("id");
            let color = eventEl.getAttribute("color");
            let startStr = eventEl.getAttribute("startStr");
            return {
              title: title,
              id: id,
              startStr: startStr,
              color: color,
              extendedProps: {
                order: "",
                healthInsurance: "",
                type: "",
                orderStatus: "",
                technician: "",
                referDoctor: "",
                time: "",
                items: [],
                totalValue: 0,
                ...CpapBorrowingData,
              },
            };
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ isLoading: false });
    }
  };

  getCpap = async () => {
    const [cpaps] = await Promise.all([getDocs(collection(db, "cpaps"))]);
    const allCpaps = cpaps.docs.map((doc) => doc.data());
    const availableCpaps = allCpaps.filter((cpap) => cpap.Status === "ว่าง");
    const notAvailableCpaps = allCpaps.filter(
      (cpap) => cpap.Status === "ไม่ว่าง"
    );

    this.setState({
      cpapList: allCpaps,
      availableCpapList: availableCpaps,
      notAvailableCpapList: notAvailableCpaps,
    });
  };

  setCurrentActivity = (e) => {
    this.setState({ currentActivity: e.target.value });
  };

  setItems = (items) => {
    this.setState({ items: items }, () => {
      this.getTotalValue();
    });
  };

  logFetchTime = (label, startTime) => {
    const endTime = Date.now();
    console.log(`${label} took ${endTime - startTime} ms`);
  };

  onRemoveEvent = async (isExists) => {
    // console.log(this.state.eventTmp.extendedProps.uuid);
    if (
      this.state.eventTmp.extendedProps.uuid != "" &&
      this.state.eventTmp.extendedProps.uuid != null &&
      this.state.eventTmp.extendedProps.uuid != undefined
    ) {
      const refDelete = doc(
        db,
        "trial",
        this.state.eventTmp.extendedProps.uuid
      );
      await deleteDoc(refDelete)
        .then(async (res) => {
          // notifyTopRight("Delete Success!", res);
          swal("Your booking event has been deleted!", {
            icon: "success",
          });
        })
        .catch((err) => {
          notifyError("Detele Error ", err);
        });
    }
    this.state.eventTmp.remove();
    // this.forceUpdate();
  };
  onSearchPatient = async (e) => {
    const q = query(
      collection(db, "patients"),
      where("hn", "==", this.hnRef.current.value)
    );
    const querySnapshot = await getDocs(q);
    let listPatient = [];
    // console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listPatient.push(doc.data());
    });
    if (listPatient[0] == undefined) {
      this.setState({ isNewPatient: true });
    } else {
      // console.log(listPatient[0]);
      // console.log(this.state.eventTmp.extendedProps.hn);
      try {
        this.firstnameRef.current.value = listPatient[0].firstnameTh;
        this.lastnameRef.current.value = listPatient[0].lastnameTh;
        this.nationalIdRef.current.value = listPatient[0].nationalId;
        this.phoneRef.current.value = listPatient[0].mobile;
        if (
          listPatient[0].healthInsurance == "" ||
          listPatient[0].healthInsurance == "please-select"
        ) {
          this.healthInsuranceRef.current.value = "กรุณาเลือก";
        } else {
          this.healthInsuranceRef.current.value =
            listPatient[0].healthInsurance;
        }
      } catch (err) {
        var getTmp = this.state.eventTmp;
        // console.log(getTmp);
        getTmp.setExtendedProp("FirstName", listPatient[0].firstnameTh);
        getTmp.setExtendedProp("LastName", listPatient[0].lastnameTh);
        // getTmp.setExtendedProp("nationalId", listPatient[0].nationalId);
        getTmp.setExtendedProp("Tel", listPatient[0].mobile);
        if (
          listPatient[0].healthInsurance == "" ||
          listPatient[0].healthInsurance == "please-select"
        ) {
          getTmp.setExtendedProp("healthInsurance", "กรุณาเลือก");
        } else {
          getTmp.setExtendedProp(
            "healthInsurance",
            listPatient[0].healthInsurance
          );
        }
        this.setState({ eventTmp: getTmp });
      }
      this.setState({ isNewPatient: false });
    }
  };
  handleDate = (moment, props) => {
    let storeMoment = moment.toISOString().slice(0, 10);
    let evtmp = this.state.eventTmp;
    evtmp.setStart(moment);
    evtmp.setEnd(moment.getDate() + 1);
    this.setState({ dateTmp: storeMoment });
    this.setState({ eventTmp: evtmp });
  };

  handleDateBorrowing = (momentTmp, props, type, isReturn) => {
    console.log(momentTmp);
    var moment = this.addSevenHours(momentTmp);
    var storeMoment = moment.toISOString().slice(0, 10);
    var evtmp = this.state.eventTmp;

    if (type == "BorrowDate") {
      if (!isReturn) {
        evtmp.setStart(moment);
        evtmp.setEnd(moment.getDate() + 1);
        evtmp.setExtendedProp("BorrowDate", storeMoment);
        this.setState({ dateTmp: storeMoment });
        this.setState({ borrowDateTmp: storeMoment });
        this.setState({ eventTmp: evtmp });
        console.log("borrow date: ", storeMoment);
      } else {
        evtmp.setExtendedProp("BorrowDate", storeMoment);
        this.setState({ borrowDateTmp: storeMoment });
        this.setState({ eventTmp: evtmp });
      }
    } else if (type == "DueDate") {
      if (!isReturn) {
        evtmp.setEnd(moment);
        evtmp.setExtendedProp("DueDate", storeMoment);
        this.setState({ dueDateTmp: storeMoment });
        this.setState({ eventTmp: evtmp });
      } else {
        evtmp.setExtendedProp("DueDate", storeMoment);
        this.setState({ dueDateTmp: storeMoment });
        this.setState({ eventTmp: evtmp });
      }
    } else if (type == "ReturnDate") {
      evtmp.setStart(moment);
      evtmp.setEnd(moment.getDate() + 1);
      evtmp.setExtendedProp("ReturnDate", storeMoment);
      this.setState({ dateTmp: storeMoment });
      this.setState({ returnDateTmp: storeMoment });
      this.setState({ eventTmp: evtmp });
      console.log("event return: ", this.state.eventTmp);
    }
  };
  handleTime = (moment, props) => {
    // console.log(moment.target.value);
    var evtmp = this.state.eventTmp;
    evtmp.setExtendedProp("time", moment.target.value);
    this.setState({ eventTmp: evtmp });
  };

  onHideAddEventModal = () => {
    this.setState({ items: [] }, () => {
      this.setState({
        totalValue: 0,
        isAddEventModal: false,
        isBorrowing: false,
        currentActivity: "",
        currentOrderStatus: "",
      });
    });
    this.onRemoveEvent(false);
  };

  onHideEditEventModal = () => {
    this.setState({ items: [] }, () => {
      this.setState({
        totalValue: 0,
        isEditEventModal: false,
        isBorrowing: false,
        currentActivity: "",
        currentOrderStatus: "",
        isEditBookingDetail: false,
      });
      // this.setState({ isEditBookingDetail: true });
      // console.log("items: ", this.state.items);
    });
  };

  onHideNoti = () => {
    this.setState({ items: [] }, () => {
      this.setState({ totalValue: 0 });
    });
    this.setState({ isNoti: false });
    this.setState({ isEditBookingDetail: false });
    // this.setState({ isEditBookingDetail: true });
  };

  onAddEvent = async () => {
    var eventAdd = this.state.eventTmp;

    const currentUserName = this.context.currentUser.displayName;
    eventAdd.setExtendedProp("createBy", currentUserName);

    eventAdd.setProp("id", 6);
    eventAdd.setExtendedProp("order", this.orderRef.current.value);
    eventAdd.setExtendedProp("HN", this.hnRef.current.value);
    eventAdd.setExtendedProp("type", this.typeRef.current.value);
    eventAdd.setExtendedProp("technician", this.techRef.current.value);
    eventAdd.setExtendedProp("referDoctor", this.docRef.current.value);
    eventAdd.setExtendedProp("FirstName", this.firstnameRef.current.value);
    eventAdd.setExtendedProp("LastName", this.lastnameRef.current.value);
    eventAdd.setExtendedProp("Tel", this.phoneRef.current.value);
    eventAdd.setExtendedProp(
      "healthInsurance",
      this.healthInsuranceRef.current.value
    );
    eventAdd.setProp(
      "title",
      eventAdd.extendedProps.order // + " HN" + this.hnRef.current.value
    );
    eventAdd.setExtendedProp("note", this.noteRef.current.value);
    eventAdd.setExtendedProp("items", this.state.items);
    eventAdd.setExtendedProp("totalValue", this.state.totalValue);

    var indexOfStatusList = this.statusList.findIndex(
      (obj) => obj === this.orderStatusRef.current.value
    );
    eventAdd.setExtendedProp("orderStatus", this.orderStatusRef.current.value);
    eventAdd.setProp("color", this.colorList[indexOfStatusList]);
    if (this.orderStatusRef.current.value != "CPAP-Consult") {
      eventAdd.setExtendedProp("time", "");
    }
    const statusBorrowing = ["Delivered CPAP", "Received CPAP"];

    if (statusBorrowing.includes(this.orderStatusRef.current.value)) {
      if (this.serialRef.current.value == null) {
        eventAdd.setExtendedProp(
          "SerialNumber",
          this.state.eventTmp.extendedProps.SerialNumber
        );
      } else {
        eventAdd.setExtendedProp("SerialNumber", this.serialRef.current.value);
      }
      eventAdd.setExtendedProp("Activity", this.activityRef.current.value);

      if (this.activityRef.current.value == "ยืมเครื่อง") {
        eventAdd.setAllDay(true);
        // eventAdd.setEnd(new Date(this.state.dueDateTmp));
        eventAdd.setExtendedProp("Status", "ไม่ว่าง");
        eventAdd.setExtendedProp("Mask", this.maskRef.current.value);
        eventAdd.setExtendedProp("MaskSize", this.masksizeRef.current.value);
        eventAdd.setExtendedProp(
          "Humidifier",
          this.humidifierRef.current.value
        );
        eventAdd.setExtendedProp("RampTime", this.ramptimeRef.current.value);
        eventAdd.setExtendedProp(
          "InitialPressure",
          this.initpressureRef.current.value
        );
        eventAdd.setExtendedProp(
          "MinPressure",
          this.minpressureRef.current.value
        );
        eventAdd.setExtendedProp(
          "MaxPressure",
          this.maxpressureRef.current.value
        );
        if (
          this.state.borrowDateTmp == "" ||
          this.state.borrowDateTmp == null
        ) {
          eventAdd.setExtendedProp("BorrowDate", eventAdd.startStr);
        } else {
          eventAdd.setExtendedProp("BorrowDate", this.state.borrowDateTmp);
        }

        if (this.state.dueDateTmp == "" || this.state.dueDateTmp == null) {
          eventAdd.setExtendedProp("DueDate", eventAdd.startStr);
        } else {
          eventAdd.setExtendedProp("DueDate", this.state.dueDateTmp);
        }
        // eventAdd.setExtendedProp("DueDate", this.state.dueDateTmp);
        eventAdd.setExtendedProp(
          "BorrowCondition",
          this.borrowconditionRef.current.value
        );

        eventAdd.setExtendedProp("ReturnDate", "");
        eventAdd.setExtendedProp("ReturnCondition", "");
      } else if (this.activityRef.current.value == "คืนเครื่อง") {
        // console.log("return date: ", this.state.extendedProps.ReturnDate);
        // console.log("return date tmp: ", this.state.returnDateTmp);
        eventAdd.setAllDay(true);
        eventAdd.setEnd(null);

        eventAdd.setExtendedProp("Status", "ว่าง");
        eventAdd.setExtendedProp("Mask", "");
        eventAdd.setExtendedProp("MaskSize", "");
        eventAdd.setExtendedProp("Humidifier", "");
        eventAdd.setExtendedProp("RampTime", "");
        eventAdd.setExtendedProp("InitialPressure", "");
        eventAdd.setExtendedProp("MinPressure", "");
        eventAdd.setExtendedProp("MaxPressure", "");
        // eventAdd.setExtendedProp("BorrowDate", this.state.borrowDateTmp);
        // eventAdd.setExtendedProp("DueDate", this.state.dueDateTmp);
        eventAdd.setExtendedProp("BorrowCondition", "");

        eventAdd.setExtendedProp(
          "ReturnDate",
          // this.state.extendedProps.ReturnDate
          this.state.returnDateTmp
        );
        eventAdd.setExtendedProp(
          "ReturnCondition",
          // this.state.extendedProps.ReturnCondition
          this.returnconditionRef.current.value
        );
      }
    } else {
      console.log("status not borrowing !");
      eventAdd.setAllDay(true);
      eventAdd.setEnd(null);
      eventAdd.setExtendedProp("SerialNumber", "");
      eventAdd.setExtendedProp("Activity", "");
      eventAdd.setExtendedProp("Status", "");
      eventAdd.setExtendedProp("Mask", "");
      eventAdd.setExtendedProp("MaskSize", "");
      eventAdd.setExtendedProp("Humidifier", "");
      eventAdd.setExtendedProp("RampTime", "");
      eventAdd.setExtendedProp("InitialPressure", "");
      eventAdd.setExtendedProp("MinPressure", "");
      eventAdd.setExtendedProp("MaxPressure", "");
      eventAdd.setExtendedProp("BorrowDate", "");
      eventAdd.setExtendedProp("DueDate", "");
      eventAdd.setExtendedProp("BorrowCondition", "");

      eventAdd.setExtendedProp("ReturnDate", "");
      eventAdd.setExtendedProp("ReturnCondition", "");
    }
    console.log("addEvent: ", eventAdd);

    try {
      const refPatient = doc(db, "patients", eventAdd.extendedProps.HN);
      const docSnap = await getDoc(refPatient);
      if (docSnap.exists()) {
        this.setEvent(eventAdd);
        this.setState({ eventTmp: eventAdd });
        this.setState({ isAddEventModal: false });
        this.setState({ isNewPatient: false });
        this.setState({ isEditBookingDetail: false });
        this.setState({ isNoti: true });
        notifyTopRight("Create success ");
      } else {
        // console.log("eventAdd: ", eventAdd);
        this.setEvent(eventAdd);
        this.setState({ eventTmp: eventAdd });
        this.setState({ isAddEventModal: false });
        this.setState({ isNewPatient: false });
        this.setState({ isEditBookingDetail: false });
        this.setState({ isNoti: true });
        notifyTopRight("Create success ");
      }
    } catch (err) {
      console.log(err);
      this.setState({ isAddEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isEditBookingDetail: false });
      notifyError("Error, Please fill out the booking form");
      // this.onCancel(true);
      this.onRemoveEvent();
    }

    // this.setEvent(eventAdd);
    // this.setState({ eventTmp: eventAdd });
    // this.setState({ isAddEventModal: false });
    // this.setState({ isNewPatient: false });
    // this.setState({ isNoti: true });
  };

  onEditEvent = async () => {
    var eventAdd = this.state.eventTmp;
    console.log("event tmp before do something: ", eventAdd);
    if (this.state.isEditBookingDetail == true) {
      const currentUserName = this.context.currentUser.displayName;
      eventAdd.setExtendedProp("createBy", currentUserName);

      eventAdd.setProp("id", 6);
      eventAdd.setExtendedProp("order", this.orderRef.current.value);
      eventAdd.setExtendedProp("HN", this.hnRef.current.value);
      eventAdd.setExtendedProp("type", this.typeRef.current.value);
      eventAdd.setExtendedProp("technician", this.techRef.current.value);
      eventAdd.setExtendedProp("referDoctor", this.docRef.current.value);
      eventAdd.setExtendedProp("FirstName", this.firstnameRef.current.value);
      eventAdd.setExtendedProp("LastName", this.lastnameRef.current.value);
      eventAdd.setExtendedProp("Tel", this.phoneRef.current.value);
      eventAdd.setExtendedProp(
        "healthInsurance",
        this.healthInsuranceRef.current.value
      );
      eventAdd.setProp(
        "title",
        eventAdd.extendedProps.order // + " HN" + this.hnRef.current.value
      );
      eventAdd.setExtendedProp("note", this.noteRef.current.value);
      eventAdd.setExtendedProp("items", this.state.items);
      eventAdd.setExtendedProp("totalValue", this.state.totalValue);

      var indexOfStatusList = this.statusList.findIndex(
        (obj) => obj === this.orderStatusRef.current.value
      );
      eventAdd.setExtendedProp(
        "orderStatus",
        this.orderStatusRef.current.value
      );
      eventAdd.setProp("color", this.colorList[indexOfStatusList]);
      if (this.orderStatusRef.current.value != "CPAP-Consult") {
        eventAdd.setExtendedProp("time", "");
      }
      const statusBorrowing = ["Delivered CPAP", "Received CPAP"];

      if (statusBorrowing.includes(this.orderStatusRef.current.value)) {
        console.log("SERIAL REF: ", this.serialRef.current);
        if (this.serialRef.current == null) {
          eventAdd.setExtendedProp(
            "SerialNumber",
            this.state.eventTmp.extendedProps.SerialNumber
          );
        } else {
          eventAdd.setExtendedProp(
            "SerialNumber",
            this.serialRef.current.value
          );
        }
        eventAdd.setExtendedProp("Activity", this.activityRef.current.value);

        if (this.activityRef.current.value == "ยืมเครื่อง") {
          eventAdd.setAllDay(true);
          eventAdd.setEnd(new Date(this.state.dueDateTmp));
          eventAdd.setExtendedProp("Status", "ไม่ว่าง");
          eventAdd.setExtendedProp("Mask", this.maskRef.current.value);
          eventAdd.setExtendedProp("MaskSize", this.masksizeRef.current.value);
          eventAdd.setExtendedProp(
            "Humidifier",
            this.humidifierRef.current.value
          );
          eventAdd.setExtendedProp("RampTime", this.ramptimeRef.current.value);
          eventAdd.setExtendedProp(
            "InitialPressure",
            this.initpressureRef.current.value
          );
          eventAdd.setExtendedProp(
            "MinPressure",
            this.minpressureRef.current.value
          );
          eventAdd.setExtendedProp(
            "MaxPressure",
            this.maxpressureRef.current.value
          );
          // console.log(this.state.borrowDateTmp);
          if (
            this.state.borrowDateTmp == "" ||
            this.state.borrowDateTmp == null
          ) {
            eventAdd.setExtendedProp("BorrowDate", eventAdd.startStr);
          } else {
            eventAdd.setExtendedProp("BorrowDate", this.state.borrowDateTmp);
          }
          eventAdd.setExtendedProp("DueDate", this.state.dueDateTmp);
          eventAdd.setExtendedProp(
            "BorrowCondition",
            this.borrowconditionRef.current.value
          );

          eventAdd.setExtendedProp("ReturnDate", "");
          eventAdd.setExtendedProp("ReturnCondition", "");
        } else if (this.activityRef.current.value == "คืนเครื่อง") {
          console.log("return date: ", eventAdd.extendedProps.ReturnDate);
          console.log("return date tmp: ", this.state.returnDateTmp);

          eventAdd.setAllDay(true);
          eventAdd.setEnd(null);

          eventAdd.setExtendedProp("Status", "ว่าง");
          eventAdd.setExtendedProp("Mask", "");
          eventAdd.setExtendedProp("MaskSize", "");
          eventAdd.setExtendedProp("Humidifier", "");
          eventAdd.setExtendedProp("RampTime", "");
          eventAdd.setExtendedProp("InitialPressure", "");
          eventAdd.setExtendedProp("MinPressure", "");
          eventAdd.setExtendedProp("MaxPressure", "");
          eventAdd.setExtendedProp("BorrowDate", this.state.borrowDateTmp);
          eventAdd.setExtendedProp("DueDate", this.state.dueDateTmp);
          eventAdd.setExtendedProp("BorrowCondition", "");

          eventAdd.setExtendedProp(
            "ReturnDate",
            // eventAdd.extendedProps.ReturnDate
            this.state.returnDateTmp
            // this.returndateRef.current.value
          );
          eventAdd.setExtendedProp(
            "ReturnCondition",
            // this.state.extendedProps.ReturnCondition'
            this.returnconditionRef.current.value
          );
        }
      } else {
        eventAdd.setAllDay(true);
        eventAdd.setEnd(null);
        eventAdd.setExtendedProp("SerialNumber", "");
        eventAdd.setExtendedProp("Activity", "");
        eventAdd.setExtendedProp("Status", "");
        eventAdd.setExtendedProp("Mask", "");
        eventAdd.setExtendedProp("MaskSize", "");
        eventAdd.setExtendedProp("Humidifier", "");
        eventAdd.setExtendedProp("RampTime", "");
        eventAdd.setExtendedProp("InitialPressure", "");
        eventAdd.setExtendedProp("MinPressure", "");
        eventAdd.setExtendedProp("MaxPressure", "");
        eventAdd.setExtendedProp("BorrowDate", "");
        eventAdd.setExtendedProp("DueDate", "");
        eventAdd.setExtendedProp("BorrowCondition", "");

        eventAdd.setExtendedProp("ReturnDate", "");
        eventAdd.setExtendedProp("ReturnCondition", "");
      }
      console.log("Event to firebase: ", eventAdd);
      this.setEvent(eventAdd);
      this.setState({ eventTmp: eventAdd });
      this.setState({ isEditEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isNoti: true });
    } else {
      this.setState({ eventTmp: eventAdd });
      this.setState({ isEditEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isNoti: true });
      this.onCancel();
    }
  };

  submitCPAP = async () => {
    // try {
    const docColRef = doc(
      collection(
        db,
        "cpaps",
        this.state.eventTmp.extendedProps.SerialNumber,
        "borrowing"
      )
    );
    // var info = CpapBorrowingData;

    var payload = {
      SerialNumber: this.state.eventTmp.extendedProps.SerialNumber,
      Admin: this.context.currentUser.displayName,
      CreateAt: new Date(),
      Note: this.state.eventTmp.extendedProps.note,
      Activity: this.state.eventTmp.extendedProps.Activity,
      HN: "",
      BorrowDate: null,
      DueDate: null,
      BorrowCondition: "",
      Mask: "",
      MaskSize: "",
      Humidifier: "",
      RampTime: "",
      InitialPressure: "",
      MinPressure: "",
      MaxPressure: "",
      ReturnDate: null,
      ReturnCondition: "",
      Status: "",
      Tel: "",
      FirstName: "",
      LastName: "",
      TypeTrial: this.state.eventTmp.extendedProps.type,
    };

    if (["ยืมเครื่อง"].includes(this.state.eventTmp.extendedProps.Activity)) {
      if (this.state.eventTmp.extendedProps.BorrowDate !== "") {
        var BorrowDate = new Date(this.state.eventTmp.extendedProps.BorrowDate);
      } else {
        BorrowDate = new Date(this.state.borrowDateTmp);
      }

      if (this.state.eventTmp.extendedProps.DueDate !== "") {
        var DueDate = new Date(this.state.eventTmp.extendedProps.DueDate);
      } else {
        DueDate = new Date(this.state.dueDateTmp);
      }

      payload = {
        ...payload,
        HN: this.state.eventTmp.extendedProps.HN,
        Tel: this.state.eventTmp.extendedProps.Tel, //XXX
        FirstName: this.state.eventTmp.extendedProps.FirstName, //XXX
        LastName: this.state.eventTmp.extendedProps.LastName, //XXX
        BorrowDate: BorrowDate,
        DueDate: DueDate,
        BorrowCondition: this.state.eventTmp.extendedProps.BorrowCondition,
        Mask: this.state.eventTmp.extendedProps.Mask,
        MaskSize: this.state.eventTmp.extendedProps.MaskSize,
        Humidifier: this.state.eventTmp.extendedProps.Humidifier,
        RampTime: this.state.eventTmp.extendedProps.RampTime,
        InitialPressure: this.state.eventTmp.extendedProps.InitialPressure,
        MinPressure: this.state.eventTmp.extendedProps.MinPressure,
        MaxPressure: this.state.eventTmp.extendedProps.MaxPressure,
        Status: "ไม่ว่าง",
      };
    }
    console.log(payload);

    if (["คืนเครื่อง"].includes(this.state.eventTmp.extendedProps.Activity)) {
      console.log(
        "Return Date: ",
        this.state.eventTmp.extendedProps.ReturnDate
      );
      payload = {
        ...payload,
        HN: this.state.eventTmp.extendedProps.HN, // kan, for create activity
        Tel: "",
        FirstName: "",
        LastName: "",
        BorrowDate: null,
        DueDate: "",
        BorrowCondition: "",
        ReturnDate: new Date(this.state.eventTmp.extendedProps.ReturnDate),
        ReturnCondition: this.state.eventTmp.extendedProps.ReturnCondition,
        Status: "ว่าง",
      };
    }

    if (
      ["ส่งทำความสะอาด", "ลงทะเบียน", "ส่งเครื่องกลับศูนย์"].includes(
        this.state.eventTmp.extendedProps.Activity
      )
    ) {
      payload = { ...payload, Status: "ว่าง" };
    }

    if (["ส่งซ่อม"].includes(this.state.eventTmp.extendedProps.Activity)) {
      payload = { ...payload, Status: "ไม่ว่าง" };
    }

    if (["ยกเลิกใช้งาน"].includes(this.state.eventTmp.extendedProps.Activity)) {
      payload = { ...payload, Status: "ยกเลิกใช้งาน" };
    }

    // console.log(CpapRef.current["Activity"].value, ":", payload);
    if (
      payload["Activity"] === "ยืมเครื่อง" &&
      payload["Activity"] === this.state.eventTmp.extendedProps.Activity
    ) {
      // set cpap activity
      await setDoc(docColRef, payload)
        .then(async (data) => {
          const ref = doc(
            db,
            "cpaps",
            this.state.eventTmp.extendedProps.SerialNumber
          );
          const docSnap = await getDoc(ref);
          if (docSnap.exists()) {
            var tmpDocSnap = docSnap.data();
            tmpDocSnap.Status = payload["Status"];
            tmpDocSnap.Activity = payload["Activity"];
            tmpDocSnap.UpdatedDateTime = payload["CreateAt"];
            tmpDocSnap.LastNote = payload["Note"];

            if (payload["Activity"] == "ยืมเครื่อง") {
              tmpDocSnap.LastHN = payload["HN"];
              tmpDocSnap.LastBorrowDate = payload["BorrowDate"];
              tmpDocSnap.LastDueDate = payload["DueDate"];
              tmpDocSnap.LastBorrowCondition = payload["BorrowCondition"];
              tmpDocSnap.LastTel = payload["Tel"];
              tmpDocSnap.LastFirstName = payload["FirstName"];
              tmpDocSnap.LastLastName = payload["LastName"];
            }

            if (payload["Activity"] == "คืนเครื่อง") {
              tmpDocSnap.LastReturnDate = payload["ReturnDate"];
              tmpDocSnap.LastReturnCondition = payload["ReturnCondition"];
              tmpDocSnap.LastHN = payload["HN"];
              tmpDocSnap.LastTel = payload["Tel"];
              tmpDocSnap.LastFirstName = payload["FirstName"];
              tmpDocSnap.LastLastName = payload["LastName"];
              tmpDocSnap.LastBorrowDate = payload["BorrowDate"];
              tmpDocSnap.LastDueDate = payload["DueDate"];
              tmpDocSnap.LastBorrowCondition = payload["BorrowCondition"];
            }

            console.log("im here work well if, ", tmpDocSnap);
            // update CPAP Status to show
            await updateDoc(ref, tmpDocSnap);

            // set patient borrowings
            if (["ยืมเครื่อง", "คืนเครื่อง"].includes(payload["Activity"])) {
              if (payload["HN"] != null) {
                const refPatientBorrowing = doc(collection(db, "borrowings"));
                await setDoc(refPatientBorrowing, payload);
                console.log("-->", payload);
              }
            }
          }
          notifyTopRight("บันทึกข้อมูลสำเร็จ");
          console.log("data:", data);
        })
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด, ", err);
          console.log("err:", err);
        });
    } else {
      // set cpap activity
      await setDoc(docColRef, payload)
        .then(async (data) => {
          console.log("payload", payload);
          const ref = doc(
            db,
            "cpaps",
            this.state.eventTmp.extendedProps.SerialNumber
          );
          const docSnap = await getDoc(ref);
          if (docSnap.exists()) {
            var tmpDocSnap = docSnap.data();

            tmpDocSnap.Status = payload["Status"];
            tmpDocSnap.Activity = payload["Activity"];
            tmpDocSnap.UpdatedDateTime = payload["CreateAt"];
            tmpDocSnap.LastNote = payload["Note"];

            if (payload["Activity"] == "ยืมเครื่อง") {
              tmpDocSnap.LastHN = payload["HN"];
              tmpDocSnap.LastBorrowDate = payload["BorrowDate"];
              tmpDocSnap.LastDueDate = payload["DueDate"];
              tmpDocSnap.LastBorrowCondition = payload["BorrowCondition"];
            }

            if (payload["Activity"] == "คืนเครื่อง") {
              tmpDocSnap.LastDueDate = null;
              tmpDocSnap.LastReturnDate = payload["ReturnDate"];
              tmpDocSnap.LastReturnCondition = payload["ReturnCondition"];
            }

            console.log("im here work well else , ", tmpDocSnap);
            await updateDoc(ref, tmpDocSnap);

            // set patient borrowings

            if (["ยืมเครื่อง", "คืนเครื่อง"].includes(payload["Activity"])) {
              if (payload["HN"] != null) {
                const refPatientBorrowing = await doc(
                  collection(db, "borrowings")
                );
                await setDoc(refPatientBorrowing, payload).then(() => {
                  console.log("borrow payload", payload);
                });
                console.log("borrow payload", payload);
              }
            }
          }
          notifyTopRight("บันทึกข้อมูลสำเร็จ");
          console.log("data:", data);
        })
        .catch((err) => {
          notifyError("บันทึกข้อมูลผิดพลาด, ", err);
          console.log("err:", err);
        });
    }
    this.getCpap();
  };

  setEvent = async (eventAdd, oldEvent = undefined) => {
    if (oldEvent == undefined) {
      oldEvent = eventAdd;
    } else {
      console.log("oldEvent not undefined");
    }
    try {
      if (
        eventAdd.extendedProps.uuid == undefined ||
        eventAdd.extendedProps.uuid == null
        // || eventAdd.extendedProps.node != oldEvent.extendedProps.node
      ) {
        // const nodeIndex = this.state.nodeList.indexOf(
        //   eventAdd.extendedProps.node
        // );
        // const nodeId = this.state.nodeIdDocs[nodeIndex];
        // const ref = doc(collection(db, "nodes", nodeId, "bookings"));
        console.log(eventAdd);
        const ref = doc(collection(db, "trial"));
        await setDoc(ref, {
          title: eventAdd.title,
          id: eventAdd.id,
          start: new Date(eventAdd.startStr),
          end: eventAdd.end,
          color: eventAdd.backgroundColor,
          allDay: eventAdd.allDay,
          extendedProps: {
            order: eventAdd.extendedProps.order,
            HN: eventAdd.extendedProps.HN,
            type: eventAdd.extendedProps.type,
            orderStatus: eventAdd.extendedProps.orderStatus,
            FirstName: eventAdd.extendedProps.FirstName,
            LastName: eventAdd.extendedProps.LastName,
            // nationalId: eventAdd.extendedProps.nationalId,
            healthInsurance: eventAdd.extendedProps.healthInsurance,
            Tel: eventAdd.extendedProps.Tel,
            // node: eventAdd.extendedProps.node,
            // customer: eventAdd.extendedProps.customer,
            note: eventAdd.extendedProps.note,
            items: eventAdd.extendedProps.items,
            totalValue: eventAdd.extendedProps.totalValue,
            time: eventAdd.extendedProps.time,
            referDoctor: eventAdd.extendedProps.referDoctor,
            technician: eventAdd.extendedProps.technician,
            // latestSleepStudyDate: eventAdd.extendedProps.latestSleepStudyDate,
            SerialNumber: eventAdd.extendedProps.SerialNumber,
            Activity: eventAdd.extendedProps.Activity,
            Status: eventAdd.extendedProps.Status,
            Mask: eventAdd.extendedProps.Mask,
            MaskSize: eventAdd.extendedProps.MaskSize,
            Humidifier: eventAdd.extendedProps.Humidifier,
            RampTime: eventAdd.extendedProps.RampTime,
            InitialPressure: eventAdd.extendedProps.InitialPressure,
            MinPressure: eventAdd.extendedProps.MinPressure,
            MaxPressure: eventAdd.extendedProps.MaxPressure,
            BorrowDate: eventAdd.extendedProps.BorrowDate,
            DueDate: eventAdd.extendedProps.DueDate,
            BorrowCondition: eventAdd.extendedProps.BorrowCondition,
            ReturnDate: eventAdd.extendedProps.ReturnDate,
            ReturnCondition: eventAdd.extendedProps.ReturnCondition,
            createBy: eventAdd.extendedProps.createBy,
          },
        });
        eventAdd.setExtendedProp("uuid", ref.id);
        // const refUpdate = doc(
        //   db,
        //   "nodes",
        //   nodeId,
        //   "bookings",
        //   eventAdd.extendedProps.uuid
        // );
        const refUpdate = doc(db, "trial", eventAdd.extendedProps.uuid);

        await updateDoc(refUpdate, {
          title: eventAdd.title,
          id: eventAdd.id,
          start: new Date(eventAdd.startStr),
          end: eventAdd.end,
          color: eventAdd.backgroundColor,
          allDay: eventAdd.allDay,
          extendedProps: { ...eventAdd.extendedProps },
          // order: eventAdd.extendedProps.order,
          // hn: eventAdd.extendedProps.hn,
          // type: eventAdd.extendedProps.type,
          // status: eventAdd.extendedProps.status,
          // uuid: eventAdd.extendedProps.uuid,
          // firstname: eventAdd.extendedProps.firstname,
          // lastname: eventAdd.extendedProps.lastname,
          // nationalId: eventAdd.extendedProps.nationalId,
          // healthInsurance: eventAdd.extendedProps.healthInsurance,
          // phone: eventAdd.extendedProps.phone,
          // node: eventAdd.extendedProps.node,
          // customer: eventAdd.extendedProps.customer,
          // note: eventAdd.extendedProps.note,
          // items: eventAdd.extendedProps.items,
          // totalValue: eventAdd.extendedProps.totalValue,
          // time: eventAdd.extendedProps.time,
          // doctor: eventAdd.extendedProps.doctor,
          // technician: eventAdd.extendedProps.technician,
          // latestSleepStudyDate: eventAdd.extendedProps.latestSleepStudyDate,
        });
        // if (eventAdd.extendedProps.node != oldEvent.extendedProps.node) {
        //   const nodeIndexOld = this.state.nodeList.indexOf(
        //     oldEvent.extendedProps.node
        //   );
        //   const nodeIdOld = this.state.nodeIdDocs[nodeIndexOld];

        //   const refDelete = doc(
        //     db,
        //     "nodes",
        //     // this.nodeName,
        //     nodeIdOld,
        //     "bookings",
        //     oldEvent.extendedProps.uuid
        //   );
        //   await deleteDoc(refDelete);
        //   console.log("deleted from old node !");
        // }
      } else {
        const refUpdate = doc(db, "trial", eventAdd.extendedProps.uuid);

        await updateDoc(refUpdate, {
          title: eventAdd.title,
          id: eventAdd.id,
          start: new Date(eventAdd.startStr),
          end: eventAdd.end,
          color: eventAdd.backgroundColor,
          allDay: eventAdd.allDay,
          extendedProps: { ...eventAdd.extendedProps },
          // order: eventAdd.extendedProps.order,
          // hn: eventAdd.extendedProps.hn,
          // type: eventAdd.extendedProps.type,
          // status: eventAdd.extendedProps.status,
          // uuid: eventAdd.extendedProps.uuid,
          // firstname: eventAdd.extendedProps.firstname,
          // lastname: eventAdd.extendedProps.lastname,
          // nationalId: eventAdd.extendedProps.nationalId,
          // healthInsurance: eventAdd.extendedProps.healthInsurance,
          // phone: eventAdd.extendedProps.phone,
          // node: eventAdd.extendedProps.node,
          // customer: eventAdd.extendedProps.customer,
          // note: eventAdd.extendedProps.note,
          // items: eventAdd.extendedProps.items,
          // totalValue: eventAdd.extendedProps.totalValue,
          // time: eventAdd.extendedProps.time,
          // doctor: eventAdd.extendedProps.doctor,
          // technician: eventAdd.extendedProps.technician,
          // latestSleepStudyDate: eventAdd.extendedProps.latestSleepStudyDate,
        });
      }
      notifyTopRight("Add CPAP Trial Event !");
      if (
        ["ยืมเครื่อง", "คืนเครื่อง"].includes(eventAdd.extendedProps.Activity)
      ) {
        console.log("ยืมเครื่อง - คืนเครื่อง");
        this.submitCPAP();
      }
    } catch (err) {
      console.log(err);
      notifyError("Please fill out patient information !");

      let api = this.calendarComponentRef.current.getApi();
      api.addEvent({
        title: oldEvent.title,
        start: oldEvent.startStr,
        id: oldEvent.id,
        allDay: true,
        color: "brown",
        extendedProps: {
          order: oldEvent.extendedProps.order,
          hn: oldEvent.extendedProps.hn,
          type: oldEvent.extendedProps.type,
          status: oldEvent.extendedProps.status,
          firstname: oldEvent.extendedProps.firstname,
          lastname: oldEvent.extendedProps.lastname,
          nationalId: oldEvent.extendedProps.nationalId,
          healthInsurance: oldEvent.extendedProps.healthInsurance,
          phone: oldEvent.extendedProps.phone,
          note: oldEvent.extendedProps.note,
          node: oldEvent.extendedProps.node,
          customer: oldEvent.extendedProps.customer,
          items: oldEvent.extendedProps.items,
          totalValue: oldEvent.extendedProps.totalValue,
          time: oldEvent.extendedProps.time,
          doctor: oldEvent.extendedProps.doctor,
          technician: oldEvent.extendedProps.technician,
          createBy: oldEvent.extendedProps.createBy
            ? oldEvent.extendedProps.createBy
            : "",
        },
      });
      eventAdd.remove();
    }
  };
  setDefaultEvent = (eventInput) => {
    let api = this.calendarComponentRef.current.getApi();
    api.addEvent({
      title: eventInput.title,
      start: eventInput.startStr,
      id: eventInput.id,
      allDay: true,
      color: "brown",
      extendedProps: {
        order: eventInput.extendedProps.order,
        hn: eventInput.extendedProps.hn,
        type: eventInput.extendedProps.type,
        status: eventInput.extendedProps.status,
        firstname: eventInput.extendedProps.firstname,
        lastname: eventInput.extendedProps.lastname,
        nationalId: eventInput.extendedProps.nationalId,
        healthInsurance: eventInput.extendedProps.healthInsurance,
        phone: eventInput.extendedProps.phone,
        note: eventInput.extendedProps.note,
        node: eventInput.extendedProps.node,
        customer: eventInput.extendedProps.customer,
        items: eventInput.extendedProps.items,
        totalValue: eventInput.extendedProps.totalValue,
        time: eventInput.extendedProps.time,
        doctor: eventInput.extendedProps.doctor,
        technician: eventInput.extendedProps.technician,
      },
    });
    eventInput.remove();
  };

  eventDrag = (eventDrag) => {
    let api = this.calendarComponentRef.current.getApi();
    var allEvents = api.getEvents().filter((obj) => {
      return (
        obj.startStr === eventDrag.event.startStr && obj.extendedProps.hn !== ""
      );
    });
    let listHNEvent = allEvents.filter((element) => {
      return element.extendedProps.hn === eventDrag.event.extendedProps.hn;
    });
    // let isExists = false;
    // if (listHNEvent.length > 1) {
    //   isExists = true;
    // }
    if (listHNEvent.length > 1) {
      swal({
        title: `HN${eventDrag.event.extendedProps.hn} is already booking on this day !`,
        text: `Please recheck booking on ${eventDrag.event.startStr}`,
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      }).then((willBooking) => {
        if (willBooking) {
          this.setState({ eventTmp: eventDrag.event });
          this.setEvent(this.state.eventTmp, eventDrag.oldEvent);
        } else {
          var colorEvent = "";
          var indexOfStatusList = this.statusList.findIndex(
            (obj) => obj === eventDrag.oldEvent.extendedProps.status
          );
          colorEvent = this.colorList[indexOfStatusList];
          api.addEvent({
            title: eventDrag.oldEvent.title,
            start: eventDrag.oldEvent.startStr,
            id: eventDrag.oldEvent.id,
            allDay: true,
            color: colorEvent,
            extendedProps: {
              order: eventDrag.oldEvent.extendedProps.order,
              hn: eventDrag.oldEvent.extendedProps.hn,
              type: eventDrag.oldEvent.extendedProps.type,
              status: eventDrag.oldEvent.extendedProps.status,
              firstname: eventDrag.oldEvent.extendedProps.firstname,
              lastname: eventDrag.oldEvent.extendedProps.lastname,
              nationalId: eventDrag.oldEvent.extendedProps.nationalId,
              healthInsurance: eventDrag.oldEvent.extendedProps.healthInsurance,
              phone: eventDrag.oldEvent.extendedProps.phone,
              note: eventDrag.oldEvent.extendedProps.note,
              node: eventDrag.oldEvent.extendedProps.node,
              customer: eventDrag.oldEvent.extendedProps.customer,
              items: eventDrag.oldEvent.extendedProps.items,
              totalValue: eventDrag.oldEvent.extendedProps.totalValue,
              time: eventDrag.extendedProps.time,
              doctor: eventDrag.extendedProps.doctor,
              technician: eventDrag.extendedProps.technician,
            },
          });
          eventDrag.event.remove();
        }
      });
    } else {
      this.setState({ eventTmp: eventDrag.event });
      this.setEvent(this.state.eventTmp, eventDrag.oldEvent);
    }
  };

  CustomerList = (nodename) => {
    const index = this.state.nodeList.indexOf(nodename);
    return this.state.customerAllList[index];
  };

  eventClick = async (eventClick) => {
    console.log("eventClick: ",eventClick.event)
    const eventTmp = eventClick.event; // Access the updated state
    this.setState({ eventTmp: eventClick.event }, () => {

      this.setState({ dateTmp: eventTmp.startStr });
      if (
        eventTmp.extendedProps.BorrowDate == "" ||
        eventTmp.extendedProps.BorrowDate == null
      ) {
        this.setState({
          borrowDateTmp: eventTmp.startStr,
        });
      } else {
        this.setState({
          borrowDateTmp: eventTmp.extendedProps.BorrowDate,
        });
      }
      this.setState({
        dueDateTmp: eventTmp.extendedProps.DueDate,
      });

      if (eventTmp.extendedProps.ReturnDate == "") {
        this.setState({
          returnDateTmp: eventTmp.startStr,
        });
      } else {
        this.setState({
          returnDateTmp: eventTmp.extendedProps.ReturnDate,
        });
      }
      // this.setState({
      //   returnDateTmp: this.state.eventTmp.extendedProps.ReturnDate,
      // });
      this.setState({ resetDateTmp: eventTmp.startStr });
    });
    if (eventTmp.extendedProps.HN == "") {
      this.setState({ isEditBookingDetail: true });
    } else {
      this.setState({ isEditBookingDetail: false });
    }
    this.setState(
      {
        currentActivity: eventTmp.extendedProps.Activity,
        currentOrderStatus: eventTmp.extendedProps.orderStatus,
      },
      () => {
        if (eventTmp.extendedProps.Activity == "ยืมเครื่อง") {
          this.setState({ isEndDate: true });
        } else {
          this.setState({ isEndDate: false });
        }
      }
    );
    // this.setState({
    //   customerList: this.CustomerList(eventClick.event.extendedProps.node),
    // });
    const statusBorrowing = ["Delivered CPAP", "Received CPAP"];
    if (eventTmp.extendedProps.orderStatus == "CPAP-Consult") {
      this.setState({ isDatetime: true });
    } else {
      this.setState({ isDatetime: false });
    }
    if (
      statusBorrowing.includes(eventTmp.extendedProps.orderStatus)
    ) {
      this.setState({ isBorrowing: true });
    } else {
      this.setState({ isBorrowing: false });
    }

    this.setState({ isEditEventModal: true });
    this.setState({ isNewPatient: false });
    const itemsTmp = JSON.parse(
      JSON.stringify(eventTmp.extendedProps.items)
    );
    const totalValueTmp = eventTmp.extendedProps.totalValue;
    this.setState({ items: itemsTmp });
    this.setState({ totalValue: totalValueTmp });
  };

  onCancel = (isClear = false) => {
    this.setState({ isEditBookingDetail: false });
    // this.setState({ isEditBookingDetail: true });
    let evtmp = this.state.eventTmp;
    if (isClear) {
      evtmp.setProp("title", this.state.eventTmp.extendedProps.order);
      evtmp.setProp("id", this.state.eventTmp.id);
      evtmp.setExtendedProp("order", this.state.eventTmp.extendedProps.order);
      evtmp.setExtendedProp("hn", "");
      evtmp.setExtendedProp("firstname", "");
      evtmp.setExtendedProp("lastname", "");
      evtmp.setExtendedProp("nationalId", "");
      evtmp.setExtendedProp("healthInsurance", "กรุณาเลือก");
      evtmp.setExtendedProp("phone", "");
      evtmp.setProp("color", "green");
    }

    evtmp.setStart(new Date(this.state.resetDateTmp));
    console.log("dueDateTmp: ", this.state.dueDateTmp);
    console.log("isEdnDate: ", this.state.isEndDate);
    if (this.state.isEndDate == false) {
      evtmp.setEnd(new Date(this.state.resetDateTmp).getDate() + 1);
      console.log("no end date");
    } else {
      evtmp.setEnd(new Date(this.state.dueDateTmp));
      console.log("have end date: ", new Date(this.state.dueDateTmp));
    }
    this.setState({
      eventTmp: evtmp,
      currentActivity: "",
      currentOrderStatus: "",
      isEditBookingDetail: false,
    });
  };
  onUpdateItemValue = (key, index, value) => {
    var tmpItems = this.state.items;
    tmpItems[index][key] = value.target.value;
    tmpItems[index].total =
      tmpItems[index].unitcost * tmpItems[index].qty - tmpItems[index].discount;

    this.setState({ items: tmpItems }, () => {
      this.getTotalValue();
    });
  };
  getTotalValue = () => {
    var totalValueTmp = 0;
    var tmpItems = this.state.items;
    for (let i = 0; i < tmpItems.length; i++) {
      totalValueTmp = parseInt(totalValueTmp) + parseInt(tmpItems[i].total);
    }
    this.setState({ totalValue: totalValueTmp });
  };
  checkStatus = (e) => {
    const statusBorrowing = ["Delivered CPAP", "Received CPAP"];
    /////////////////////// Add Here ////////////////////////
    var Tmp = this.state.eventTmp;
    if (e.target.value == "Delivered CPAP") {
      Tmp.setExtendedProp("Activity", "ยืมเครื่อง");
      this.setState({ currentActivity: "ยืมเครื่อง" });
      this.setState({ eventTmp: Tmp });
    } else if (e.target.value == "Received CPAP") {
      Tmp.setExtendedProp("Activity", "คืนเครื่อง");
      this.setState({ eventTmp: Tmp });
      this.setState({ currentActivity: "คืนเครื่อง" });
    }
    /////////////////////////////////////////////////////////
    this.setState({ currentOrderStatus: e.target.value });
    if (e.target.value == "CPAP-Consult") {
      this.setState({ isDatetime: true });
    } else {
      this.setState({ isDatetime: false });
    }
    if (statusBorrowing.includes(e.target.value)) {
      this.setState({ isBorrowing: true });
    } else {
      this.setState({ isBorrowing: false });
    }
  };

  handleOnClick = (hn, id, file) => {
    this.getSignedUrl({
      folder: "reports",
      hn,
      id,
      filename: file,
    })
      .then((result) => {
        // console.log("url", result.data);
        this.setState({ fileUrl: result.data });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ createModal: true });
  };

  eventDrop = (eventDrop) => {
    this.setState({ borrowDateTmp: "" });
    this.setState({ dueDateTmp: "" });
    this.setState({ eventTmp: eventDrop.event });
    // this.setState({})
    if (this.state.eventTmp.extendedProps.HN == "") {
      this.setState({ isEditBookingDetail: true });
    }
    this.setState({ isEditBookingDetail: true });
    this.setState({ isAddEventModal: true });
    this.setState({ isNewPatient: false });
    console.log(eventDrop.event);
  };

  addSevenHours = (date) => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 7);
    return newDate;
  };
  render() {
    return (
      <Fragment>
        <Toast />
        {/* <Modal
          className="fade bd-example-modal-lg"
          show={this.state.createModal}
          onHide={() => this.setState({ createModal: false })}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Report</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-lg-12"
              style={{ height: "85vh" }}
            >
              <iframe
                className="col-lg-12 m-0 p-0"
                src={this.state.fileUrl + "#toolbar=0"}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal> */}
        <div className="animated fadeIn demo-app ">
          <Row>
            {!this.state.isLoading ? (
              <Col xl={10}>
                <Card>
                  <Card.Body
                    className="border p-2 my-0"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="demo-app-calendar" id="mycalendartest">
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          interactionPlugin,
                          listPlugin,
                          multiMonthPlugin,
                        ]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                          center: "title",
                          left: "today,listMonth,dayGridWeek,dayGridMonth,multiMonthYear",
                          right: "prev next",
                        }}
                        rerenderDelay={10}
                        // eventDurationEditable={false}
                        // editable={this.context.currentUser.roles != "staff"}
                        // droppable={this.context.currentUser.roles != "staff"}
                        eventDurationEditable={false}
                        editable={false}
                        droppable={false}
                        ref={this.calendarComponentRef}
                        weekends={this.state.calendarWeekends}
                        // eventDrop={this.eventDrag}
                        eventReceive={this.eventDrop}
                        eventClick={this.eventClick}
                        events={this.state.calendarEvents}
                        // // Get the event description from the event object
                        // const description = info.event.extendedProps.description;

                        // // Create a new element for the event title and description
                        // const titleElement = document.createElement("div");
                        // titleElement.classList.add("fc-title");
                        // titleElement.classList.add("left-align"); // Add the CSS class for text alignment

                        // // Create a <span> element to wrap the description for bold styling
                        // const descriptionElement = document.createElement("span");
                        // descriptionElement.classList.add("bold-description");
                        // descriptionElement.innerText = description;

                        // // Set the innerHTML of the titleElement with the title and the wrapped description
                        // titleElement.innerHTML = `${info.event.title}<br>${descriptionElement.outerHTML}`;

                        // return { domNodes: [titleElement] };

                        eventContent={function (info) {
                          var fullname =
                            info.event.extendedProps.FirstName +
                            " " +
                            info.event.extendedProps.LastName;
                          var type = info.event.extendedProps.type;
                          var status = info.event.extendedProps.orderStatus;
                          //   var total = info.event.extendedProps.totalValue;
                          var time = info.event.extendedProps.time;
                          var note = info.event.extendedProps.note;
                          var order = info.event.extendedProps.order;
                          var tech = info.event.extendedProps.technician;
                          var doctor = info.event.extendedProps.referDoctor;

                          var txt = `${fullname}`;

                          var description = "";

                          if (type !== "") {
                            description = description + `CPAP ${type}`;
                          }
                          if (tech !== "" || tech !== "กรุณาเลือก") {
                            description = description + "\nT: " + `${tech}`;
                          }
                          if (doctor !== "" || doctor !== "กรุณาเลือก") {
                            description = description + "\nD: " + `${doctor}`;
                          }
                          if (note !== "") {
                            description = description + `\nN: ${note}`;
                          }

                          if (status == "CPAP-Consult" && time !== "") {
                            txt = `${fullname}\n${type}\nTime: ${time}`;
                          }

                          const orderElement = document.createElement("div");
                          orderElement.classList.add(
                            "center-align",
                            "border-bottom",
                            "border-white"
                          );
                          orderElement.innerText = order;
                          orderElement.title = description;

                          // Create a new element for the event title and description
                          const titleElement = document.createElement("div");
                          titleElement.classList.add("left-align");
                          titleElement.innerText = txt;
                          titleElement.title = description;

                          const statusElement = document.createElement("div");
                          statusElement.classList.add("text-truncate");
                          statusElement.innerText = status;
                          statusElement.title = description;

                          const longOrderElement =
                            document.createElement("div");
                            if (
                              status === "Delivered CPAP" ||
                              status === "Received CPAP"
                            ) {
                              longOrderElement.classList.add(
                                "center-align",
                                "text-black",
                                "text-truncate",
                                // "border-bottom",
                                // "border-white"
                              );
                            } else {
                              longOrderElement.classList.add(
                                "center-align",
                                "text-white",
                                "text-truncate"
                              );
                            }  

                          var longOrder = order + "   " + fullname;
                          longOrderElement.innerText = longOrder;
                          longOrderElement.title = description;

                          if (
                            status === "Delivered CPAP" ||
                            status === "Received CPAP"
                          ) {
                            statusElement.classList.add(
                              "center-align",
                              "text-black"
                            );
                            return {
                              domNodes: [longOrderElement, statusElement],
                            };
                          } else {
                            statusElement.classList.add("left-align");
                            return {
                              domNodes: [
                                orderElement,
                                titleElement,
                                statusElement,
                              ],
                            };
                          }
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              <Col xl={10}>
                <div class="media d-flex align-items-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              </Col>
            )}

            <Col xl={2} /*style={{ maxWidth: "300px", maxHeight: "500px" }}*/>
              <Card>
                <Card.Body className="p-2">
                  <div className="col p-0 m-0">
                    {this.statusList.map((element, index) => {
                      return (
                        <div className="d-flex p-1 align-items-center">
                          <span
                            className="badge width30 height30 text-white"
                            style={{ backgroundColor: this.colorList[index] }}
                          >
                            {" "}
                          </span>
                          <text className="mb-2 mt-1 ml-1 pl-2 small">
                            {element}
                          </text>
                        </div>
                      );
                    })}
                  </div>
                  {this.context.currentUser.roles[0] != "staff" && (
                    <>
                      {" "}
                      <div className="row px-0 ml-2 mt-4">
                        <h4 className="text-black">
                          <b>Add Booking</b>
                        </h4>
                      </div>
                      <div className="mt-2" id="external-events">
                        {this.state.events.map((event) => (
                          <div
                            className="fc-event mt-0 ml-0 mb-2 btn btn-block btn-primary"
                            title={event.title}
                            data={event.id}
                            key={event.id}
                          >
                            {event.title}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* addEventModal */}
          <Modal
            className="fade bd-example-modal-xl"
            show={this.state.isAddEventModal}
            onHide={() => this.onHideAddEventModal()}
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>Add Event</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => this.onHideAddEventModal()}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="row col-lg-12">
                      <this.PatientInputText
                        title="Order"
                        id="order"
                        refvalue={this.orderRef}
                        // selected={this.state.eventTmp.extendedProps.order}
                      ></this.PatientInputText>
                    </div>
                    <div className="row col-lg-12">
                      <this.PatientDropDownForm
                        title="Order Status"
                        refvalue={this.orderStatusRef}
                        selected={this.state.eventTmp.extendedProps.orderStatus}
                        options={this.statusList}
                        onChange={this.checkStatus}
                      ></this.PatientDropDownForm>
                    </div>
                    <div className="row col-lg-12">
                      <this.PatientDropDownForm
                        title="Type"
                        refvalue={this.typeRef}
                        selected={this.state.eventTmp.extendedProps.type}
                        options={this.TypeOptions}
                      ></this.PatientDropDownForm>
                    </div>
                    {this.state.isBorrowing && this.state.isAddEventModal && (
                      <div className="row col-lg-12">
                        <this.PatientDropDownForm
                          title="Serial Number"
                          id="serialnumber"
                          refvalue={this.serialRef}
                          selected={
                            this.state.eventTmp.extendedProps.SerialNumber
                          }
                          options={
                            // this.orderStatusRef.current.value ==
                            this.state.currentOrderStatus == "Delivered CPAP"
                              ? this.state.availableCpapList.map(
                                  (cpap) => cpap.SerialNumber
                                )
                              : this.state.notAvailableCpapList.map(
                                  (cpap) => cpap.SerialNumber
                                )
                          }
                        ></this.PatientDropDownForm>
                      </div>
                    )}
                    <div className="row col-lg-12">
                      <this.PatientInputText
                        title="HN"
                        refvalue={this.hnRef}
                        defaultValue={this.state.eventTmp.extendedProps.HN}
                      ></this.PatientInputText>
                      <div className="form-group row col-lg-6">
                        <Button
                          variant=""
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.onSearchPatient}
                          size="sm"
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                    {this.state.isNewPatient ? (
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        ></label>

                        <div className="col-lg-4">
                          <label className="text-danger">* New Patient</label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {!this.state.eventTmp.extendedProps.HN ? (
                      <>
                        <div className="row col-lg-12">
                          <div className="form-group row col-lg-6">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Full Name
                            </label>

                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                ref={this.firstnameRef}
                                defaultValue={
                                  this.state.eventTmp.extendedProps.FirstName
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row col-lg-6">
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                ref={this.lastnameRef}
                                defaultValue={
                                  this.state.eventTmp.extendedProps.LastName
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row col-lg-12">
                          {" "}
                          <this.PatientInputText
                            title="Phone"
                            refvalue={this.phoneRef}
                            defaultValue={this.state.eventTmp.extendedProps.Tel}
                          ></this.PatientInputText>
                        </div>
                      </>
                    ) : (
                      <>
                        <this.PatientTextInfo
                          title="Full Name"
                          value={
                            this.state.eventTmp.extendedProps.FirstName +
                            " " +
                            this.state.eventTmp.extendedProps.LastName
                          }
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Phone"
                          value={this.state.eventTmp.extendedProps.Tel}
                          bold={true}
                        />
                      </>
                    )}
                    <div className="row col-lg-12">
                      <this.PatientDropDownForm
                        title="Health Insurance"
                        refvalue={this.healthInsuranceRef}
                        selected={
                          this.state.eventTmp.extendedProps.healthInsurance
                        }
                        options={this.paymentPrivilages}
                      ></this.PatientDropDownForm>
                    </div>
                    <div className="row col-lg-12">
                      <this.PatientDropDownForm
                        title="Technician"
                        refvalue={this.techRef}
                        selected={this.state.eventTmp.extendedProps.technician}
                        options={this.state.technicianList}
                      ></this.PatientDropDownForm>
                      <this.PatientInputText
                        title="Referring Doctor"
                        refvalue={this.docRef}
                        selected={this.state.eventTmp.extendedProps.referDoctor}
                        // options={this.state.doctorList}
                      ></this.PatientInputText>
                    </div>
                    <div className="row col-lg-12">
                      {" "}
                      <div className="form-group row col-lg-6">
                        <label
                          className="col-lg-4 col-form-label"
                          htmlFor="val-username"
                        >
                          {"Date"}
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-lg-5">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            todayButton="Today"
                            selected={new Date(this.state.eventTmp.start)}
                            onChange={(event, props) =>
                              this.handleDate(event, props)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.isDatetime && (
                      <>
                        <div className="row col-lg-12">
                          <div className="form-group row col-lg-6">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Time
                            </label>
                            <div className="col-lg-5">
                              <input
                                type={"time"}
                                className="form-control"
                                defaultValue={
                                  this.state.eventTmp.extendedProps.time
                                }
                                onChange={(event, props) =>
                                  this.handleTime(event, props)
                                }
                              ></input>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.isBorrowing && (
                      <>
                        {" "}
                        <div className="row col-lg-12 mt-5"></div>
                        <div className="row col-lg-12">
                          <this.PatientDropDownForm
                            title="Activity"
                            disabled={true}
                            refvalue={this.activityRef}
                            selected={
                              this.state.eventTmp.extendedProps.Activity
                            }
                            options={this.CpapBorrowingOptions}
                            onChange={(e) => this.setCurrentActivity(e)}
                          ></this.PatientDropDownForm>
                        </div>
                        {this.state.currentActivity == "ยืมเครื่อง" ? (
                          <>
                            {" "}
                            <div className="row col-lg-12">
                              <this.PatientDropDownForm
                                title="Mask"
                                refvalue={this.maskRef}
                                selected={
                                  this.state.eventTmp.extendedProps.Mask
                                }
                                options={this.Masks}
                              ></this.PatientDropDownForm>{" "}
                              <this.PatientDropDownForm
                                title="Mask Size"
                                refvalue={this.masksizeRef}
                                selected={
                                  this.state.eventTmp.extendedProps.MaskSize
                                }
                                options={this.MaskSizes}
                              ></this.PatientDropDownForm>
                            </div>
                            <div className="row col-lg-12">
                              <this.PatientDropDownForm
                                title="Humidifier"
                                refvalue={this.humidifierRef}
                                selected={
                                  this.state.eventTmp.extendedProps.Humidifier
                                }
                                options={this.HumidifierOptions}
                              ></this.PatientDropDownForm>{" "}
                              <this.PatientDropDownForm
                                title="Ramp Time (minute)"
                                refvalue={this.ramptimeRef}
                                selected={
                                  this.state.eventTmp.extendedProps.RampTime
                                }
                                options={this.RampTimeOptions}
                              ></this.PatientDropDownForm>
                            </div>
                            <div className="row col-lg-12">
                              <this.PatientDropDownForm
                                title="Initial Pressure (cmH2o)"
                                refvalue={this.initpressureRef}
                                selected={
                                  this.state.eventTmp.extendedProps
                                    .InitialPressure
                                }
                                options={this.PressureOptions}
                              ></this.PatientDropDownForm>{" "}
                            </div>
                            <div className="row col-lg-12">
                              <this.PatientDropDownForm
                                title="Min Pressure (cmH2o)"
                                refvalue={this.minpressureRef}
                                selected={
                                  this.state.eventTmp.extendedProps.MinPressure
                                }
                                options={this.PressureOptions}
                              ></this.PatientDropDownForm>
                              <this.PatientDropDownForm
                                title="Max Pressure (cmH2o)"
                                refvalue={this.maxpressureRef}
                                selected={
                                  this.state.eventTmp.extendedProps.MaxPressure
                                }
                                options={this.PressureOptions}
                              ></this.PatientDropDownForm>
                            </div>
                            <div className="row col-lg-12">
                              <div className="form-group row col-lg-6">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Borrow Date
                                </label>
                                <div className="col-lg-5">
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.BorrowDate
                                          )
                                        : new Date(this.state.eventTmp.start)
                                    }
                                    onChange={(event, props) =>
                                      this.handleDateBorrowing(
                                        event,
                                        props,
                                        "BorrowDate",
                                        false
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group row col-lg-6">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Due Date
                                </label>
                                <div className="col-lg-5">
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .DueDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .DueDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.DueDate
                                          )
                                        : new Date(this.state.eventTmp.start)
                                    }
                                    onChange={(event, props) =>
                                      this.handleDateBorrowing(
                                        event,
                                        props,
                                        "DueDate",
                                        false
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-12">
                              <this.PatientInputText
                                title="Borrow Condition"
                                refvalue={this.borrowconditionRef}
                                defaultValue={
                                  this.state.eventTmp.extendedProps
                                    .BorrowCondition
                                }
                              />
                            </div>
                          </>
                        ) : this.state.currentActivity == "คืนเครื่อง" ? (
                          <>
                            {" "}
                            <div className="row col-lg-12">
                              <div className="form-group row col-lg-6">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Borrow Date
                                </label>
                                <div className="col-lg-5">
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.BorrowDate
                                          )
                                        : new Date(this.state.eventTmp.start)
                                    }
                                    onChange={(event, props) =>
                                      this.handleDateBorrowing(
                                        event,
                                        props,
                                        "BorrowDate",
                                        true
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group row col-lg-6">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Due Date
                                </label>
                                <div className="col-lg-5">
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .DueDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .DueDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.DueDate
                                          )
                                        : new Date(this.state.eventTmp.start)
                                    }
                                    onChange={(event, props) =>
                                      this.handleDateBorrowing(
                                        event,
                                        props,
                                        "DueDate",
                                        true
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-12">
                              <div className="form-group row col-lg-6">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Return Date
                                </label>
                                <div className="col-lg-5">
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .ReturnDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .ReturnDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.ReturnDate
                                          )
                                        : new Date(this.state.eventTmp.start)
                                    }
                                    onChange={(event, props) =>
                                      this.handleDateBorrowing(
                                        event,
                                        props,
                                        "ReturnDate",
                                        true
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row col-lg-12">
                              <this.PatientInputText
                                title="Return Condition"
                                refvalue={this.returnconditionRef}
                                defaultValue={
                                  this.state.eventTmp.extendedProps
                                    .ReturnCondition
                                }
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    <div className="row col-lg-12">
                      <PatientInputTextArea
                        title={"Note"}
                        refvalue={this.noteRef}
                        defaultValue={this.state.eventTmp.extendedProps.note}
                      ></PatientInputTextArea>
                    </div>
                    <this.ItemListTable
                      items={this.state.items}
                      itemList={this.state.itemList}
                      totalValue={this.state.totalValue}
                      setItems={this.setItems}
                      onUpdateItemValue={this.onUpdateItemValue}
                    ></this.ItemListTable>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => this.onHideAddEventModal()}
              >
                Cancel
              </Button>
              <Button
                variant=""
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // console.log("ondrop: ", this.state.eventTmp);
                  let api = this.calendarComponentRef.current.getApi();
                  var allEvents = api.getEvents().filter((obj) => {
                    return (
                      obj.startStr === this.state.eventTmp.startStr &&
                      obj.extendedProps.hn !== "" &&
                      obj.extendedProps.hn != null
                    );
                  });
                  // console.log("allEvents:", allEvents);
                  let listHNEvent = allEvents.filter((element) => {
                    return (
                      element.extendedProps.HN === this.hnRef.current.value
                    );
                  });
                  // console.log("listHNEvent 1403", listHNEvent);

                  if (listHNEvent.length > 1) {
                    swal({
                      title: `HN${this.hnRef.current.value} is already booking on this day !`,
                      text: `Please recheck booking on ${this.state.eventTmp.startStr}`,
                      icon: "warning",
                      buttons: ["Cancel", "Confirm"],
                      dangerMode: true,
                    }).then((willBooking) => {
                      if (willBooking) {
                        this.onAddEvent();
                      }
                    });
                  } else {
                    this.onAddEvent();
                  }
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* EditEventModal */}
          <Modal
            className="fade bd-example-modal-lg"
            show={this.state.isEditEventModal}
            onHide={() => {
              this.onHideEditEventModal();
              this.onCancel();
              // let evtmp = this.state.eventTmp;
              // evtmp.setStart(new Date(this.state.resetDateTmp));
              // console.log("dueDateTmp: ", this.state.dueDateTmp);
              // console.log("isEdnDate: ", this.state.isEndDate);
              // if (this.state.isEndDate == false) {
              //   evtmp.setEnd(new Date(this.state.resetDateTmp).getDate() + 1);
              //   console.log("no end date");
              // } else {
              //   evtmp.setEnd(new Date(this.state.dueDateTmp));
              //   console.log(
              //     "have end date: ",
              //     new Date(this.state.dueDateTmp)
              //   );
              // }
              // this.setState({ eventTmp: evtmp });
            }}
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>Edit Event</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => {
                  this.onHideEditEventModal();
                  this.onCancel();
                }}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              {["super_admin", "sale"].includes(
                this.context.currentUser.roles[0]
              ) && (
                <Button
                  variant=""
                  type="button"
                  className="btn btn-secondary ml-4"
                  onClick={() => {
                    if (this.state.eventTmp.extendedProps.HN != "") {
                      this.setState({
                        isEditBookingDetail: !this.state.isEditBookingDetail,
                      });
                    }
                  }}
                  size="sm"
                >
                  Edit
                </Button>
              )}
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-12">
                    {this.state.isEditBookingDetail ? (
                      <>
                        {" "}
                        <div className="row col-lg-12">
                          <this.PatientInputText
                            title="Order"
                            id="order"
                            refvalue={this.orderRef}
                            defaultValue={
                              this.state.eventTmp.extendedProps.order
                            }
                            // selected={this.state.eventTmp.extendedProps.order}
                          ></this.PatientInputText>
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientDropDownForm
                            title="Order Status"
                            refvalue={this.orderStatusRef}
                            selected={
                              this.state.eventTmp.extendedProps.orderStatus
                            }
                            options={this.statusList}
                            onChange={this.checkStatus}
                          ></this.PatientDropDownForm>
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientDropDownForm
                            title="Type"
                            refvalue={this.typeRef}
                            selected={this.state.eventTmp.extendedProps.type}
                            options={this.TypeOptions}
                          ></this.PatientDropDownForm>
                        </div>
                        {this.state.isBorrowing &&
                          this.state.isEditEventModal &&
                          this.state.eventTmp.extendedProps.SerialNumber ==
                            "" && (
                            <div className="row col-lg-12">
                              <this.PatientDropDownForm
                                title="Serial Number"
                                id="serialnumber"
                                refvalue={this.serialRef}
                                // selected={
                                //   this.state.eventTmp.extendedProps.SerialNumber
                                // }
                                options={
                                  this.state.currentOrderStatus ==
                                  "Delivered CPAP"
                                    ? this.state.availableCpapList.map(
                                        (cpap) => cpap.SerialNumber
                                      )
                                    : this.state.notAvailableCpapList.map(
                                        (cpap) => cpap.SerialNumber
                                      )
                                }
                              ></this.PatientDropDownForm>
                            </div>
                          )}
                        {this.state.isBorrowing &&
                          this.state.isEditEventModal &&
                          this.state.eventTmp.extendedProps.SerialNumber !=
                            "" && (
                            <div className="row col-lg-12">
                              <this.PatientTextInfo
                                title="Serial Number"
                                value={
                                  this.state.eventTmp.extendedProps.SerialNumber
                                }
                                bold={true}
                              ></this.PatientTextInfo>
                            </div>
                          )}
                        <div className="row col-lg-12">
                          <this.PatientInputText
                            title="HN"
                            refvalue={this.hnRef}
                            defaultValue={this.state.eventTmp.extendedProps.HN}
                          ></this.PatientInputText>
                          <div className="form-group row col-lg-6">
                            <Button
                              variant=""
                              type="button"
                              className="btn btn-secondary"
                              onClick={this.onSearchPatient}
                              size="sm"
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                        {this.state.isNewPatient ? (
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            ></label>

                            <div className="col-lg-4">
                              <label className="text-danger">
                                * New Patient
                              </label>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="row col-lg-12">
                          <div className="form-group row col-lg-6">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              Full Name
                            </label>

                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                ref={this.firstnameRef}
                                defaultValue={
                                  this.state.eventTmp.extendedProps.FirstName
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row col-lg-6">
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                ref={this.lastnameRef}
                                defaultValue={
                                  this.state.eventTmp.extendedProps.LastName
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row col-lg-12">
                          {" "}
                          <this.PatientInputText
                            title="Phone"
                            refvalue={this.phoneRef}
                            defaultValue={this.state.eventTmp.extendedProps.Tel}
                          ></this.PatientInputText>
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientDropDownForm
                            title="Health Insurance"
                            refvalue={this.healthInsuranceRef}
                            selected={
                              this.state.eventTmp.extendedProps.healthInsurance
                            }
                            options={this.paymentPrivilages}
                          ></this.PatientDropDownForm>
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientDropDownForm
                            title="Technician"
                            refvalue={this.techRef}
                            selected={
                              this.state.eventTmp.extendedProps.technician
                            }
                            options={this.state.technicianList}
                          ></this.PatientDropDownForm>
                          <this.PatientInputText
                            title="Referring Doctor"
                            refvalue={this.docRef}
                            selected={
                              this.state.eventTmp.extendedProps.referDoctor
                            }
                            // options={this.state.doctorList}
                          ></this.PatientInputText>
                        </div>
                        <div className="row col-lg-12">
                          {" "}
                          <div className="form-group row col-lg-6">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="val-username"
                            >
                              {"Date"}
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-lg-5">
                              <DatePicker
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                todayButton="Today"
                                selected={new Date(this.state.eventTmp.start)}
                                onChange={(event, props) =>
                                  this.handleDate(event, props)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.isDatetime && (
                          <>
                            <div className="row col-lg-12">
                              <div className="form-group row col-lg-6">
                                <label
                                  className="col-lg-4 col-form-label"
                                  htmlFor="val-username"
                                >
                                  Time
                                </label>
                                <div className="col-lg-5">
                                  <input
                                    type={"time"}
                                    className="form-control"
                                    defaultValue={
                                      this.state.eventTmp.extendedProps.time
                                    }
                                    onChange={(event, props) =>
                                      this.handleTime(event, props)
                                    }
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {this.state.isBorrowing && (
                          <>
                            {" "}
                            <div className="row col-lg-12 mt-5"></div>
                            <div className="row col-lg-12">
                              <this.PatientDropDownForm
                                title="Activity"
                                disabled={true}
                                refvalue={this.activityRef}
                                selected={
                                  this.state.eventTmp.extendedProps.Activity
                                }
                                options={this.CpapBorrowingOptions}
                                onChange={(e) => this.setCurrentActivity(e)}
                              ></this.PatientDropDownForm>
                            </div>
                            {this.state.currentActivity == "ยืมเครื่อง" ? (
                              <>
                                {" "}
                                <div className="row col-lg-12">
                                  <this.PatientDropDownForm
                                    title="Mask"
                                    refvalue={this.maskRef}
                                    selected={
                                      this.state.eventTmp.extendedProps.Mask
                                    }
                                    options={this.Masks}
                                  ></this.PatientDropDownForm>{" "}
                                  <this.PatientDropDownForm
                                    title="Mask Size"
                                    refvalue={this.masksizeRef}
                                    selected={
                                      this.state.eventTmp.extendedProps.MaskSize
                                    }
                                    options={this.MaskSizes}
                                  ></this.PatientDropDownForm>
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientDropDownForm
                                    title="Humidifier"
                                    refvalue={this.humidifierRef}
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .Humidifier
                                    }
                                    options={this.HumidifierOptions}
                                  ></this.PatientDropDownForm>{" "}
                                  <this.PatientDropDownForm
                                    title="Ramp Time (minute)"
                                    refvalue={this.ramptimeRef}
                                    selected={
                                      this.state.eventTmp.extendedProps.RampTime
                                    }
                                    options={this.RampTimeOptions}
                                  ></this.PatientDropDownForm>
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientDropDownForm
                                    title="Initial Pressure (cmH2o)"
                                    refvalue={this.initpressureRef}
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .InitialPressure
                                    }
                                    options={this.PressureOptions}
                                  ></this.PatientDropDownForm>{" "}
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientDropDownForm
                                    title="Min Pressure (cmH2o)"
                                    refvalue={this.minpressureRef}
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .MinPressure
                                    }
                                    options={this.PressureOptions}
                                  ></this.PatientDropDownForm>
                                  <this.PatientDropDownForm
                                    title="Max Pressure (cmH2o)"
                                    refvalue={this.maxpressureRef}
                                    selected={
                                      this.state.eventTmp.extendedProps
                                        .MaxPressure
                                    }
                                    options={this.PressureOptions}
                                  ></this.PatientDropDownForm>
                                </div>
                                <div className="row col-lg-12">
                                  <div className="form-group row col-lg-6">
                                    <label
                                      className="col-lg-4 col-form-label"
                                      htmlFor="val-username"
                                    >
                                      Borrow Date
                                    </label>
                                    <div className="col-lg-5">
                                      <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        selected={
                                          this.state.eventTmp.extendedProps
                                            .BorrowDate != "" &&
                                          this.state.eventTmp.extendedProps
                                            .BorrowDate != null
                                            ? new Date(
                                                this.state.eventTmp.extendedProps.BorrowDate
                                              )
                                            : new Date(
                                                this.state.eventTmp.start
                                              )
                                        }
                                        onChange={(event, props) =>
                                          this.handleDateBorrowing(
                                            event,
                                            props,
                                            "BorrowDate",
                                            false
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row col-lg-6">
                                    <label
                                      className="col-lg-4 col-form-label"
                                      htmlFor="val-username"
                                    >
                                      Due Date
                                    </label>
                                    <div className="col-lg-5">
                                      <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        selected={
                                          this.state.eventTmp.extendedProps
                                            .DueDate != "" &&
                                          this.state.eventTmp.extendedProps
                                            .DueDate != null
                                            ? new Date(
                                                this.state.eventTmp.extendedProps.DueDate
                                              )
                                            : new Date(
                                                this.state.eventTmp.start
                                              )
                                        }
                                        onChange={(event, props) =>
                                          this.handleDateBorrowing(
                                            event,
                                            props,
                                            "DueDate",
                                            false
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientInputText
                                    title="Borrow Condition"
                                    refvalue={this.borrowconditionRef}
                                    defaultValue={
                                      this.state.eventTmp.extendedProps
                                        .BorrowCondition
                                    }
                                  />
                                </div>
                              </>
                            ) : this.state.currentActivity == "คืนเครื่อง" ? (
                              <>
                                {" "}
                                <div className="row col-lg-12">
                                  <div className="form-group row col-lg-6">
                                    <label
                                      className="col-lg-4 col-form-label"
                                      htmlFor="val-username"
                                    >
                                      Borrow Date
                                    </label>
                                    <div className="col-lg-5">
                                      <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        selected={
                                          this.state.eventTmp.extendedProps
                                            .BorrowDate != "" &&
                                          this.state.eventTmp.extendedProps
                                            .BorrowDate
                                            ? new Date(
                                                this.state.eventTmp.extendedProps.BorrowDate
                                              )
                                            : new Date(
                                                this.state.eventTmp.start
                                              )
                                        }
                                        onChange={(event, props) =>
                                          this.handleDateBorrowing(
                                            event,
                                            props,
                                            "BorrowDate",
                                            true
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row col-lg-6">
                                    <label
                                      className="col-lg-4 col-form-label"
                                      htmlFor="val-username"
                                    >
                                      Due Date
                                    </label>
                                    <div className="col-lg-5">
                                      <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        selected={
                                          this.state.eventTmp.extendedProps
                                            .DueDate != "" &&
                                          this.state.eventTmp.extendedProps
                                            .DueDate
                                            ? new Date(
                                                this.state.eventTmp.extendedProps.DueDate
                                              )
                                            : new Date(
                                                this.state.eventTmp.start
                                              )
                                        }
                                        onChange={(event, props) =>
                                          this.handleDateBorrowing(
                                            event,
                                            props,
                                            "DueDate",
                                            true
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row col-lg-12">
                                  <div className="form-group row col-lg-6">
                                    <label
                                      className="col-lg-4 col-form-label"
                                      htmlFor="val-username"
                                    >
                                      Return Date
                                    </label>
                                    <div className="col-lg-5">
                                      <DatePicker
                                        className="form-control"
                                        dateFormat="yyyy-MM-dd"
                                        selected={
                                          this.state.eventTmp.extendedProps
                                            .ReturnDate != "" &&
                                          this.state.eventTmp.extendedProps
                                            .ReturnDate
                                            ? new Date(
                                                this.state.eventTmp.extendedProps.ReturnDate
                                              )
                                            : new Date(
                                                this.state.eventTmp.start
                                              )
                                        }
                                        onChange={(event, props) =>
                                          this.handleDateBorrowing(
                                            event,
                                            props,
                                            "ReturnDate",
                                            true
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientInputText
                                    title="Return Condition"
                                    refvalue={this.returnconditionRef}
                                    defaultValue={
                                      this.state.eventTmp.extendedProps
                                        .ReturnCondition
                                    }
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        <div className="row col-lg-12">
                          <PatientInputTextArea
                            title={"Note"}
                            refvalue={this.noteRef}
                            defaultValue={
                              this.state.eventTmp.extendedProps.note
                            }
                          ></PatientInputTextArea>
                        </div>
                        <this.ItemListTable
                          items={this.state.items}
                          itemList={this.state.itemList}
                          totalValue={this.state.totalValue}
                          setItems={this.setItems}
                          onUpdateItemValue={this.onUpdateItemValue}
                        ></this.ItemListTable>
                      </>
                    ) : (
                      // text info
                      <>
                        {" "}
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Order"
                            value={this.state.eventTmp.extendedProps.order}
                            bold={true}
                          />
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Order Status"
                            value={
                              this.state.eventTmp.extendedProps.orderStatus
                            }
                            bold={true}
                          />
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Type"
                            value={this.state.eventTmp.extendedProps.type}
                            bold={true}
                          />
                        </div>
                        {this.state.isBorrowing && (
                          <div className="row col-lg-12">
                            {/* <this.PatientTextInfo
                              title={"Serial Number"}
                              value={
                                this.state.eventTmp.extendedProps.SerialNumber
                              }
                            ></this.PatientTextInfo> */}
                            <this.PatientLinkInfo
                              title="Serial Number"
                              value={
                                this.state.eventTmp.extendedProps.SerialNumber
                              }
                              bold={true}
                              path={
                                "/cpap-page/" +
                                this.state.eventTmp.extendedProps.SerialNumber
                              }
                            />
                          </div>
                        )}
                        <div className="row col-lg-12">
                          {/* <this.PatientTextInfo
                            title={"HN"}
                            value={this.state.eventTmp.extendedProps.HN}
                          ></this.PatientTextInfo> */}
                          <this.PatientLinkInfo
                            title="HN"
                            value={this.state.eventTmp.extendedProps.HN}
                            bold={true}
                            path={
                              "/patient-page/" +
                              this.state.eventTmp.extendedProps.HN
                            }
                          />
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Full Name"
                            value={
                              this.state.eventTmp.extendedProps.FirstName +
                              " " +
                              this.state.eventTmp.extendedProps.LastName
                            }
                            bold={true}
                          />
                          <this.PatientTextInfo
                            title="Phone"
                            value={this.state.eventTmp.extendedProps.Tel}
                            bold={true}
                          />
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Health Insurance"
                            value={
                              this.state.eventTmp.extendedProps.healthInsurance
                            }
                            bold={true}
                          />
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Technician"
                            value={this.state.eventTmp.extendedProps.technician}
                            bold={true}
                          />
                          <this.PatientTextInfo
                            title="Doctor"
                            value={
                              this.state.eventTmp.extendedProps.referDoctor
                            }
                            bold={true}
                          />
                        </div>
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Date"
                            value={this.state.eventTmp.startStr}
                            bold={true}
                          />
                        </div>
                        {this.state.isDatetime && (
                          <div className="row col-lg-12">
                            <this.PatientTextInfo
                              title="Time"
                              value={this.state.eventTmp.extendedProps.time}
                              bold={true}
                            />
                          </div>
                        )}
                        {this.state.isBorrowing && (
                          <>
                            {" "}
                            <div className="row col-lg-12 mt-5"></div>
                            <div className="row col-lg-12">
                              <this.PatientTextInfo
                                title="Activity"
                                value={
                                  this.state.eventTmp.extendedProps.Activity
                                }
                                bold={true}
                              />
                            </div>
                            {this.state.currentActivity == "ยืมเครื่อง" ? (
                              <>
                                {" "}
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Mask"
                                    value={
                                      this.state.eventTmp.extendedProps.Mask
                                    }
                                    bold={true}
                                  />
                                  <this.PatientTextInfo
                                    title="Mask Size"
                                    value={
                                      this.state.eventTmp.extendedProps.MaskSize
                                    }
                                    bold={true}
                                  />
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Humidifier"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .Humidifier
                                    }
                                    bold={true}
                                  />
                                  <this.PatientTextInfo
                                    title="Ramp Time (minute)"
                                    value={
                                      this.state.eventTmp.extendedProps.RampTime
                                    }
                                    bold={true}
                                  />
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Initial Pressure (cmH2o)"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .InitialPressure
                                    }
                                    bold={true}
                                  />
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Min Pressure (cmH2o)"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .MinPressure
                                    }
                                    bold={true}
                                  />
                                  <this.PatientTextInfo
                                    title="Max Pressure (cmH2o)"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .MaxPressure
                                    }
                                    bold={true}
                                  />
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Borrow Date"
                                    // value={new Date(
                                    //   this.state.eventTmp.extendedProps.BorrowDate
                                    // )
                                    //   .toISOString()
                                    //   .slice(0, 10)}
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.BorrowDate
                                          )
                                            .toISOString()
                                            .slice(0, 10)
                                        : this.state.borrowDateTmp != ""
                                        ? new Date(this.state.borrowDateTmp)
                                            .toISOString()
                                            .slice(0, 10)
                                        : new Date(this.state.eventTmp.start)
                                            .toISOString()
                                            .slice(0, 10)
                                    }
                                    bold={true}
                                  />
                                  <this.PatientTextInfo
                                    title="Due Date"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .DueDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .DueDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.DueDate
                                          )
                                            .toISOString()
                                            .slice(0, 10)
                                        : this.state.eventTmp.extendedProps
                                            .DueDate != ""
                                        ? new Date(this.state.dueDateTmp)
                                            .toISOString()
                                            .slice(0, 10)
                                        : new Date(this.state.eventTmp.start)
                                            .toISOString()
                                            .slice(0, 10)
                                    }
                                    bold={true}
                                  />
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Borrow Condition"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .BorrowCondition
                                    }
                                    bold={true}
                                  />
                                </div>
                              </>
                            ) : this.state.currentActivity == "คืนเครื่อง" ? (
                              <>
                                {" "}
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Borrow Date"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .BorrowDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.BorrowDate
                                          )
                                            .toISOString()
                                            .slice(0, 10)
                                        : this.state.borrowDateTmp != ""
                                        ? new Date(this.state.borrowDateTmp)
                                            .toISOString()
                                            .slice(0, 10)
                                        : new Date(this.state.eventTmp.start)
                                            .toISOString()
                                            .slice(0, 10)
                                    }
                                    bold={true}
                                  />
                                  <this.PatientTextInfo
                                    title="Due Date"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .DueDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .DueDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.DueDate
                                          )
                                            .toISOString()
                                            .slice(0, 10)
                                        : this.state.dueDateTmp != ""
                                        ? new Date(this.state.dueDateTmp)
                                            .toISOString()
                                            .slice(0, 10)
                                        : new Date(this.state.eventTmp.start)
                                            .toISOString()
                                            .slice(0, 10)
                                    }
                                    bold={true}
                                  />
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Return Date"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .ReturnDate != "" &&
                                      this.state.eventTmp.extendedProps
                                        .ReturnDate != null
                                        ? new Date(
                                            this.state.eventTmp.extendedProps.ReturnDate
                                          )
                                            .toISOString()
                                            .slice(0, 10)
                                        : this.state.returnDateTmp != ""
                                        ? new Date(this.state.returnDateTmp)
                                            .toISOString()
                                            .slice(0, 10)
                                        : new Date(this.state.eventTmp.start)
                                            .toISOString()
                                            .slice(0, 10)
                                    }
                                    bold={true}
                                  />
                                </div>
                                <div className="row col-lg-12">
                                  <this.PatientTextInfo
                                    title="Return Condition"
                                    value={
                                      this.state.eventTmp.extendedProps
                                        .ReturnCondition
                                    }
                                    bold={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                        <div className="row col-lg-12">
                          <this.PatientTextInfo
                            title="Note"
                            value={this.state.eventTmp.extendedProps.note}
                            bold={true}
                          />
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th className="center">No.</th>
                                <th className="center">Item Name</th>
                                <th className="center">Item Code</th>
                                <th className="center">Unit Cost (THB)</th>
                                <th className="center">Qty</th>
                                <th className="center">Discount (THB)</th>
                                <th className="center">Total (THB)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.items.map((element, index) => {
                                return (
                                  <tr>
                                    <td className="">
                                      <b>{element.id}</b>
                                    </td>
                                    <td>
                                      <b>{element.item}</b>
                                    </td>
                                    <td className="left strong">
                                      <b>{element.itemcode}</b>
                                    </td>
                                    <td className="left">
                                      {" "}
                                      <b>{element.unitcost}</b>
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <b>{element.qty}</b>
                                    </td>
                                    <td className="center">
                                      {" "}
                                      <b>{element.discount}</b>
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <b>{element.total}</b>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-5 ml-auto">
                            <table className="table table-clear">
                              <tbody>
                                <tr>
                                  <td className="left">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="right">
                                    <strong>
                                      {this.state.totalValue + " THB"}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <>
                {" "}
                <div>
                  {this.context.currentUser.roles[0] == "super_admin" && (
                    <Button
                      variant=""
                      className="btn btn-danger"
                      onClick={() => {
                        swal({
                          title: "Are you sure to delete ?",
                          text: "Once deleted, you will not be able to recover this booking event !",
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            this.onRemoveEvent(true);
                            // swal("Your booking event has been deleted!", {
                            //   icon: "success",
                            // });
                          }
                        });
                        this.onHideEditEventModal();
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    variant="light"
                    className="mr-2"
                    onClick={() => {
                      this.onHideEditEventModal();
                      this.onCancel();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant=""
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      // console.log("ondrop: ", this.state.eventTmp);
                      let api = this.calendarComponentRef.current.getApi();
                      var allEvents = api.getEvents().filter((obj) => {
                        return (
                          obj.startStr === this.state.eventTmp.startStr &&
                          obj.extendedProps.HN !== "" &&
                          obj.extendedProps.HN != null
                        );
                      });
                      // console.log("allEvents:", allEvents);
                      let listHNEvent = allEvents.filter((element) => {
                        return (
                          this.hnRef.current &&
                          this.hnRef.current.value !== null &&
                          element.extendedProps.HN === this.hnRef.current.value
                        );
                      });
                      // console.log("listHNEvent 1403", listHNEvent);

                      if (listHNEvent.length > 1) {
                        swal({
                          title: `HN${this.hnRef.current.value} is already booking on this day !`,
                          text: `Please recheck booking on ${this.state.eventTmp.startStr}`,
                          icon: "warning",
                          buttons: ["Cancel", "Confirm"],
                          dangerMode: true,
                        }).then((willBooking) => {
                          if (willBooking) {
                            this.onEditEvent();
                          }
                        });
                      } else {
                        this.onEditEvent();
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </>
            </Modal.Footer>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default TrialCalendar;
