const preSleepInfoData = {
  //Personal Information
  firstName: "",
  lastName: "",
  gender: "",
  nationalId: "",
  address: "",
  email: "",
  dateOfBirth: "",
  age: "",
  weight: "",
  height: "",

  //sleep behavior
  bedTime: "",
  wakeupTime: "",
  totalSleepHours: "",
  fallAsleepTime: "",
  returningToSleepTime: "",
  sleepBreaks: "",
  nightAwakenings: "",
  difficultyFallingAsleepNewPlace: "",

  //SymptomAnalysis
  snoringNoise: "",
  snoringLoudness: "",
  sleepDeprivedPartners: "",
  breathingPausesDuringSleep: "",
  chokingSensationDuringSleep: "",
  bedwetting: "",
  heartPalpitationsDuringSleep: "",
  chestPainDuringSleep: "",
  teethGrindingDuringSleep: "",
  mouthBreathing: "",
  limbJerkingDuringSleep: "",
  sleepBehaviorDisorder: "",
  uncomfortableLegs: "",

  //Daytime
  wakingUpNotRefreshed: "",
  wakingUpDizzy: "",
  wakingUpHeadache: "",
  sleepyAfternoon: "",
  howLongSleepyAfternoon: "",
  afternoonNap: "",
  afternoonNapCount: "",
  sleepyDriving: "",
  carAccidentFallingAsleep: "",
  memoryLapses: "",
  moodDisorders: "",
  sexualDysfunction: "",

  //pill
  currentMedications: "",
  currentMedicationsNote: "",
  sleepingPills: "",
  sleepingPillsName: "",
  CBDOil: "",
  CBDOilNote: "",
  medicationsSubply: "",

  //
  tuberculosisSpread: "",
  obesityMoreThan35: "",
  weightGain: "",
  weightGainNote: "",
  highBloodPressure: "",
  diabetes: "",
  highFat: "",
  ischemicHeartDisease: "",
  heartAttack: "",
  arrhythmia: "",
  swollenLegs: "",
  narrowedBloodVesselsInBrain: "",
  allergicRhinitis: "",
  emphysema: "",
  asthma: "",
  thyroidDisease: "",
  claustrophobia: "",
  anxietyOrDepression: "",
  infectiousDiseases: "",
  infectiousDiseasesNote: "",
  familySleepDisorders: "",
  familySleepDisordersNote: "",

  //Narcolepsy
  nightmare: "",
  hallucinations: "",
  cataplexy: "",
  sleepParalysis: "",

  //Epworth Sleepiness Scala
  readingSleepiness: "",
  tvWatchingSleepiness: "",
  publicPlaceSleepiness: "",
  longTripPassengerSleepiness: "",
  afternoonRestSleepiness: "",
  conversationSleepiness: "",
  afterLunchSleepiness: "",
  trafficStopSleepiness: "",

  //STOP-BANG
  snoring: "",
  tiredness: "",
  observedStopBreathing: "",
  pressure: "",
  BMIHigherThan35: "",
  ageOlderThan50: "",
  neckCircumferenceGreaterThan40cm: "",
  males: "",
};

export default preSleepInfoData;
