import React, { Fragment, useState, useRef, useEffect } from "react";

import { Button, Modal } from "react-bootstrap";
import { OrderEditForm } from "./OrderEditForm";
import { db } from "../../../firebase";
import { setDoc, getDoc, updateDoc, doc, collection } from "firebase/firestore";
import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";

const DetailModal = (props) => {
  const getSignedUrl = httpsCallable(functions, "getSignedUrl");

  const [isLoding, setIsLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  const [startDate, setStartDate] = useState(props.order.warrantyStartDate);
  const [endDate, setEndDate] = useState(props.order.warrantyEndDate);
  const [receiptDate, setReceiptDate] = useState(props.order.receiptDate);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFilenames] = useState([]);

  const inputRef = useRef({
    receiptNo: null,
    hn: null,
    amount: null,
    salePackage: null,
    mask: null,
    maskSize: null,
    receiptDate: null,
    warrantyPeriod: null,
    warrantyStartDate: null,
    warrantyEndDate: null,
    refHospital: null,
    refPhysician: null,
    note: null,
    status: null,
    activity: null,
    note: null,
  });

  // console.log(props.order);

  const calculateEndDate = (period) => {
    const now = receiptDate
      ? new Date(receiptDate)
      : props.order.receiptDate.toDate();
    // console.log("now", now);
    // console.log("receiptDate", receiptDate);
    let calculatedDate;

    switch (period) {
      case "6M":
        calculatedDate = new Date(new Date(now).setMonth(now.getMonth() + 6));
        // calculatedDate = new Date(now.setMonth(now.getMonth() + 6));
        break;
      case "1Y":
        calculatedDate = new Date(
          new Date(now).setFullYear(now.getFullYear() + 1)
        );
        // calculatedDate = new Date(now.setFullYear(now.getFullYear() + 1));
        break;
      case "2Y":
        calculatedDate = new Date(
          new Date(now).setFullYear(now.getFullYear() + 2)
        );
        // calculatedDate = new Date(now.setFullYear(now.getFullYear() + 2));
        break;
      default:
        calculatedDate = null;
    }

    setStartDate(now); // Reset to now
    setEndDate(calculatedDate);

    // console.log("start", now);
    // console.log("end", calculatedDate);
  };

  const handleChange = (name) => (e) => {
    if (name === "nationalImage") {
      for (let i = 0; i < e.target.files.length; i++) {
        const f = e.target.files[i];
        setSelectedFiles((prevState) => [...prevState, f]);
        setFilenames((prevState) => [...prevState, f.name]);
      }
    } else if (name === "warrantyPeriod") {
      calculateEndDate(e.target.value);
      inputRef.current[name] = e.target.value;
    } else if (name === "receiptDate") {
      setReceiptDate(e.target.value);
      inputRef.current[name] = e.target.value;
    } else {
      inputRef.current[name] = e.target.value;
    }
  };
  const handleOnHideModal = () => {
    props.hide();
  };

  const handleOnViewFile = (file) => {
    setIsLoading(true);
    getSignedUrl({
      folder: "files",
      hn: props.order.hn,
      id: props.order.noteid,
      filename: file,
    })
      .then((result) => {
        console.log("url", result.data);
        setFileUrl(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
    setCreateModal(true);
  };

  const handleHideView = () => {
    setCreateModal(false);
    setFileUrl(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ref = doc(db, "patients", props.order.hn);
    const docSnap = await getDoc(ref);

    const formData = {
      receiptNo: inputRef.current.receiptNo
        ? inputRef.current.receiptNo
        : props.order.receiptNo,
      hn: inputRef.current.hn ? inputRef.current.hn : props.order.hn,
      amount: inputRef.current.amount
        ? inputRef.current.amount
        : props.order.amount,
      salePackage: inputRef.current.salePackage
        ? inputRef.current.salePackage
        : props.order.salePackage
        ? props.order.salePackage
        : "",
      mask: inputRef.current.mask ? inputRef.current.mask : props.order.mask,
      maskSize: inputRef.current.maskSize
        ? inputRef.current.maskSize
        : props.order.maskSize,
      refHospital:
        docSnap.data().refHn === undefined ? "" : docSnap.data().refHn,
      refPhysician: inputRef.current.refPhysician
        ? inputRef.current.refPhysician
        : props.order.refPhysician,
      status: inputRef.current.status
        ? inputRef.current.status
        : props.order.status,
      receiptDate: inputRef.current.receiptDate
        ? new Date(inputRef.current.receiptDate)
        : props.order.receiptDate,
      warrantyPeriod: inputRef.current.warrantyPeriod
        ? inputRef.current.warrantyPeriod
        : props.order.warrantyPeriod,
      warrantyStartDate: endDate
        ? startDate
        : new Date(inputRef.current.warrantyStartDate),
      warrantyEndDate: endDate
        ? endDate
        : new Date(inputRef.current.warrantyEndDate),
      // warrantyStartDate: inputRef.current.warrantyStartDate
      //   ? new Date(inputRef.current.warrantyStartDate)
      //   : props.order.warrantyStartDate,
      // warrantyEndDate: inputRef.current.warrantyEndDate
      //   ? new Date(inputRef.current.warrantyEndDate)
      //   : props.order.warrantyEndDate,
      activity: inputRef.current.activity
        ? inputRef.current.activity
        : props.order.activity,
      sales: inputRef.current.sales
        ? inputRef.current.sales
        : props.order.sales
        ? props.order.sales
        : "",
      note: inputRef.current.note ? inputRef.current.note : props.order.note,
      productId: props.order.productId,
      updatedAt: new Date(),

      firstname: docSnap.data().firstnameTh,
      lastname: docSnap.data().lastnameTh,
      nodeRef:
        docSnap.data().nodeRef === undefined ? "" : docSnap.data().nodeRef,
      refCustomer:
        docSnap.data().refCustomer === undefined
          ? ""
          : docSnap.data().refCustomer,
      mobile: docSnap.data().mobile,
      address: docSnap.data().address1,
    };
    // console.log(formData);

    const notes = {
      hn: inputRef.current.hn ? inputRef.current.hn : props.order.hn,
      filename: fileNames.length !== 0 ? [] : props.order.filename,
      updateAt: new Date(),
    };

    props.onEditSubmit(
      props.order.id,
      formData,
      props.order.noteid,
      selectedFiles,
      notes
    );

    // console.log("notes", notes);

    setFilenames([]);
    setSelectedFiles([]);
    props.hide();
  };

  return (
    <Fragment>
      {!isLoding && (
        <Modal
          className="fade bd-example-modal-lg"
          show={createModal}
          onHide={handleHideView}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-lg-12"
              style={{ height: "85vh" }}
            >
              <iframe
                className="col-lg-12 m-0 p-0"
                src={fileUrl + "#toolbar=0"}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
      <Modal
        className="fade bd-example-modal-lg"
        show={props.show}
        onHide={handleOnHideModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Edit Order Datails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderEditForm
            onChange={handleChange}
            initailValues={props.order}
            fileNames={fileNames}
            defaultfiles={props.order.filename}
            onViewFile={handleOnViewFile}
            warrantyStart={startDate}
            warrantyEnd={endDate}
            receiptDate={receiptDate}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
        <div className="d-flex justify-content-center mb-3">
          <Button className="sm" onClick={handleSubmit}>
            Save
          </Button>

          <Button
            className="sm ml-2"
            variant="light"
            onClick={handleOnHideModal}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DetailModal;
