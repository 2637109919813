import React, { Fragment, useState, useRef, useEffect } from "react";
import _ from "lodash";
import RegisterFields from "./RegisterFields";
import formFields from "./formFields";

export const RegisterForm = (props) => {
  // const inputRef = useRef({});
  const inputRef = useRef({});

  // console.log(props.startDate);
  // console.log(props.endDate);

  const renderFields = () => {
    return _.map(
      formFields,
      ({ label, name, type, options, required, description }) => {
        return (
          <RegisterFields
            name={name}
            label={label}
            type={type}
            value={
              name === "deviceNo"
                ? props.deviceNo
                : name === "incomingDate"
                ? new Date(props.incomingDate).toLocaleDateString("fr-CA", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : inputRef.current.name
            }
            options={options}
            required={required}
            onChange={props.onChange(name)}
            description={description}
            fileNames={props.fileNames}
            startDate={props.startDate}
            endDate={props.endDate}
          />
        );
      }
    );
  };

  useEffect(() => {
    inputRef.current = "";
  }, []);

  return <Fragment>{renderFields()}</Fragment>;
};
