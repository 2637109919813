import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { Button, Row, Card, Col, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
// import { useAuth, currentUser } from "../../../context/AuthContext";
import { db } from "../../../firebase";
import {
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
  where,
  orderBy,
  query,
  limit,
  or,
  writeBatch,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "./PatientUtils";
// import { PatientDropDownForm } from "../Booking/BookingUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../../context/AuthContext";
import useQueryParams from "../../../hook/useQueryParam";

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .required("Please provide a password"),
});

const profileData = {
  hn: "",
  email: "",
  titleTh: "",
  firstnameTh: "",
  lastnameTh: "",
  nationalId: "",
  titleEn: "",
  firstnameEn: "",
  lastnameEn: "",
  dateOfBirth: "",
  status: "",
  ethnicity: "",
  nationality: "",
  religion: "",
  bloodType: "",
  note: "",
  gender: "",
  occupation: "",
  mobile: "",
  telephone: "",
  address1: "",
  address2: "",
  province: "",
  postcode: "",
  medicineIntolerance: "",
  healthInsurance: "",
  other: "",
  emergencyContact: "",
  relationship: "",
  emergencyContactPhone: "",
  refHn: "",
  refCustomer: "",
};

const PatientDetail = (profile) => {
  // console.log("start", profile);
  const [showPassword, setShowPassword] = useState(false);
  const profileRef = useRef(profileData);
  const emailRef = useRef();
  const passwordRef = useRef();
  const { id } = useParams();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [isEditProfile, setEditProfile] = useState(false);
  const [activity, setActivity] = useState([]);
  const [order, setOrder] = useState([]);
  const [nodeList, setNodeList] = useState([]);
  const [nodeIdDocs, setNodeIdDocs] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerAllList, setCustomerAllList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formInfo, setFormInfo] = useState({});

  const { queryParams, setQueryParams } = useQueryParams({
    lastorder: null,
  });

  let text = {
    order: null,
    createDate: null,
    title: null,
    activity: null,
    infoDate1: null,
    infoDate2: null,
    info1: null,
    info2: null,
  };
  const { currentUser } = useAuth();
  //   const { signup, currentUser } = useAuth();

  const marriedStatus = ["โสด", "สมรส", "หย่า", "แยกกันอยู่", "หม้าย", "สมณะ"];
  const genders = ["ชาย", "หญิง"];
  const paymentPrivilages = [
    "ชำระเงินเอง",
    "ประกันสุขภาพถ้วนหน้า",
    "ประกันสังคม",
    "กรมบัญชีกลาง",
    "กรุงเทพมหานคร",
    "ต้นสังกัด",
    "นักศึกษา มธ.",
    "โครงการวิจัยรับค่าบริการล่วงหน้า",
    "องค์การปกครองส่วนท้องถิ่น",
    "โครงการตรวจสุขภาพรับค่าบริการล่วงหน้า",
    "ผ่อนชำระ/นิติกร",
    "อุบัติเหตุและฉุกเฉินนอกโรงพยาบาล(EMS)",
    "ปลัดกระทรวงสาธารณะสุข",
    "โครงการ 85 ปี",
    "อื่นๆ",
  ];

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      const ref = doc(db, "patients", patientInfo.hn);
      const docSnap = await getDoc(ref);
      const updateData = {
        email: profileRef.current["email"].value,
        titleTh: profileRef.current["titleTh"].value,
        firstnameTh: profileRef.current["firstnameTh"].value,
        lastnameTh: profileRef.current["lastnameTh"].value,
        nationalId: profileRef.current["nationalId"].value,
        titleEn: profileRef.current["titleEn"].value,
        firstnameEn: profileRef.current["firstnameEn"].value,
        lastnameEn: profileRef.current["lastnameEn"].value,
        dateOfBirth: profileRef.current["dateOfBirth"].value,
        status: profileRef.current["status"].value,
        ethnicity: profileRef.current["ethnicity"].value,
        nationality: profileRef.current["nationality"].value,
        religion: profileRef.current["religion"].value,
        bloodType: profileRef.current["bloodType"].value,
        note: profileRef.current["note"].value,
        gender: profileRef.current["gender"].value,
        occupation: profileRef.current["occupation"].value,
        mobile: profileRef.current["mobile"].value,
        telephone: profileRef.current["telephone"].value,
        address1: profileRef.current["address1"].value,
        address2: profileRef.current["address2"].value,
        province: profileRef.current["province"].value,
        postcode: profileRef.current["postcode"].value,
        medicineIntolerance: profileRef.current["medicineIntolerance"].value,
        healthInsurance: profileRef.current["healthInsurance"].value,
        other: profileRef.current["other"].value,
        emergencyContact: profileRef.current["emergencyContact"].value,
        relationship: profileRef.current["relationship"].value,
        emergencyContactPhone:
          profileRef.current["emergencyContactPhone"].value,
        refHn: profileRef.current["refHn"].value,
        nodeRef: profileRef.current["nodeRef"].value,
        refCustomer: profileRef.current["refCustomer"].value,
      };
      if (docSnap.exists()) {
        await updateDoc(ref, updateData)
          .then(() => {
            notifyTopRight("Update success ");
          })
          .catch((error) => {
            notifyError(`Error ${error}`);
          });

        const collectionRef = collection(db, "orders");
        const q = query(collectionRef, where("hn", "==", patientInfo.hn));
        const snap = await getDocs(q);
        const totalDocs = snap.size;
        console.log(`Total documents to update: ${totalDocs}`);
        const batch = writeBatch(db);
        snap.forEach((docSnapshot) => {
          const docRef = doc(db, "orders", docSnapshot.id);
          batch.update(docRef, {
            firstname: profileRef.current["firstnameTh"].value,
            lastname: profileRef.current["lastnameTh"].value,
            refCustomer: profileRef.current["refCustomer"].value,
            refHospital: profileRef.current["refHn"].value,
          });
        });
        await batch.commit();
      } else {
        console.log("No Ducument");
      }
    } catch (err) {
      console.log(err);
      notifyError(`Error ${err}`);
    }
  };

  const CustomerList = (nodename) => {
    const index = nodeList.indexOf(nodename);
    return customerAllList[index];
  };

  useEffect(async () => {
    if (patientInfo.hn === "") {
      const profileObj = profile.profile;
      setPatientInfo(profileObj);

      //last activity
      const queryRef = query(
        collection(db, "activities"),
        where("hn", "==", profile.profile.hn),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const tempActivity = [];
      const docSnap = await getDocs(queryRef);
      docSnap.forEach((doc) => {
        text = {
          actId: doc.id,
          id: doc.data().id,
          order: doc.data().order,
          createDate: doc.data().createdAt,
          title: doc.data().title,
          activity: doc.data().activity,
          infoDate1: doc.data().startDate,
          infoDate2: doc.data().dueDate,
          info1: doc.data().detail1,
          info2: doc.data().detail2,
        };
        if (doc.data().activity == "คืนเครื่อง") {
          text = {
            ...text,
            infoDate1: doc.data().endDate,
          };
        }

        tempActivity.push({ ...text });
      });

      //order
      try {
        const orderRef = query(
          collection(db, "patients", profile.profile.hn, "orders"),
          orderBy("createdAt", "desc"),
          limit(1)
        );
        await getDocs(orderRef).then((snapshot) => {
          const order = [];
          snapshot.forEach((doc) => {
            order.push(doc.data());
          });
          // console.log(order);
          setOrder(order);
        });
      } catch (err) {
        console.log("Cannot find last orders");
      }

      const [nodesDocs] = await Promise.all([getDocs(collection(db, "nodes"))]);

      let allCustomers = [];
      let allnodeIdDocs = [];
      let allItems = [];
      let allNodes;

      if (
        currentUser.roles[0] == "super_admin" ||
        currentUser.roles[0] == "sale"
      ) {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        for (const nodeDoc of nodesDocs.docs) {
          allnodeIdDocs = [...allnodeIdDocs, nodeDoc.id];
          const customerDocs = await getDocs(
            collection(db, "nodes", nodeDoc.id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
      } else {
        const currentAdmin = await getDoc(
          doc(db, "admins", currentUser.displayName)
        );
        let nodeCode = currentAdmin.data().node;
        let node = nodesDocs.docs.filter((doc) => {
          return doc.data().nodecode == nodeCode;
        });
        console.log("node: ", node);
        allnodeIdDocs = node.map((doc) => doc.id);
        allNodes = node.map((doc) => doc.data().nodename);

        for (const id of allnodeIdDocs) {
          const customerDocs = await getDocs(
            collection(db, "nodes", id, "customers")
          );
          const customersData = customerDocs.docs.map((customer) => {
            const customerData = customer.id;
            return customerData;
          });
          allCustomers.push(customersData);
        }
      }
      setCustomerAllList(allCustomers);
      setCustomerList(allCustomers[0]);
      setNodeList(allNodes);
      setNodeIdDocs(allnodeIdDocs);

      //last sleep test scheduled activity
      const ref = query(
        collection(db, "activities"),
        where("hn", "==", profile.profile.hn),
        where("activity", "==", "Sleep Test Scheduled"),
        orderBy("createdAt", "desc"),
        limit(1)
      );

      const temp = [];
      const snap = await getDocs(ref);

      snap.forEach((doc) => {
        text = {
          actId: doc.id,
          id: doc.data().id,
          order: doc.data().order,
          createDate: doc.data().createdAt,
          title: doc.data().title,
          activity: doc.data().activity,
          infoDate1: doc.data().startDate,
          infoDate2: doc.data().dueDate,
          info1: doc.data().detail1,
          info2: doc.data().detail2,
        };
        temp.push({ ...text });
      });

      setQueryParams({
        ...queryParams,
        lastorder: temp[0] ? temp[0].order : "",
      });

      // setCustomerList(profileObj.nodeRef);

      //form ref
      const form = [];
      const formRef = query(
        collection(db, "patients", profile.profile.hn, "form"),
        where("formname", "==", "ข้อมูลพื้นฐาน"),
        orderBy("createAt", "desc"),
        limit(1)
      );
      const formSnap = await getDocs(formRef);
      formSnap.forEach((doc) => {
        form.push({ ...doc.data().info });
      });

      console.log("form", form);

      setFormInfo(form[0]);
      setActivity(tempActivity);
      setIsLoading(false);
    }
  }, [patientInfo]);
  return (
    <Fragment>
      {!isLoading ? (
        <>
          <div className="form-head d-flex mb-3 mb-lg-5 align-items-start">
            {!["staff", "sleep_technician"].includes(currentUser.roles[0]) && (
              <Button
                onClick={() => {
                  setEditProfile(!isEditProfile);
                  setCustomerList(CustomerList(patientInfo.nodeRef));
                }}
                className={isEditProfile ? "btn btn-blue" : "btn btn-dark"}
              >
                <i className="flaticon-381-clock mr-2"></i>Edit Profile
              </Button>
            )}
            {/* <Link to={"#"} className="btn btn-success ml-auto px-5">
          + Add Netw Appointment
        </Link> */}
          </div>
          {isEditProfile ? (
            <>
              <Toast />
              <div className="row">
                <div className="col-lg-12">
                  {/* <div className="card-header">
            <h4 className="card-title">Patient Info</h4>
          </div> */}
                  <div className="card-body">
                    <div className="form-validation">
                      <form
                        className="form-valide"
                        action="#"
                        method="post"
                        onSubmit={submitHandler}
                      >
                        <div className="row">
                          <div className="col-xl-6">
                            {/* <b>Email</b>
                        <div className="form-group row">
                          <div className="col-lg-4 col-form-label">
                            <label>อีเมล์</label>
                          </div>
                          <div className="col-lg-8">
                            <label className="mt-2">{patientInfo.email}</label>
                          </div>
                        </div> */}
                            <PatientTextInputNotRequiredForm
                              title="ชื่อ"
                              id="first-name-th"
                              name="first-name-th"
                              refvalue={(el) =>
                                (profileRef.current["firstnameTh"] = el)
                              }
                              defaultValue={patientInfo.firstnameTh}
                            />
                            <PatientTextInputNotRequiredForm
                              title="นามสกุล"
                              id="last-name-th"
                              name="last-name-th"
                              refvalue={(el) =>
                                (profileRef.current["lastnameTh"] = el)
                              }
                              defaultValue={patientInfo.lastnameTh}
                            />

                            <div className="form-group row">
                              <div className="col-lg-4 col-form-label">
                                <label style={{ color: "#424242" }}>HN</label>
                              </div>
                              <div className="col-lg-8">
                                <label
                                  className="mt-2"
                                  style={{ color: "#424242" }}
                                >
                                  {patientInfo.hn}
                                </label>
                              </div>
                            </div>
                            <PatientTextInputNotRequiredForm
                              title="รหัสโรงพยาบาลอ้างอิง"
                              id="referent-hn"
                              name="referent-hn"
                              refvalue={(el) =>
                                (profileRef.current["refHn"] = el)
                              }
                              defaultValue={patientInfo.refHn}
                              description="*ไม่ต้องใส่ HN ใส่เฉพาะตัวเลข"
                            />
                            <PatientDropDownForm
                              title="Node"
                              htmlFor="reimburse-right"
                              id="reimburse-right"
                              name="reimburse-right"
                              placeholder="กดเพื่อเลือก"
                              options={nodeList}
                              refvalue={(el) =>
                                (profileRef.current["nodeRef"] = el)
                              }
                              onChange={(e) => {
                                setCustomerList(CustomerList(e.target.value));
                              }}
                              selected={patientInfo.nodeRef}
                              defaultValue={patientInfo.nodeRef}
                            />
                            <PatientDropDownForm
                              title="Customer"
                              id="customer"
                              placeholder="กดเพื่อเลือก"
                              refvalue={(el) =>
                                (profileRef.current["refCustomer"] = el)
                              }
                              defaultValue={patientInfo.refCustomer}
                              selected={patientInfo.refCustomer}
                              options={customerList}
                            ></PatientDropDownForm>
                            <PatientTextInputNotRequiredForm
                              title="คำนำหน้า"
                              id="prename-th"
                              name="prename-th"
                              refvalue={(el) =>
                                (profileRef.current["titleTh"] = el)
                              }
                              defaultValue={patientInfo.titleTh}
                            />

                            <PatientTextInputNotRequiredForm
                              title="เลขประจำตัวประชาชน 13 หลัก"
                              id="national-id"
                              name="national-id"
                              refvalue={(el) =>
                                (profileRef.current["nationalId"] = el)
                              }
                              defaultValue={patientInfo.nationalId}
                            />
                            <PatientTextInputNotRequiredForm
                              title="วันเดือนปีเกิด"
                              id="date-of-birth"
                              name="date-of-birth"
                              type="date"
                              refvalue={(el) =>
                                (profileRef.current["dateOfBirth"] = el)
                              }
                              defaultValue={patientInfo.dateOfBirth}
                            />
                            <PatientDropDownForm
                              title="เพศ"
                              htmlFor="gender"
                              id="gender"
                              name="gender"
                              placeholder="กดเพื่อเลือก"
                              options={genders}
                              refvalue={(el) =>
                                (profileRef.current["gender"] = el)
                              }
                              defaultValue={patientInfo.gender}
                            />
                            <PatientTextInputNotRequiredForm
                              title="เบอร์โทรศัพท์"
                              id="mobile"
                              name="mobile"
                              refvalue={(el) =>
                                (profileRef.current["mobile"] = el)
                              }
                              defaultValue={patientInfo.mobile}
                            />
                            <PatientTextInputNotRequiredForm
                              title="เบอร์โทรศัพท์บ้าน"
                              id="telephone"
                              name="telephone"
                              refvalue={(el) =>
                                (profileRef.current["telephone"] = el)
                              }
                              defaultValue={patientInfo.telephone}
                            />
                            <PatientTextInputNotRequiredForm
                              title="ประวัติการแพ้ยา"
                              id="medicine-allergic"
                              name="medicine-allergic"
                              refvalue={(el) =>
                                (profileRef.current["medicineIntolerance"] = el)
                              }
                              defaultValue={patientInfo.medicineIntolerance}
                            />

                            <PatientDropDownForm
                              title="สิทธิการเบิก"
                              htmlFor="reimburse-right"
                              id="reimburse-right"
                              name="reimburse-right"
                              placeholder="กดเพื่อเลือก"
                              options={paymentPrivilages}
                              refvalue={(el) =>
                                (profileRef.current["healthInsurance"] = el)
                              }
                              defaultValue={patientInfo.healthInsurance}
                            />
                            <PatientTextInputNotRequiredForm
                              title="สิทธิ์อื่นๆ โปรดระบุ"
                              id="other-reimburse-right"
                              name="other-reimburse-right"
                              refvalue={(el) =>
                                (profileRef.current["other"] = el)
                              }
                              defaultValue={patientInfo.other}
                            />
                            <div className="form-group row">
                              <label
                                className="col-lg-4 col-form-label"
                                style={{ color: "#424242" }}
                                htmlFor="val-suggestions"
                              >
                                ข้อความ<span className="text-danger"></span>
                              </label>
                              <div className="col-lg-6">
                                <textarea
                                  className="form-control"
                                  style={{ color: "#424242" }}
                                  id="val-suggestions"
                                  name="val-suggestions"
                                  rows="5"
                                  placeholder="เรื่องอื่นๆ เกี่ยวกับตนเอง"
                                  ref={(el) =>
                                    (profileRef.current["note"] = el)
                                  }
                                  defaultValue={patientInfo.note}
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-6">
                            <PatientTextInputNotRequiredForm
                              title="Email"
                              id="email"
                              name="email"
                              refvalue={(el) =>
                                (profileRef.current["email"] = el)
                              }
                              defaultValue={patientInfo.email}
                            />

                            {/* <b>Password</b>
                            <div className="form-group row">
                              <div className="col-lg-4 col-form-label">
                                <label>รหัสผ่าน</label>
                              </div>
                              <div className="col-lg-8">
                              </div>
                            </div>

                            <br />
                            <br /> */}
                            {console.log("gender:", patientInfo.gender)}
                            <PatientTextInputNotRequiredForm
                              title="คำนำหน้า (อังกฤษ)"
                              id="prename-en"
                              name="prename-en"
                              refvalue={(el) =>
                                (profileRef.current["titleEn"] = el)
                              }
                              defaultValue={patientInfo.titleEn}
                            />
                            <PatientTextInputNotRequiredForm
                              title="ชื่อ (อังกฤษ)"
                              id="first-name-en"
                              name="first-name-en"
                              refvalue={(el) =>
                                (profileRef.current["firstnameEn"] = el)
                              }
                              defaultValue={patientInfo.firstnameEn}
                            />
                            <PatientTextInputNotRequiredForm
                              title="นามสกุล (อังกฤษ)"
                              id="last-name-en"
                              name="last-name-en"
                              refvalue={(el) =>
                                (profileRef.current["lastnameEn"] = el)
                              }
                              defaultValue={patientInfo.lastnameEn}
                            />

                            <PatientDropDownForm
                              title="สถานภาพ"
                              htmlFor="married-status"
                              id="married-status"
                              name="married-status"
                              placeholder="กดเพื่อเลือก"
                              options={marriedStatus}
                              refvalue={(el) =>
                                (profileRef.current["status"] = el)
                              }
                              defaultValue={patientInfo.status}
                            />

                            <PatientTextInputNotRequiredForm
                              title="เชื้อชาติ"
                              id="ethnicity"
                              name="ethnicity"
                              refvalue={(el) =>
                                (profileRef.current["ethnicity"] = el)
                              }
                              defaultValue={patientInfo.ethnicity}
                            />
                            <PatientTextInputNotRequiredForm
                              title="สัญชาติ"
                              id="nationality"
                              name="nationality"
                              refvalue={(el) =>
                                (profileRef.current["nationality"] = el)
                              }
                              defaultValue={patientInfo.nationality}
                            />
                            <PatientTextInputNotRequiredForm
                              title="ศาสนา"
                              id="religion"
                              name="religion"
                              refvalue={(el) =>
                                (profileRef.current["religion"] = el)
                              }
                              defaultValue={patientInfo.religion}
                            />
                            <PatientTextInputNotRequiredForm
                              title="หมู่เลือด"
                              id="blood-type"
                              name="blood-type"
                              refvalue={(el) =>
                                (profileRef.current["bloodType"] = el)
                              }
                              defaultValue={patientInfo.bloodType}
                            />

                            <PatientTextInputNotRequiredForm
                              title="อาชีพ"
                              id="occupation"
                              name="occupation"
                              refvalue={(el) =>
                                (profileRef.current["occupation"] = el)
                              }
                              defaultValue={patientInfo.occupation}
                            />
                            <PatientTextInputNotRequiredForm
                              title="ที่อยู่"
                              id="address1"
                              name="address1"
                              refvalue={(el) =>
                                (profileRef.current["address1"] = el)
                              }
                              defaultValue={patientInfo.address1}
                            />
                            <PatientTextInputNotRequiredForm
                              title="ที่อยู่ (ต่อ)"
                              id="address2"
                              name="address2"
                              refvalue={(el) =>
                                (profileRef.current["address2"] = el)
                              }
                              defaultValue={patientInfo.address2}
                            />

                            <PatientTextInputNotRequiredForm
                              title="จังหวัด"
                              id="province"
                              name="province"
                              refvalue={(el) =>
                                (profileRef.current["province"] = el)
                              }
                              defaultValue={patientInfo.province}
                            />
                            <PatientTextInputNotRequiredForm
                              title="รหัสไปรษณีย์"
                              id="postal-code"
                              name="postal-code"
                              refvalue={(el) =>
                                (profileRef.current["postcode"] = el)
                              }
                              defaultValue={patientInfo.postcode}
                            />

                            <PatientTextInputNotRequiredForm
                              title="ชื่อผู้ติดต่ออื่น"
                              id="other-contact"
                              name="other-contact"
                              refvalue={(el) =>
                                (profileRef.current["emergencyContact"] = el)
                              }
                              defaultValue={patientInfo.emergencyContact}
                            />

                            <PatientTextInputNotRequiredForm
                              title="ความสัมพันธ์"
                              id="other-relation"
                              name="other-relation"
                              refvalue={(el) =>
                                (profileRef.current["relationship"] = el)
                              }
                              defaultValue={patientInfo.relationship}
                            />
                            <PatientTextInputNotRequiredForm
                              title="เบอร์ติดต่อผู้ติดต่ออื่น"
                              id="other-phone"
                              name="other-phone"
                              refvalue={(el) =>
                                (profileRef.current["emergencyContactPhone"] =
                                  el)
                              }
                              defaultValue={patientInfo.emergencyContactPhone}
                            />
                            <br />

                            <div className="form-group row">
                              <div className="col-lg-8 ml-auto">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Toast />
                <div className="col-xl-6 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Information</h4>
                    </div>
                    <div className="card-body">
                      <div className="basic-form">
                        <div className="form-row">
                          <div className="form-group col-md-7">
                            <label style={{ color: "#424242" }}>
                              รหัสโรงพยาบาลอ้างอิง
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={patientInfo.refHn}
                              disabled="true"
                            />
                          </div>
                          <div className="form-group col-md-5">
                            <label style={{ color: "#424242" }}>
                              เบอร์โทรศัพท์
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={patientInfo.mobile}
                              disabled="true"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label style={{ color: "#424242" }}>ชื่อ</label>

                            <input
                              type="text"
                              className="form-control"
                              defaultValue={patientInfo.firstnameTh}
                              disabled="true"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label style={{ color: "#424242" }}>นามสกุล</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={patientInfo.lastnameTh}
                              disabled="true"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label style={{ color: "#424242" }}>เพศ</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={patientInfo.gender}
                              disabled="true"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label style={{ color: "#424242" }}>
                              วันเดือนปีเกิด
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              defaultValue={patientInfo.dateOfBirth}
                              disabled="true"
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label style={{ color: "#424242" }}>Note</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={patientInfo.note}
                              disabled="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Col xl="6">
                  <Row>
                    <Col>
                      <Card>
                        <Card.Header>
                          <Card.Title>แบบฟอร์มข้อมูลพื้นฐาน</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            {formInfo ? (
                              <Card.Text>
                                {/* <strong className="text-info">
                                  แบบฟอร์มข้อมูลพื้นฐาน
                                </strong> */}

                                <p className="mb-0">
                                  เลขประจำตัวประชาชน :
                                  {formInfo.nationalId
                                    ? " " + formInfo.nationalId
                                    : " -"}
                                </p>
                                <p className="mb-0">
                                  ที่อยู่ :
                                  {formInfo.address
                                    ? " " + formInfo.address
                                    : " -"}
                                </p>
                                <p className="mb-0">
                                  อีเมล์ :
                                  {formInfo.email ? " " + formInfo.email : " -"}
                                </p>
                              </Card.Text>
                            ) : (
                              ""
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {activity.map((doc, index) => {
                        const createDate = new Date(
                          doc.createDate.seconds * 1000 +
                            doc.createDate.nanoseconds / 1000000
                        );
                        const infoDate1 = doc.infoDate1
                          ? new Date(
                              doc.infoDate1.seconds * 1000 +
                                doc.infoDate1.nanoseconds / 1000000
                            )
                          : null;

                        return (
                          <Card key={index}>
                            <Card.Header>
                              <Card.Title>Last Activity</Card.Title>
                            </Card.Header>
                            <Card.Body>
                              <Card.Text className="mb-5">
                                <strong className="text-info">
                                  {doc.activity}
                                </strong>
                                {"  "}
                                <strong className="text-info">
                                  {infoDate1
                                    ? infoDate1.toLocaleDateString("th-TH", {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      })
                                    : ""}
                                </strong>
                                {doc.title !== "borrowings" ? (
                                  <p className="mb-0">
                                    {doc.info1 ? "Node : " + doc.info1 : ""}
                                  </p>
                                ) : (
                                  <p className="mb-0">
                                    Serial No.{" "}
                                    {doc.info1 ? (
                                      <Link to={"/cpap-page/" + doc.info1}>
                                        {doc.info1}
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                )}
                                <p className="mb-0">
                                  {doc.info2 ? "Type : " + doc.info2 : ""}
                                </p>
                                <p className="mb-0">
                                  {doc.order ? "Order : " + doc.order : ""}
                                </p>
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer className="d-sm-flex justify-content-between align-items-center">
                              <Card.Text className="text-dark d-inline">
                                {createDate.toLocaleString("en-GB", {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </Card.Text>
                            </Card.Footer>
                          </Card>
                        );
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xl="6">
                  {order.map((doc, index) => (
                    <Card>
                      <Card.Header>
                        <Card.Title>Last Order</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <strong className="text-info">
                            {doc.activity.charAt(0).toUpperCase() +
                              doc.activity.slice(1)}
                          </strong>
                          {"  "}
                          {/* <strong className="text-info">
                      {doc.receiptNo ? doc.receiptNo : ""}
                    </strong> */}
                          <p className="mb-0">
                            {doc.productSn
                              ? "Receipt No. : " + doc.receiptNo
                              : ""}
                          </p>

                          <div className="mb-0">
                            {/* {doc.productSn ? "Serial Number: " + doc.productSn : ""} */}

                            {"Serial Number : "}
                            {doc.productSn ? (
                              <Link to={"/product-page/" + doc.productSn}>
                                {doc.productSn}
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                          <p className="mb-0">
                            {doc.status ? "Status : " + doc.status : ""}
                          </p>
                          <p className="mb-0">
                            {doc.receiptDate
                              ? "Receipt Date : " +
                                `${doc.receiptDate
                                  .toDate()
                                  .toLocaleString("th-TH", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}`
                              : ""}
                          </p>
                        </Card.Text>
                      </Card.Body>
                      <Card.Footer className=" d-sm-flex justify-content-between align-items-center">
                        <Card.Text className=" text-dark d-inline"></Card.Text>
                        {doc.createdAt.toDate().toLocaleString("en-GB", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </Card.Footer>
                    </Card>
                  ))}
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>Loading ...</>
      )}
    </Fragment>
  );
};

export default PatientDetail;
