import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";
import { db } from "../../../../firebase";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  PatientTextInputForm,
  PatientTextInputNotRequiredForm,
  PatientDropDownForm,
} from "../PatientUtils";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../../components/toast/SetToast";
import Toast from "../../../components/toast/SetToast";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { connectStorageEmulator } from "firebase/storage";

const infoData = {
  //Personal Information
  firstName: "",
  lastName: "",
  gender: "",
  nationalId: "",
  address: "",
  email: "",
  dateOfBirth: "",
  age: "",
  weight: "",
  height: "",

  //sleep behavior
  bedTime: "",
  wakeupTime: "",
  totalSleepHours: "",
  fallAsleepTime: "",
  returningToSleepTime: "",
  sleepBreaks: "",
  nightAwakenings: "",
  difficultyFallingAsleepNewPlace: "",

  //SymptomAnalysis
  snoringNoise: "",
  snoringLoudness: "",
  sleepDeprivedPartners: "",
  breathingPausesDuringSleep: "",
  chokingSensationDuringSleep: "",
  bedwetting: "",
  heartPalpitationsDuringSleep: "",
  chestPainDuringSleep: "",
  teethGrindingDuringSleep: "",
  mouthBreathing: "",
  limbJerkingDuringSleep: "",
  sleepBehaviorDisorder: "",
  uncomfortableLegs: "",

  //Daytime
  wakingUpNotRefreshed: "",
  wakingUpDizzy: "",
  wakingUpHeadache: "",
  sleepyAfternoon: "",
  howLongSleepyAfternoon: "",
  afternoonNap: "",
  afternoonNapCount: "",
  sleepyDriving: "",
  carAccidentFallingAsleep: "",
  memoryLapses: "",
  moodDisorders: "",
  sexualDysfunction: "",

  //pill
  currentMedications: "",
  currentMedicationsNote: "",
  sleepingPills: "",
  sleepingPillsName: "",
  CBDOil: "",
  CBDOilNote: "",
  medicationsSubply: "",

  //
  tuberculosisSpread: "",
  obesityMoreThan35: "",
  weightGain: "",
  weightGainNote: "",
  highBloodPressure: "",
  diabetes: "",
  highFat: "",
  ischemicHeartDisease: "",
  heartAttack: "",
  arrhythmia: "",
  swollenLegs: "",
  narrowedBloodVesselsInBrain: "",
  allergicRhinitis: "",
  emphysema: "",
  asthma: "",
  thyroidDisease: "",
  claustrophobia: "",
  anxietyOrDepression: "",
  infectiousDiseases: "",
  infectiousDiseasesNote: "",
  familySleepDisorders: "",
  familySleepDisordersNote: "",

  //Narcolepsy
  nightmare: "",
  hallucinations: "",
  cataplexy: "",
  sleepParalysis: "",

  //Epworth Sleepiness Scala
  readingSleepiness: "",
  tvWatchingSleepiness: "",
  publicPlaceSleepiness: "",
  longTripPassengerSleepiness: "",
  afternoonRestSleepiness: "",
  conversationSleepiness: "",
  afterLunchSleepiness: "",
  trafficStopSleepiness: "",

  //STOP-BANG
  snoring: "",
  tiredness: "",
  observedStopBreathing: "",
  pressure: "",
  BMIHigherThan35: "",
  ageOlderThan50: "",
  neckCircumferenceGreaterThan40cm: "",
  males: "",
};
const profileData = {
  username: "",
  email: "",
  titleTh: "",
  firstnameTh: "",
  lastnameTh: "",
  titleEn: "",
  firstnameEn: "",
  lastnameEn: "",
  dateOfBirth: "",
  status: "",
  ethnicity: "",
  nationality: "",
  religion: "",
  bloodType: "",
  note: "",
  gender: "",
  occupation: "",
  mobile: "",
  telephone: "",
  address1: "",
  address2: "",
  province: "",
  postcode: "",
  medicineIntolerance: "",
  healthInsurance: "",
  other: "",
  emergencyContact: "",
  relationship: "",
  emergencyContactPhone: "",
};

const preSleepInfoData = {
  assign: false,
  formname: "แบบประเมินสำหรับตรวจการนอนหลับ รพ.ลาดพร้าว",
  id: "3",
  info: infoData,
  submit: false,
};

const PreSleepInformation = () => {
  // const profileRef = useRef(profileData);
  const preSleepInfoRef = useRef(infoData);
  const history = useHistory();
  const [patientInfo, setPatientInfo] = useState(profileData);
  const [preSleepInfoDoc, setPreSleepInfoDoc] = useState(preSleepInfoData);
  const [isLoading, setLoading] = useState(true);
  const { id, uuid } = useParams();
  const DropdownBox = ({
    title = "",
    htmlFor = "",
    id = "",
    name = "",
    refvalue,
    placeholder = "Please select",
    options = [""],
    defaultValue,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-lg-4 col-form-label" htmlFor={htmlFor}>
          {title}
          <span className="text-danger"></span>
        </label>
        <div className="col-lg-4">
          <select
            className="form-control"
            id={id}
            name={name}
            ref={refvalue}
            defaultValue={defaultValue}
          >
            {options.map((value) => (
              <option value={value}>{value}</option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  const InputBox = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    bold = false,
    required,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-form-label mt-2">
          {bold ? <b>{title}</b> : <>{title}</>}
        </label>
        <div className="col-xl-6">
          {noBox == false && (
            <input
              type="text"
              required={required}
              className="form-control"
              placeholder={placeholder == "" ? title : placeholder}
              id={id}
              ref={refValue}
              defaultValue={defaultValue}
            />
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };
  const InputArea = ({
    title = "",
    value = "",
    placeholder = "",
    id = "",
    measure = "",
    refValue,
    noBox = false,
    defaultValue,
    required,
  }) => {
    return (
      <div className="form-group row">
        <label className="col-xl-5 col-form-label mt-2">{title}</label>
        <div className="col-xl-6">
          {noBox == false && (
            <textarea
              className="form-control"
              id={id}
              required={required}
              name="val-suggestions"
              rows="4"
              placeholder={placeholder}
              ref={refValue}
              defaultValue={defaultValue}
            ></textarea>
          )}
        </div>
        <div className="ml-3 mt-3">
          <text>{measure}</text>
        </div>
      </div>
    );
  };

  const InputRadio = ({
    title = "",
    id = "",
    options = [""],
    checked = "",
    name = "",
    isnote = false,
    note = "",
    mt = "row mt-3",
    refValue,
    required,
    refNote,
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-5 col-form-label pt-0">{title}</label>
          <div className="col-xl-6">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        required={required}
                        name={name}
                        value={value}
                        // id={id}
                        onChange={(e) => {
                          preSleepInfoRef.current[refValue] = e.target.value;
                          console.log(preSleepInfoRef.current[refValue]);
                        }}
                        defaultChecked={checked == value ? "checked" : ""}
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
          {isnote && (
            <div className="form-check">
              {" "}
              <textarea
                className="form-control"
                id="val-suggestions"
                name="val-suggestions"
                rows="2"
                placeholder=""
                ref={(e) => {
                  preSleepInfoRef.current[refNote] = e;
                }}
                defaultValue={note}
              ></textarea>
            </div>
          )}
        </div>
      </fieldset>
    );
  };
  const InputCheckBox = ({
    title = "",
    id = "",
    options = [""],
    checked = [""],
    name = "",
    refValue,
    mt = "row mt-3",
  }) => {
    return (
      <fieldset className="form-group">
        <div className={mt}>
          <label className="col-xl-4 col-form-label pt-0">{title}</label>
          <div className="col-xl-2">
            {options.map((value) => {
              return (
                <>
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        value={value}
                        onChange={(e) => {
                          // preSleepInfoRef.current[refValue] = e.target.value;
                          console.log(e.target.value);
                        }}
                        defaultChecked={
                          checked.includes(value) ? "checked" : ""
                        }
                      />
                      {value}
                    </label>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </fieldset>
    );
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var info = {
      firstName: preSleepInfoRef.current["firstName"].value,
      lastName: preSleepInfoRef.current["lastName"].value,
      gender: preSleepInfoRef.current["gender"].value,
      nationalId: preSleepInfoRef.current["nationalId"].value,
      address: preSleepInfoRef.current["address"].value,
      email: preSleepInfoRef.current["email"].value,
      dateOfBirth: preSleepInfoRef.current["email"].value,
      age: preSleepInfoRef.current["age"].value,
      weight: preSleepInfoRef.current["weight"].value,
      height: preSleepInfoRef.current["height"].value,

      //sleep behavior
      bedTime: preSleepInfoRef.current["bedTime"].value,
      wakeupTime: preSleepInfoRef.current["wakeupTime"].value,
      totalSleepHours: preSleepInfoRef.current["totalSleepHours"].value,
      fallAsleepTime: preSleepInfoRef.current["fallAsleepTime"].value,
      returningToSleepTime:
        preSleepInfoRef.current["returningToSleepTime"].value,
      sleepBreaks: preSleepInfoRef.current["sleepBreaks"].value,
      nightAwakenings: preSleepInfoRef.current["nightAwakenings"].value,
      difficultyFallingAsleepNewPlace:
        preSleepInfoRef.current["difficultyFallingAsleepNewPlace"],

      //SymptomAnalysis
      snoringNoise: preSleepInfoRef.current["snoringNoise"],
      snoringLoudness: preSleepInfoRef.current["snoringLoudness"],
      sleepDeprivedPartners: preSleepInfoRef.current["sleepDeprivedPartners"],
      breathingPausesDuringSleep:
        preSleepInfoRef.current["breathingPausesDuringSleep"],
      chokingSensationDuringSleep:
        preSleepInfoRef.current["chokingSensationDuringSleep"],
      bedwetting: preSleepInfoRef.current["bedwetting"],
      heartPalpitationsDuringSleep:
        preSleepInfoRef.current["heartPalpitationsDuringSleep"],
      chestPainDuringSleep: preSleepInfoRef.current["chestPainDuringSleep"],
      teethGrindingDuringSleep:
        preSleepInfoRef.current["teethGrindingDuringSleep"],
      mouthBreathing: preSleepInfoRef.current["mouthBreathing"],
      limbJerkingDuringSleep: preSleepInfoRef.current["limbJerkingDuringSleep"],
      sleepBehaviorDisorder: preSleepInfoRef.current["sleepBehaviorDisorder"],
      uncomfortableLegs: preSleepInfoRef.current["uncomfortableLegs"],

      //Daytime
      wakingUpNotRefreshed: preSleepInfoRef.current["wakingUpNotRefreshed"],
      wakingUpDizzy: preSleepInfoRef.current["wakingUpDizzy"],
      wakingUpHeadache: preSleepInfoRef.current["wakingUpHeadache"],
      sleepyAfternoon: preSleepInfoRef.current["sleepyAfternoon"],
      howLongSleepyAfternoon:
        preSleepInfoRef.current["howLongSleepyAfternoon"].value,
      afternoonNap: preSleepInfoRef.current["afternoonNap"],
      afternoonNapCount: preSleepInfoRef.current["afternoonNapCount"].value,
      sleepyDriving: preSleepInfoRef.current["sleepyDriving"],
      carAccidentFallingAsleep:
        preSleepInfoRef.current["carAccidentFallingAsleep"],
      memoryLapses: preSleepInfoRef.current["memoryLapses"],
      moodDisorders: preSleepInfoRef.current["moodDisorders"],
      sexualDysfunction: preSleepInfoRef.current["sexualDysfunction"],

      //pill
      currentMedications: preSleepInfoRef.current["currentMedications"],
      currentMedicationsNote:
        preSleepInfoRef.current["currentMedicationsNote"].value,
      sleepingPills: preSleepInfoRef.current["sleepingPills"],
      sleepingPillsName: preSleepInfoRef.current["sleepingPillsName"].value,
      CBDOil: preSleepInfoRef.current["CBDOil"],
      CBDOilNote: preSleepInfoRef.current["CBDOilNote"].value,
      medicationsSubply: preSleepInfoRef.current["medicationsSubply"],

      //
      tuberculosisSpread: preSleepInfoRef.current["tuberculosisSpread"],
      obesityMoreThan35: preSleepInfoRef.current["obesityMoreThan35"],
      weightGain: preSleepInfoRef.current["weightGain"],
      weightGainNote: preSleepInfoRef.current["weightGainNote"].value,
      highBloodPressure: preSleepInfoRef.current["highBloodPressure"],
      diabetes: preSleepInfoRef.current["diabetes"],
      highFat: preSleepInfoRef.current["highFat"],
      ischemicHeartDisease: preSleepInfoRef.current["ischemicHeartDisease"],
      heartAttack: preSleepInfoRef.current["heartAttack"],
      arrhythmia: preSleepInfoRef.current["arrhythmia"],
      swollenLegs: preSleepInfoRef.current["swollenLegs"],
      narrowedBloodVesselsInBrain:
        preSleepInfoRef.current["narrowedBloodVesselsInBrain"],
      allergicRhinitis: preSleepInfoRef.current["allergicRhinitis"],
      emphysema: preSleepInfoRef.current["emphysema"],
      asthma: preSleepInfoRef.current["asthma"],
      thyroidDisease: preSleepInfoRef.current["thyroidDisease"],
      claustrophobia: preSleepInfoRef.current["claustrophobia"],
      anxietyOrDepression: preSleepInfoRef.current["anxietyOrDepression"],
      infectiousDiseases: preSleepInfoRef.current["infectiousDiseases"],
      infectiousDiseasesNote:
        preSleepInfoRef.current["infectiousDiseasesNote"].value,
      familySleepDisorders: preSleepInfoRef.current["familySleepDisorders"],
      familySleepDisordersNote:
        preSleepInfoRef.current["familySleepDisordersNote"].value,

      //Narcolepsy
      nightmare: preSleepInfoRef.current["nightmare"],
      hallucinations: preSleepInfoRef.current["hallucinations"],
      cataplexy: preSleepInfoRef.current["cataplexy"],
      sleepParalysis: preSleepInfoRef.current["sleepParalysis"],

      //Epworth Sleepiness Scala
      readingSleepiness: preSleepInfoRef.current["readingSleepiness"],
      tvWatchingSleepiness: preSleepInfoRef.current["tvWatchingSleepiness"],
      publicPlaceSleepiness: preSleepInfoRef.current["publicPlaceSleepiness"],
      longTripPassengerSleepiness:
        preSleepInfoRef.current["longTripPassengerSleepiness"],
      afternoonRestSleepiness:
        preSleepInfoRef.current["afternoonRestSleepiness"],
      conversationSleepiness: preSleepInfoRef.current["conversationSleepiness"],
      afterLunchSleepiness: preSleepInfoRef.current["afterLunchSleepiness"],
      trafficStopSleepiness: preSleepInfoRef.current["trafficStopSleepiness"],

      //STOP-BANG
      snoring: preSleepInfoRef.current["snoring"],
      tiredness: preSleepInfoRef.current["tiredness"],
      observedStopBreathing: preSleepInfoRef.current["observedStopBreathing"],
      pressure: preSleepInfoRef.current["pressure"],
      BMIHigherThan35: preSleepInfoRef.current["BMIHigherThan35"],
      ageOlderThan50: preSleepInfoRef.current["ageOlderThan50"],
      neckCircumferenceGreaterThan40cm:
        preSleepInfoRef.current["neckCircumferenceGreaterThan40cm"],
      males: preSleepInfoRef.current["males"],
    };

    const ref = doc(db, "patients", patientInfo.hn, "form", uuid);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      try {
        var tmpDocSnap = docSnap.data();
        tmpDocSnap.info = info;
        tmpDocSnap.submit = true;
        var dateNow = new Date();
        dateNow.setHours(dateNow.getHours() + 7);
        tmpDocSnap.updateAt = dateNow.toISOString();
        console.log(tmpDocSnap);
        await updateDoc(ref, tmpDocSnap);
        notifyTopRight("บันทึกข้อมูลสำเร็จ");
      } catch (err) {
        notifyError("บันทึกข้อมูลผิดพลาด, ", err);
      }
    }
  };
  useEffect(async () => {
    if (patientInfo.hn != undefined && patientInfo.hn != "") {
      let docColRef = doc(db, "patients", id, "form", uuid);
      let docSnapForm = await getDoc(docColRef);
      setPreSleepInfoDoc(docSnapForm.data());

      preSleepInfoRef.current["difficultyFallingAsleepNewPlace"] =
        docSnapForm.data().info.difficultyFallingAsleepNewPlace;
      preSleepInfoRef.current["snoringNoise"] =
        docSnapForm.data().info.snoringNoise;
      preSleepInfoRef.current["snoringLoudness"] =
        docSnapForm.data().info.snoringLoudness;
      preSleepInfoRef.current["sleepDeprivedPartners"] =
        docSnapForm.data().info.sleepDeprivedPartners;
      preSleepInfoRef.current["breathingPausesDuringSleep"] =
        docSnapForm.data().info.breathingPausesDuringSleep;
      preSleepInfoRef.current["chokingSensationDuringSleep"] =
        docSnapForm.data().info.chokingSensationDuringSleep;
      preSleepInfoRef.current["bedwetting"] =
        docSnapForm.data().info.bedwetting;
      preSleepInfoRef.current["heartPalpitationsDuringSleep"] =
        docSnapForm.data().info.heartPalpitationsDuringSleep;
      preSleepInfoRef.current["teethGrindingDuringSleep"] =
        docSnapForm.data().info.teethGrindingDuringSleep;
      preSleepInfoRef.current["mouthBreathing"] =
        docSnapForm.data().info.mouthBreathing;
      preSleepInfoRef.current["limbJerkingDuringSleep"] =
        docSnapForm.data().info.limbJerkingDuringSleep;
      preSleepInfoRef.current["sleepBehaviorDisorder"] =
        docSnapForm.data().info.sleepBehaviorDisorder;
      preSleepInfoRef.current["uncomfortableLegs"] =
        docSnapForm.data().info.uncomfortableLegs;
      preSleepInfoRef.current["wakingUpNotRefreshed"] =
        docSnapForm.data().info.wakingUpNotRefreshed;
      preSleepInfoRef.current["wakingUpDizzy"] =
        docSnapForm.data().info.wakingUpDizzy;
      preSleepInfoRef.current["wakingUpHeadache"] =
        docSnapForm.data().info.wakingUpHeadache;
      preSleepInfoRef.current["sleepyAfternoon"] =
        docSnapForm.data().info.sleepyAfternoon;
      preSleepInfoRef.current["afternoonNap"] =
        docSnapForm.data().info.afternoonNap;
      preSleepInfoRef.current["sleepyDriving"] =
        docSnapForm.data().info.sleepyDriving;
      preSleepInfoRef.current["carAccidentFallingAsleep"] =
        docSnapForm.data().info.carAccidentFallingAsleep;
      preSleepInfoRef.current["memoryLapses"] =
        docSnapForm.data().info.memoryLapses;
      preSleepInfoRef.current["moodDisorders"] =
        docSnapForm.data().info.moodDisorders;
      preSleepInfoRef.current["sexualDysfunction"] =
        docSnapForm.data().info.sexualDysfunction;
      preSleepInfoRef.current["currentMedications"] =
        docSnapForm.data().info.currentMedications;
      preSleepInfoRef.current["sleepingPills"] =
        docSnapForm.data().info.sleepingPills;
      preSleepInfoRef.current["CBDOil"] = docSnapForm.data().info.CBDOil;
      preSleepInfoRef.current["medicationsSubply"] =
        docSnapForm.data().info.medicationsSubply;
      preSleepInfoRef.current["tuberculosisSpread"] =
        docSnapForm.data().info.tuberculosisSpread;
      preSleepInfoRef.current["obesityMoreThan35"] =
        docSnapForm.data().info.obesityMoreThan35;
      preSleepInfoRef.current["weightGain"] =
        docSnapForm.data().info.weightGain;
      preSleepInfoRef.current["highBloodPressure"] =
        docSnapForm.data().info.highBloodPressure;
      preSleepInfoRef.current["diabetes"] = docSnapForm.data().info.diabetes;
      preSleepInfoRef.current["highFat"] = docSnapForm.data().info.highFat;
      preSleepInfoRef.current["ischemicHeartDisease"] =
        docSnapForm.data().info.ischemicHeartDisease;
      preSleepInfoRef.current["heartAttack"] =
        docSnapForm.data().info.heartAttack;
      preSleepInfoRef.current["arrhythmia"] =
        docSnapForm.data().info.arrhythmia;
      preSleepInfoRef.current["swollenLegs"] =
        docSnapForm.data().info.swollenLegs;
      preSleepInfoRef.current["narrowedBloodVesselsInBrain"] =
        docSnapForm.data().info.narrowedBloodVesselsInBrain;
      preSleepInfoRef.current["allergicRhinitis"] =
        docSnapForm.data().info.allergicRhinitis;
      preSleepInfoRef.current["emphysema"] = docSnapForm.data().info.emphysema;
      preSleepInfoRef.current["asthma"] = docSnapForm.data().info.asthma;
      preSleepInfoRef.current["thyroidDisease"] =
        docSnapForm.data().info.thyroidDisease;
      preSleepInfoRef.current["claustrophobia"] =
        docSnapForm.data().info.claustrophobia;
      preSleepInfoRef.current["anxietyOrDepression"] =
        docSnapForm.data().info.anxietyOrDepression;
      preSleepInfoRef.current["infectiousDiseases"] =
        docSnapForm.data().info.infectiousDiseases;
      preSleepInfoRef.current["familySleepDisorders"] =
        docSnapForm.data().info.familySleepDisorders;
      preSleepInfoRef.current["nightmare"] = docSnapForm.data().info.nightmare;
      preSleepInfoRef.current["hallucinations"] =
        docSnapForm.data().info.hallucinations;
      preSleepInfoRef.current["cataplexy"] = docSnapForm.data().info.cataplexy;
      preSleepInfoRef.current["sleepParalysis"] =
        docSnapForm.data().info.sleepParalysis;
      preSleepInfoRef.current["readingSleepiness"] =
        docSnapForm.data().info.readingSleepiness;
      preSleepInfoRef.current["tvWatchingSleepiness"] =
        docSnapForm.data().info.tvWatchingSleepiness;
      preSleepInfoRef.current["publicPlaceSleepiness"] =
        docSnapForm.data().info.publicPlaceSleepiness;
      preSleepInfoRef.current["longTripPassengerSleepiness"] =
        docSnapForm.data().info.longTripPassengerSleepiness;
      preSleepInfoRef.current["afternoonRestSleepiness"] =
        docSnapForm.data().info.afternoonRestSleepiness;
      preSleepInfoRef.current["conversationSleepiness"] =
        docSnapForm.data().info.conversationSleepiness;
      preSleepInfoRef.current["afterLunchSleepiness"] =
        docSnapForm.data().info.afterLunchSleepiness;
      preSleepInfoRef.current["trafficStopSleepiness"] =
        docSnapForm.data().info.trafficStopSleepiness;
      preSleepInfoRef.current["snoring"] = docSnapForm.data().info.snoring;
      preSleepInfoRef.current["tiredness"] = docSnapForm.data().info.tiredness;
      preSleepInfoRef.current["observedStopBreathing"] =
        docSnapForm.data().info.observedStopBreathing;
      preSleepInfoRef.current["pressure"] = docSnapForm.data().info.pressure;
      preSleepInfoRef.current["BMIHigherThan35"] =
        docSnapForm.data().info.BMIHigherThan35;
      preSleepInfoRef.current["ageOlderThan50"] =
        docSnapForm.data().info.ageOlderThan50;
      preSleepInfoRef.current["neckCircumferenceGreaterThan40cm"] =
        docSnapForm.data().info.neckCircumferenceGreaterThan40cm;
      preSleepInfoRef.current["males"] = docSnapForm.data().info.males;

      setLoading(false);
      console.log("preSleepInfoRef: ", preSleepInfoRef);
      return;
    }

    const ref = doc(db, "patients", id);
    const docSnap = await getDoc(ref);
    setPatientInfo(docSnap.data());
  }, [patientInfo]);
  console.log(preSleepInfoDoc);

  return (
    <Fragment>
      <Toast />
      <Col xl={12}>
        <Card>
          <Card.Header>
            <Card.Title>
              แบบฟอร์มการซักประวัติสําหรับผู้รับบริการที่ต้องการตรวจการนอนหลับ
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {!isLoading ? (
              <div className="basic-form">
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-xl-7">
                      <h4 className="mb-4">
                        <b>ข้อมูลส่วนตัว</b>
                      </h4>
                      <InputBox
                        title="ชื่อ"
                        measure=""
                        refValue={(el) =>
                          (preSleepInfoRef.current["firstName"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.firstName}
                        required={true}
                      ></InputBox>
                      <InputBox
                        title="นามสกุล"
                        refValue={(el) =>
                          (preSleepInfoRef.current["lastName"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.lastName}
                      ></InputBox>
                      <InputBox
                        title="เพศ"
                        refValue={(el) =>
                          (preSleepInfoRef.current["gender"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.gender}
                      ></InputBox>
                      <InputBox
                        title="เลขประจำตัวประชาชน"
                        refValue={(el) =>
                          (preSleepInfoRef.current["nationalId"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.nationalId}
                      ></InputBox>
                      <InputBox
                        title="ที่อยู่ตามบัตรประชาชน"
                        refValue={(el) =>
                          (preSleepInfoRef.current["address"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.address}
                      ></InputBox>
                      <InputBox
                        title="อีเมล"
                        refValue={(el) =>
                          (preSleepInfoRef.current["email"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.email}
                      ></InputBox>
                      <InputBox
                        title="วันเกิด"
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["dateOfBirth"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.dateOfBirth}
                        type="date"
                      ></InputBox>
                      <InputBox
                        title="อายุ"
                        measure="ปี"
                        refValue={(el) => (preSleepInfoRef.current["age"] = el)}
                        defaultValue={preSleepInfoDoc.info.age}
                        required={true}
                      ></InputBox>
                      <InputBox
                        title="น้ำหนัก"
                        measure="กิโลกรัม"
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["weight"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.weight}
                      ></InputBox>
                      <InputBox
                        title="ส่วนสูง"
                        measure="เซนติเมตร"
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["height"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.height}
                      ></InputBox>
                      <h4 className="mt-5 mb-4">
                        <b>ประวัติการนอน</b>
                      </h4>{" "}
                      <InputBox
                        title="เวลาเข้านอน"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["bedTime"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.bedTime}
                      ></InputBox>
                      <InputBox
                        title="เวลาตื่นนอน"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["wakeupTime"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.wakeupTime}
                      ></InputBox>
                      <InputBox
                        title="เวลานอนเฉลี่ยกี่ชั่วโมงต่อคืน"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["totalSleepHours"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.totalSleepHours}
                      ></InputBox>
                      <InputBox
                        title="ระยะเวลาที่ใช้ในการหลับเมื่อเริ่มเข้านอนกี่นาที"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["fallAsleepTime"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.fallAsleepTime}
                      ></InputBox>
                      <InputBox
                        title="ระยะเวลาที่ใช้ในกลับไปหลับอีกครั้งหลังตื่นระหว่างคืนกี่นาที"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["returningToSleepTime"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.returningToSleepTime}
                      ></InputBox>
                      <InputBox
                        title="มีตื่นกลางคืนกี่ครั้งต่อคืน"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["sleepBreaks"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.sleepBreaks}
                      ></InputBox>
                      <InputBox
                        title="ตื่นเพราะสาเหตุใด เช่นเข้าห้องน้ำ"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["nightAwakenings"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.nightAwakenings}
                      ></InputBox>
                      <InputRadio
                        title="มีนอนไม่หลับ หรือหลับยากในสถานที่แปลกใหม่หรือไม่"
                        options={["มี", "ไม่มี"]}
                        name="มีนอนไม่หลับ หรือหลับยากในสถานที่แปลกใหม่หรือไม่"
                        checked={
                          preSleepInfoDoc.info.difficultyFallingAsleepNewPlace
                        }
                        refValue={"difficultyFallingAsleepNewPlace"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>ประวัติอาการช่วงกลางคืน</b>
                      </h4>{" "}
                      <InputRadio
                        title="มีนอนกรนเสียงดัง"
                        options={["มี", "ไม่มี"]}
                        name="มีนอนกรนเสียงดัง"
                        checked={preSleepInfoDoc.info.snoringNoise}
                        refValue={"snoringNoise"}
                      ></InputRadio>
                      <InputRadio
                        title="ลักษณะของเสียงเป็นอย่างไร"
                        options={[
                          "กรนดังต่อเนื่อง",
                          "กรนเป็นๆหายๆ",
                          "กรนเฉพาะขณะนอนหลับบางท่าทาง",
                          "ไม่มี",
                        ]}
                        name="ลักษณะของเสียงเป็นอย่างไร"
                        checked={preSleepInfoDoc.info.snoringLoudness}
                        refValue={"snoringLoudness"}
                      ></InputRadio>
                      <InputRadio
                        title="มีเพื่อนร่วมห้องนอนย้ายออกจากห้องนอนเพราะเสียงกรนของคุณ"
                        options={["มี", "ไม่มี"]}
                        name="มีเพื่อนร่วมห้องนอนย้ายออกจากห้องนอนเพราะเสียงกรนของคุณ"
                        checked={preSleepInfoDoc.info.sleepDeprivedPartners}
                        refValue={"sleepDeprivedPartners"}
                      ></InputRadio>
                      <InputRadio
                        title="มีคนเห็นหยุดหายใจ"
                        options={["มี", "ไม่มี"]}
                        name="มีคนเห็นหยุดหายใจ"
                        checked={
                          preSleepInfoDoc.info.breathingPausesDuringSleep
                        }
                        refValue={"breathingPausesDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="มีสะดุ้งตื่นเพราะหายใจไม่พอ หรือสำลัก"
                        options={["มี", "ไม่มี"]}
                        name="มีสะดุ้งตื่นเพราะหายใจไม่พอ หรือสำลัก"
                        checked={
                          preSleepInfoDoc.info.chokingSensationDuringSleep
                        }
                        refValue={"chokingSensationDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="มีปัสสาวะรดที่นอน"
                        options={["มี", "ไม่มี"]}
                        name="มีปัสสาวะรดที่นอน"
                        checked={preSleepInfoDoc.info.bedwetting}
                        refValue={"bedwetting"}
                      ></InputRadio>
                      <InputRadio
                        title="มีใจสั้นขณะนอนหลับ"
                        options={["มี", "ไม่มี"]}
                        name="มีใจสั้นขณะนอนหลับ"
                        checked={
                          preSleepInfoDoc.info.heartPalpitationsDuringSleep
                        }
                        refValue={"heartPalpitationsDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="มีเจ็บหน้าอกขณะนอนหลับ"
                        options={["มี", "ไม่มี"]}
                        name="มีเจ็บหน้าอกขณะนอนหลับ"
                        checked={preSleepInfoDoc.info.chestPainDuringSleep}
                        refValue={"chestPainDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="มีนอนกัดฟัน"
                        options={["มี", "ไม่มี"]}
                        name="มีนอนกัดฟัน"
                        checked={preSleepInfoDoc.info.teethGrindingDuringSleep}
                        refValue={"teethGrindingDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="มีนอนอ้าปาก"
                        options={["มี", "ไม่มี"]}
                        name="มีนอนอ้าปาก"
                        checked={preSleepInfoDoc.info.mouthBreathing}
                        refValue={"mouthBreathing"}
                      ></InputRadio>
                      <InputRadio
                        title="มีแขน ขากระตุก"
                        options={["มี", "ไม่มี"]}
                        name="มีแขน ขากระตุก"
                        checked={preSleepInfoDoc.info.limbJerkingDuringSleep}
                        refValue={"limbJerkingDuringSleep"}
                      ></InputRadio>
                      <InputRadio
                        title="มีพฤติกรรมผิดปกติขณะหลับ เช่น ละเมอเดิน ละเมอพูด ฝันร้าย ผีอำ"
                        options={["มี", "ไม่มี"]}
                        name="มีพฤติกรรมผิดปกติขณะหลับ เช่น ละเมอเดิน ละเมอพูด ฝันร้าย ผีอำ"
                        checked={preSleepInfoDoc.info.sleepBehaviorDisorder}
                        refValue={"sleepBehaviorDisorder"}
                      ></InputRadio>
                      <InputRadio
                        title="มีรู้สึกไม่สะบายขา ต้องขยับขาจึงจะทุเลา"
                        options={["มี", "ไม่มี"]}
                        name="มีรู้สึกไม่สะบายขา ต้องขยับขาจึงจะทุเลา"
                        checked={preSleepInfoDoc.info.uncomfortableLegs}
                        refValue={"uncomfortableLegs"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>ประวัติอาการช่วงกลางวัน</b>
                      </h4>{" "}
                      <InputRadio
                        title="มีตื่นนอนไม่สดชื่น"
                        options={["มี", "ไม่มี"]}
                        name="มีตื่นนอนไม่สดชื่น"
                        checked={preSleepInfoDoc.info.wakingUpNotRefreshed}
                        refValue={"wakingUpNotRefreshed"}
                      ></InputRadio>
                      <InputRadio
                        title="มีสับสน หรือมึนงงหลังตื่นนอน"
                        options={["มี", "ไม่มี"]}
                        name="มีสับสน หรือมึนงงหลังตื่นนอน"
                        checked={preSleepInfoDoc.info.wakingUpDizzy}
                        refValue={"wakingUpDizzy"}
                      ></InputRadio>
                      <InputRadio
                        title="มีปวดหัวช่วงเช้า"
                        options={["มี", "ไม่มี"]}
                        name="มีปวดหัวช่วงเช้า"
                        checked={preSleepInfoDoc.info.wakingUpHeadache}
                        refValue={"wakingUpHeadache"}
                      ></InputRadio>
                      <InputRadio
                        title="มีง่วงนอนกลางวัน"
                        options={["มี", "ไม่มี"]}
                        name="มีง่วงนอนกลางวัน"
                        checked={preSleepInfoDoc.info.sleepyAfternoon}
                        refValue={"sleepyAfternoon"}
                      ></InputRadio>
                      <InputBox
                        title="ง่วงนอนผิดปกติมานานกี่เดือน"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["howLongSleepyAfternoon"] =
                            el)
                        }
                        defaultValue={
                          preSleepInfoDoc.info.howLongSleepyAfternoon
                        }
                      ></InputBox>
                      <InputRadio
                        title="มีนอนกลางวัน"
                        options={["มี", "ไม่มี"]}
                        name="มีนอนกลางวัน"
                        checked={preSleepInfoDoc.info.afternoonNap}
                        refValue={"afternoonNap"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้ามีนอนกลางวันนอนกี่ครั้ง"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["afternoonNapCount"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.afternoonNapCount}
                      ></InputBox>
                      <InputRadio
                        title="มีง่วงขณะขับรถ"
                        options={["มี", "ไม่มี"]}
                        name="มีง่วงขณะขับรถ"
                        checked={preSleepInfoDoc.info.sleepyDriving}
                        refValue={"sleepyDriving"}
                      ></InputRadio>
                      <InputRadio
                        title="มีประวัติอุบัติเหตุขณะขับรถเพราะเผลอหลับ"
                        options={["มี", "ไม่มี"]}
                        name="มีประวัติอุบัติเหตุขณะขับรถเพราะเผลอหลับ"
                        checked={preSleepInfoDoc.info.carAccidentFallingAsleep}
                        refValue={"carAccidentFallingAsleep"}
                      ></InputRadio>
                      <InputRadio
                        title="มีความจำไ่ม่ดีหรือขี้ลืม"
                        options={["มี", "ไม่มี"]}
                        name="มีความจำไ่ม่ดีหรือขี้ลืม"
                        checked={preSleepInfoDoc.info.memoryLapses}
                        refValue={"memoryLapses"}
                      ></InputRadio>
                      <InputRadio
                        title="มีอารมณ์แปรปรวน"
                        options={["มี", "ไม่มี"]}
                        name="มีอารมณ์แปรปรวน"
                        checked={preSleepInfoDoc.info.moodDisorders}
                        refValue={"moodDisorders"}
                      ></InputRadio>
                      <InputRadio
                        title="มีปัญหาเรื่องสมรรถภาพทางเพศ"
                        options={["มี", "ไม่มี"]}
                        name="มีปัญหาเรื่องสมรรถภาพทางเพศ"
                        checked={preSleepInfoDoc.info.sexualDysfunction}
                        refValue={"sexualDysfunction"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>ประวัติการใช้ยา</b>
                      </h4>{" "}
                      <InputRadio
                        title="มียาประจำ"
                        options={["มี", "ไม่มี"]}
                        name="มียาประจำ"
                        checked={preSleepInfoDoc.info.currentMedications}
                        refValue={"currentMedications"}
                      ></InputRadio>
                      <InputBox
                        title="มียาประจำสำหรับโรค"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["currentMedicationsNote"] =
                            el)
                        }
                        defaultValue={
                          preSleepInfoDoc.info.currentMedicationsNote
                        }
                      ></InputBox>
                      <InputRadio
                        title="มีใช้ยานอนหลับ"
                        options={["มี", "ไม่มี"]}
                        name="มีใช้ยานอนหลับ"
                        checked={preSleepInfoDoc.info.sleepingPills}
                        refValue={"sleepingPills"}
                      ></InputRadio>
                      <InputBox
                        title="ชื่อยานอนหลับ"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["sleepingPillsName"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.sleepingPillsName}
                      ></InputBox>
                      <InputRadio
                        title="มีใช้น้ำมันกัญชา"
                        options={["มี", "ไม่มี"]}
                        name="มีใช้น้ำมันกัญชา"
                        checked={preSleepInfoDoc.info.CBDOil}
                        refValue={"CBDOil"}
                      ></InputRadio>
                      <InputBox
                        title="สำหรับโรค"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["CBDOilNote"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.CBDOilNote}
                      ></InputBox>
                      <InputRadio
                        title="ได้รับยาจาก"
                        options={["ซื้อเอง", "โรงพยาบาลสาธารณสุข", "ไม่มี"]}
                        name="ได้รับยาจาก"
                        checked={preSleepInfoDoc.info.medicationsSubply}
                        refValue={"medicationsSubply"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>ประวัติโรคประจำตัว</b>
                      </h4>{" "}
                      <InputRadio
                        title="วัณโรค (TB) ระยะแพร่เชื้อ"
                        options={["มี", "ไม่มี"]}
                        name="วัณโรค (TB) ระยะแพร่เชื้อ"
                        checked={preSleepInfoDoc.info.tuberculosisSpread}
                        refValue={"tuberculosisSpread"}
                      ></InputRadio>
                      <InputRadio
                        title="โรคอ้วน BMI > 35"
                        options={["มี", "ไม่มี"]}
                        name="โรคอ้วน BMI > 35"
                        checked={preSleepInfoDoc.info.obesityMoreThan35}
                        refValue={"obesityMoreThan35"}
                      ></InputRadio>
                      <InputRadio
                        title="น้ำหนักตัวเพิ่ม"
                        options={["มี", "ไม่มี"]}
                        name="น้ำหนักตัวเพิ่ม"
                        checked={preSleepInfoDoc.info.weightGain}
                        refValue={"weightGain"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้าเพิ่ม เพิ่มกี่กิโลกรัม ภายใน กี่เดือนหรือปี"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["weightGainNote"] = el)
                        }
                        defaultValue={preSleepInfoDoc.info.weightGainNote}
                      ></InputBox>
                      <InputRadio
                        title="โรคความดันโลหิตสูง"
                        options={["มี", "ไม่มี"]}
                        name="โรคความดันโลหิตสูง"
                        checked={preSleepInfoDoc.info.highBloodPressure}
                        refValue={"highBloodPressure"}
                      ></InputRadio>
                      <InputRadio
                        title="เบาหวาน"
                        options={["มี", "ไม่มี"]}
                        name="เบาหวาน"
                        checked={preSleepInfoDoc.info.diabetes}
                        refValue={"diabetes"}
                      ></InputRadio>
                      <InputRadio
                        title="ไขมันสูง"
                        options={["มี", "ไม่มี"]}
                        name="ไขมันสูง"
                        checked={preSleepInfoDoc.info.highFat}
                        refValue={"highFat"}
                      ></InputRadio>
                      <InputRadio
                        title="หัวใจขาดเลือด"
                        options={["มี", "ไม่มี"]}
                        name="หัวใจขาดเลือด"
                        checked={preSleepInfoDoc.info.ischemicHeartDisease}
                        refValue={"ischemicHeartDisease"}
                      ></InputRadio>
                      <InputRadio
                        title="หัวใจวาย"
                        options={["มี", "ไม่มี"]}
                        name="หัวใจวาย"
                        checked={preSleepInfoDoc.info.heartAttack}
                        refValue={"heartAttack"}
                      ></InputRadio>
                      <InputRadio
                        title="หัวใจเต้นผิดจังหวะ"
                        options={["มี", "ไม่มี"]}
                        name="หัวใจเต้นผิดจังหวะ"
                        checked={preSleepInfoDoc.info.arrhythmia}
                        refValue={"arrhythmia"}
                      ></InputRadio>
                      <InputRadio
                        title="ขาบวม สองข้าง"
                        options={["มี", "ไม่มี"]}
                        name="ขาบวม สองข้าง"
                        checked={preSleepInfoDoc.info.swollenLegs}
                        refValue={"swollenLegs"}
                      ></InputRadio>
                      <InputRadio
                        title="เส้นเลือดในสมองแตกหรือตีบ"
                        options={["มี", "ไม่มี"]}
                        name="เส้นเลือดในสมองแตกหรือตีบ"
                        checked={
                          preSleepInfoDoc.info.narrowedBloodVesselsInBrain
                        }
                        refValue={"narrowedBloodVesselsInBrain"}
                      ></InputRadio>
                      <InputRadio
                        title="ภูมิแพ้จมูก"
                        options={["มี", "ไม่มี"]}
                        name="ภูมิแพ้จมูก"
                        checked={preSleepInfoDoc.info.allergicRhinitis}
                        refValue={"allergicRhinitis"}
                      ></InputRadio>
                      <InputRadio
                        title="ถุงลมโป่งพอง"
                        options={["มี", "ไม่มี"]}
                        name="ถุงลมโป่งพอง"
                        checked={preSleepInfoDoc.info.emphysema}
                        refValue={"emphysema"}
                      ></InputRadio>
                      <InputRadio
                        title="หอบหืด"
                        options={["มี", "ไม่มี"]}
                        name="หอบหืด"
                        checked={preSleepInfoDoc.info.asthma}
                        refValue={"asthma"}
                      ></InputRadio>
                      <InputRadio
                        title="โรคไทรอยด์"
                        options={["มี", "ไม่มี"]}
                        name="โรคไทรอยด์"
                        checked={preSleepInfoDoc.info.thyroidDisease}
                        refValue={"thyroidDisease"}
                      ></InputRadio>
                      <InputRadio
                        title="ภาวะกลัวที่แคบ"
                        options={["มี", "ไม่มี"]}
                        name="ภาวะกลัวที่แคบ"
                        checked={preSleepInfoDoc.info.claustrophobia}
                        refValue={"claustrophobia"}
                      ></InputRadio>
                      <InputRadio
                        title="วิตกกังวลหรือซึมเศร้า"
                        options={["มี", "ไม่มี"]}
                        name="วิตกกังวลหรือซึมเศร้า"
                        checked={preSleepInfoDoc.info.anxietyOrDepression}
                        refValue={"anxietyOrDepression"}
                      ></InputRadio>
                      <InputRadio
                        title="มีประวัติโรคติดเชื้อ"
                        options={["มี", "ไม่มี"]}
                        name="มีประวัติโรคติดเชื้อ"
                        checked={preSleepInfoDoc.info.infectiousDiseases}
                        refValue={"infectiousDiseases"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้ามีประวัติโรคติดเชื้อ โปรดระบุ"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["infectiousDiseasesNote"] =
                            el)
                        }
                        defaultValue={
                          preSleepInfoDoc.info.infectiousDiseasesNote
                        }
                      ></InputBox>
                      <InputRadio
                        title="มีประวัติความผิดปกติการนอนของคนในครอบครัว"
                        options={["มี", "ไม่มี"]}
                        name="มีประวัติความผิดปกติการนอนของคนในครอบครัว"
                        checked={preSleepInfoDoc.info.familySleepDisorders}
                        refValue={"familySleepDisorders"}
                      ></InputRadio>
                      <InputBox
                        title="ถ้ามีโปรดระบุ"
                        measure=""
                        required={true}
                        refValue={(el) =>
                          (preSleepInfoRef.current["familySleepDisordersNote"] =
                            el)
                        }
                        defaultValue={
                          preSleepInfoDoc.info.familySleepDisordersNote
                        }
                      ></InputBox>
                      <h4 className="mt-5 mb-4">
                        <b>ประวัติอาการโรคลมหลับ</b>
                      </h4>{" "}
                      <InputRadio
                        title="เคยมีฝันร้าย หรือฝันแปลกๆ"
                        options={["มี", "ไม่มี"]}
                        name="เคยมีฝันร้าย หรือฝันแปลกๆ"
                        checked={preSleepInfoDoc.info.nightmare}
                        refValue={"nightmare"}
                      ></InputRadio>
                      <InputRadio
                        title="เคยเห็นภาพหลอน หรือเสียงแว่วขณะนอนหลับ"
                        options={["มี", "ไม่มี"]}
                        name="เคยเห็นภาพหลอน หรือเสียงแว่วขณะนอนหลับ"
                        checked={preSleepInfoDoc.info.hallucinations}
                        refValue={"hallucinations"}
                      ></InputRadio>
                      <InputRadio
                        title="เคยมีอ่อนแรงบริเวณของร่างกายทันทีทันใดขณะอยู่ในสถานการณ์ที่กระตุ้นอารมณ์"
                        options={["มี", "ไม่มี"]}
                        name="เคยมีอ่อนแรงบริเวณของร่างกายทันทีทันใดขณะอยู่ในสถานการณ์ที่กระตุ้นอารมณ์"
                        checked={preSleepInfoDoc.info.cataplexy}
                        refValue={"cataplexy"}
                      ></InputRadio>
                      <InputRadio
                        title="เคยมีประวัติตื่นมาขยับตัวไม่ได้"
                        options={["มี", "ไม่มี"]}
                        name="เคยมีประวัติตื่นมาขยับตัวไม่ได้"
                        checked={preSleepInfoDoc.info.sleepParalysis}
                        refValue={"sleepParalysis"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>แบบทดสอบระดับความง่วงนอน เอ็บเวิร์ธฉบับภาษาไทย</b>
                      </h4>{" "}
                      <InputRadio
                        title="ขณะอ่านหนังสือ"
                        options={["0", "1", "2", "3"]}
                        name="ขณะอ่านหนังสือ"
                        checked={preSleepInfoDoc.info.readingSleepiness}
                        refValue={"readingSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะนั่งดูโทรทัศน์"
                        options={["0", "1", "2", "3"]}
                        name="ขณะนั่งดูโทรทัศน์"
                        checked={preSleepInfoDoc.info.tvWatchingSleepiness}
                        refValue={"tvWatchingSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะกำลังนั่งเฉยๆ ในที่สาธารณะ เช่น ในโรงภาพยนต์ หรือที่ประชุมสัมมนา"
                        options={["0", "1", "2", "3"]}
                        name="ขณะกำลังนั่งเฉยๆ ในที่สาธารณะ เช่น ในโรงภาพยนต์ หรือที่ประชุมสัมมนา"
                        checked={preSleepInfoDoc.info.publicPlaceSleepiness}
                        refValue={"publicPlaceSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะกำลังนั่งเป็นผู้โดยสารในรถนานกว่า 1 ชั่วโมงอย่างต่อเนื่อง"
                        options={["0", "1", "2", "3"]}
                        name="ขณะกำลังนั่งเป็นผู้โดยสารในรถนานกว่า 1 ชั่วโมงอย่างต่อเนื่อง"
                        checked={
                          preSleepInfoDoc.info.longTripPassengerSleepiness
                        }
                        refValue={"longTripPassengerSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะกำลังนั่งเอนหลัง เพื่อพักผ่อนในตอนบ่ายถ้ามีโอกาส"
                        options={["0", "1", "2", "3"]}
                        name="ขณะกำลังนั่งเอนหลัง เพื่อพักผ่อนในตอนบ่ายถ้ามีโอกาส"
                        checked={preSleepInfoDoc.info.afternoonRestSleepiness}
                        refValue={"afternoonRestSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะกำลังนั่ง และพูดคุยกับผู้อื่น"
                        options={["0", "1", "2", "3"]}
                        name="ขณะกำลังนั่ง และพูดคุยกับผู้อื่น"
                        checked={preSleepInfoDoc.info.conversationSleepiness}
                        refValue={"conversationSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะกำลังนั่งเงียบๆ หลังอาหารกลางวัน โดยที่ไม่ได้ดื่มแอลกอฮอล์"
                        options={["0", "1", "2", "3"]}
                        name="ขณะกำลังนั่งเงียบๆ หลังอาหารกลางวัน โดยที่ไม่ได้ดื่มแอลกอฮอล์"
                        checked={preSleepInfoDoc.info.afterLunchSleepiness}
                        refValue={"afterLunchSleepiness"}
                      ></InputRadio>
                      <InputRadio
                        title="ขณะกำลังขับรถแต่รถหยุด เพื่อรอสัญญาณจราจรนาน 2-3 นาที"
                        options={["0", "1", "2", "3"]}
                        name="ขณะกำลังขับรถแต่รถหยุด เพื่อรอสัญญาณจราจรนาน 2-3 นาที"
                        checked={preSleepInfoDoc.info.trafficStopSleepiness}
                        refValue={"trafficStopSleepiness"}
                      ></InputRadio>
                      <h4 className="mt-5 mb-4">
                        <b>แบบสอบถาม STOP-BANG</b>
                      </h4>{" "}
                      <InputRadio
                        title="นอนกรนดัง (ดังกว่าเสียงพูดปกติ หรือดังออกไปนอกห้อง)"
                        options={["ใช่", "ไม่ใช่"]}
                        name="นอนกรนดัง (ดังกว่าเสียงพูดปกติ หรือดังออกไปนอกห้อง)"
                        checked={preSleepInfoDoc.info.snoring}
                        refValue={"snoring"}
                      ></InputRadio>
                      <InputRadio
                        title="อ่อนเพลีย ล้า หรือรู้สึกง่วงนอนในตอนกลางวันบ่อย"
                        options={["ใช่", "ไม่ใช่"]}
                        name="อ่อนเพลีย ล้า หรือรู้สึกง่วงนอนในตอนกลางวันบ่อย"
                        checked={preSleepInfoDoc.info.tiredness}
                        refValue={"tiredness"}
                      ></InputRadio>
                      <InputRadio
                        title="มีคนเคยสังเกตว่ามีหยุดหายใจขณะหลับ"
                        options={["ใช่", "ไม่ใช่"]}
                        name="มีคนเคยสังเกตว่ามีหยุดหายใจขณะหลับ"
                        checked={preSleepInfoDoc.info.observedStopBreathing}
                        refValue={"observedStopBreathing"}
                      ></InputRadio>
                      <InputRadio
                        title="มีความดันโลหิตสูง หรือกำลังรักษาโรคความดันโลหิตสูง"
                        options={["ใช่", "ไม่ใช่"]}
                        name="มีความดันโลหิตสูง หรือกำลังรักษาโรคความดันโลหิตสูง"
                        checked={preSleepInfoDoc.info.pressure}
                        refValue={"pressure"}
                      ></InputRadio>
                      <InputRadio
                        title="ดัชนีมวลกายมากกว่า 35 กก./ตร.ม."
                        options={["ใช่", "ไม่ใช่"]}
                        name="ดัชนีมวลกายมากกว่า 35 กก./ตร.ม."
                        checked={preSleepInfoDoc.info.BMIHigherThan35}
                        refValue={"BMIHigherThan35"}
                      ></InputRadio>
                      <InputRadio
                        title="อายุมากกว่า 50 ปี"
                        options={["ใช่", "ไม่ใช่"]}
                        name="อายุมากกว่า 50 ปี"
                        checked={preSleepInfoDoc.info.ageOlderThan50}
                        refValue={"ageOlderThan50"}
                      ></InputRadio>
                      <InputRadio
                        title="เส้นรอบคอมากกว่า 40 เซนติเมตร (วัดตรงส่วนที่นูนที่สุดของคอ)"
                        options={["ใช่", "ไม่ใช่"]}
                        name="เส้นรอบคอมากกว่า 40 เซนติเมตร (วัดตรงส่วนที่นูนที่สุดของคอ)"
                        checked={
                          preSleepInfoDoc.info.neckCircumferenceGreaterThan40cm
                        }
                        refValue={"neckCircumferenceGreaterThan40cm"}
                      ></InputRadio>
                      <InputRadio
                        title="เพศชาย"
                        options={["ใช่", "ไม่ใช่"]}
                        name="เพศชาย"
                        checked={preSleepInfoDoc.info.males}
                        refValue={"males"}
                      ></InputRadio>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        บันทึกข้อมูล
                      </button>
                      <button
                        onClick={() => {
                          history.push(`/patient-page/${id}`);
                        }}
                        type="submit"
                        className="btn btn-light ml-2"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};

export default PreSleepInformation;
