import React, { Component, Fragment, useState } from "react";
import { Col, Row, Card, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import { Calendar } from "@fullcalendar/core";
import { db, functions, auth, storage } from "../../../firebase";
import { ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
// import { sleepStudyReportMail } from "../../../../functions";
import {
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  collection,
  query,
  where,
  getCollections,
  collectionGroup,
  orderBy,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import { async } from "@firebase/util";
import Alert from "sweetalert2";
// import { isExists } from "date-fns";
// import EditEvent from "./EditEvent";
import {
  notifyTopRight,
  notifyWarning,
  notifyError,
} from "../../components/toast/SetToast";
import Toast from "../../components/toast/SetToast";
import { AuthContext } from "../../../context/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import { connectStorageEmulator } from "firebase/storage";
import {
  orderList,
  typeList,
  colorList,
  statusList,
  paymentPrivilages,
  PatientDropDownForm,
  PatientTextInfo,
  PatientLinkInfo,
  eventTmp,
  statusOrder,
  // sendNotification,
} from "./BookingUtils";
import {
  startAddReport,
  startSetReports,
  startRemoveReport,
  startEditReport,
} from "../../../actions/reports";
import selectReports from "../../../selectors/reports";

// import "react-datepicker/dist/react-datepicker-cssmodules.css";

class EventCalendar extends Component {
  constructor(props) {
    super(props);
    this.calendarComponentRef = React.createRef();
    this.state = {
      calendarEvents: [],
      nodeList: [],
      customerList: [],
      customerAllList: [],
      itemList: [],
      events: [
        {
          title: "Event",
          id: "6",
          order: "",
          hn: "",
          type: "",
          status: "",
          color: "green",
        },
      ],
      eventTmp: {
        title: "",
        startStr: "",
        id: "",
        extendedProps: {
          order: "",
          hn: "",
          type: "",
          status: "",
          firstname: "",
          lastname: "",
          nationalId: "",
          healthInsurance: "",
          phone: "",
          note: "",
          node: "",
          customer: "",
          items: [],
          totalValue: 0,
          time: "",
          technician: "",
          doctor: "",
          referDoctor: "",
          techrecord: "",
          sleepDate: "",
        },
      },
      items: [],
      technicianList: [],
      techrecordList: [],
      doctorList: [],
      nodeIdDocs: [],
      totalValue: 0,
      dateTmp: new Date().toISOString().slice(0, 10),
      resetDateTmp: new Date().toISOString().slice(0, 10),
      isAddEventModal: false,
      isNoti: false,
      isEditEventModal: false,
      isNewPatient: false,
      isEditBookingDetail: false,
      errStatus: false,
      isDatetime: false,
      isLoading: true,
      createModal: false,
      fileUrl: null,
      modalEmail: false,
      emailList: [],
    };
    this._isMounted = false;
    this.cache = {}; // Initialize cache
  }

  static contextType = AuthContext;

  dateRef = React.createRef();
  titleRef = React.createRef();
  idRef = React.createRef();
  orderRef = React.createRef();
  nodeRef = React.createRef();
  customerRef = React.createRef();
  hnRef = React.createRef();
  typeRef = React.createRef();
  statusRef = React.createRef();
  colorRef = React.createRef();
  firstnameRef = React.createRef();
  lastnameRef = React.createRef();
  nationalIdRef = React.createRef();
  genderRef = React.createRef();
  healthInsuranceRef = React.createRef();
  phoneRef = React.createRef();
  noteRef = React.createRef();
  itemsRef = React.createRef([]);
  techRef = React.createRef([]);
  docRef = React.createRef([]);
  referDocRef = React.createRef([]);
  techRecordRef = React.createRef([]);
  fileInputRef = React.createRef(null);
  hospitalNumberRef = React.createRef();
  ahiRef = React.createRef();
  currentEmailRef = React.createRef();

  nodeSelectRef = React.createRef();
  techSelectRef = React.createRef();

  nodeName = "brain dynamics";
  typeList = typeList;
  statusList = statusList;
  colorList = colorList;
  orderList = orderList;
  paymentPrivilages = paymentPrivilages;
  PatientDropDownForm = PatientDropDownForm;
  PatientTextInfo = PatientTextInfo;
  PatientLinkInfo = PatientLinkInfo;
  statusOrder = statusOrder;

  getPatienBySearch = httpsCallable(functions, "getPatienBySearch");
  sendNotification = httpsCallable(functions, "sendNotification");
  createPatient = httpsCallable(functions, "createPatient");
  getSignedUrl = httpsCallable(functions, "getSignedUrl");
  sleepStudyReportMail = httpsCallable(functions, "sleepStudyReportMail");

  componentDidMount() {
    this._isMounted = true;
    this.rehydrateState();
    this.fetchData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  rehydrateState = () => {
    const cachedState = JSON.parse(localStorage.getItem("eventCalendarState"));
    if (cachedState) {
      this.setState(cachedState);
    }
  };

  cacheState = () => {
    const stateToCache = {
      calendarEvents: this.state.calendarEvents,
      nodeList: this.state.nodeList,
      customerAllList: this.state.customerAllList,
      itemList: this.state.itemList,
      technicianList: this.state.technicianList,
      techRecordList: this.state.techrecordList,
      doctorList: this.state.doctorList,
      nodeIdDocs: this.state.nodeIdDocs,
    };
    localStorage.setItem("eventCalendarState", JSON.stringify(stateToCache));
  };

  logFetchTime = (label, startTime) => {
    const endTime = Date.now();
    console.log(`${label} took ${endTime - startTime} ms`);
  };

  fetchData = async () => {
    try {
      const startFetchTime = Date.now();

      // Use Promise.all for parallel fetching
      const [nodesDocs, itemsDocs, techDocs, docDocs] = await Promise.all([
        getDocs(collection(db, "nodes")),
        getDocs(collection(db, "items")),
        getDocs(collection(db, "sleeptechnicians")),
        getDocs(collection(db, "doctors")),
      ]);

      if (!this._isMounted) return;

      this.logFetchTime(
        "Fetching nodes, items, techs, and docs",
        startFetchTime
      );

      let allCustomers = [];
      let bookingEvent = [];
      let allnodeIdDocs = [];
      let allItems = [];
      let allNodes;

      const currentUserRole = this.context.currentUser.roles[0];
      const currentUserName = this.context.currentUser.displayName;

      console.log("role: ", currentUserRole);

      const processNode = async (nodeDoc) => {
        const nodeId = nodeDoc.id;
        allnodeIdDocs.push(nodeId);

        // Check cache
        if (this.cache[nodeId]) {
          const { customers, bookings } = this.cache[nodeId];
          allCustomers.push(customers);
          bookingEvent = [...bookingEvent, ...bookings];
          return;
        }

        const [customerDocs, bookingDocs] = await Promise.all([
          getDocs(collection(db, "nodes", nodeId, "customers")),
          getDocs(collection(db, "nodes", nodeId, "bookings")),
        ]);

        const customersData = customerDocs.docs.map((customer) => customer.id);
        allCustomers.push(customersData);

        const nodeBookings = bookingDocs.docs.map((booking) => {
          const bookingData = booking.data();
          const dateStart = new Date(bookingData.start.toDate());
          dateStart.setHours(dateStart.getHours() + 7);
          bookingData.start = dateStart.toISOString().slice(0, 10);
          return bookingData;
        });
        bookingEvent = [...bookingEvent, ...nodeBookings];

        // Cache the data
        this.cache[nodeId] = {
          customers: customersData,
          bookings: nodeBookings,
        };
      };

      const BATCH_SIZE = 5;
      const processNodesInBatches = async (nodes) => {
        for (let i = 0; i < nodes.length; i += BATCH_SIZE) {
          const batch = nodes.slice(i, i + BATCH_SIZE);
          await Promise.all(batch.map((nodeDoc) => processNode(nodeDoc)));
        }
      };

      if (currentUserRole === "super_admin" || currentUserRole === "sale") {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        await processNodesInBatches(nodesDocs.docs);
        allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));
      } else if (
        currentUserRole === "sleep_physician" ||
        currentUserRole === "sleep_technician"
      ) {
        allNodes = nodesDocs.docs.map((doc) => doc.data().nodename);
        await processNodesInBatches(nodesDocs.docs);

        if (currentUserRole === "sleep_physician") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              bookingData.extendedProps &&
              bookingData.extendedProps.doctor === currentUserName
          );
        } else if (currentUserRole === "sleep_technician") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              (bookingData.extendedProps &&
                bookingData.extendedProps.techrecord === currentUserName) ||
              bookingData.extendedProps.technician === currentUserName
          );
        }
      } else {
        const currentAdminDoc = await getDoc(
          doc(db, "admins", currentUserName)
        );
        const currentAdmin = currentAdminDoc.data();
        const nodeCode = currentAdmin.node;
        const node = nodesDocs.docs.filter(
          (doc) => doc.data().nodecode === nodeCode
        );
        allnodeIdDocs = node.map((doc) => doc.id);
        allNodes = node.map((doc) => doc.data().nodename);

        await processNodesInBatches(node);

        allItems = itemsDocs.docs
          .map((doc) => ({
            ...doc.data(),
            itemcode: doc.id,
          }))
          .filter((doc) => doc.node === nodeCode);
      }

      const allTechs = [
        "กรุณาเลือก",
        ...techDocs.docs.map((doc) => doc.data().name),
      ];
      const allTechsRecord = [
        "กรุณาเลือก",
        ...techDocs.docs.map((doc) => doc.data().name),
      ];
      const allDocs = [
        "กรุณาเลือก",
        ...docDocs.docs.map((doc) => doc.data().name),
      ];

      console.log("allNodes:", allNodes);
      console.log("allNodeIdDocs:", allnodeIdDocs);
      console.log("allCustomers:", allCustomers);
      console.log("bookingEvent:", bookingEvent);
      console.log("allItems:", allItems);
      console.log("allTechs:", allTechs);
      console.log("allDocs:", allDocs);

      if (this._isMounted) {
        this.setState({
          calendarEvents: bookingEvent,
          itemList: allItems,
          nodeList: allNodes,
          customerAllList: allCustomers,
          customerList: allCustomers[0],
          technicianList: allTechs,
          techrecordList: allTechsRecord,
          doctorList: allDocs,
          nodeIdDocs: allnodeIdDocs,
          isLoading: false,
        });

        this.cacheState(); // Cache the current state

        const draggableEl = document.getElementById("external-events");
        if (draggableEl) {
          new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
              const title = eventEl.getAttribute("title");
              const id = eventEl.getAttribute("id");
              const order = eventEl.getAttribute("order");
              const hn = eventEl.getAttribute("hn");
              const type = eventEl.getAttribute("type");
              const status = eventEl.getAttribute("status");
              const color = eventEl.getAttribute("color");
              const startStr = eventEl.getAttribute("startStr");
              return {
                title,
                id,
                startStr,
                order,
                hn,
                type,
                status,
                color,
              };
            },
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ isLoading: false });
    }
  };

  onChangeNode = async (nodeValue) => {
    try {
      this.setState({ calendarEvents: [] });
      const techValue = this.techSelectRef.current.value;
      console.log("techValue: ", techValue);

      const [nodesDocs, itemsDocs] = await Promise.all([
        getDocs(collection(db, "nodes")),
        getDocs(collection(db, "items")),
      ]);

      const nodeName = nodeValue.target.value;
      let allCustomers = [];
      let bookingEvent = [];
      let allnodeIdDocs = [];
      let allItems = [];

      const currentUserRole = this.context.currentUser.roles[0];
      const currentUserName = this.context.currentUser.displayName;

      const processNode = async (nodeId) => {
        const [customerDocs, bookingDocs] = await Promise.all([
          getDocs(collection(db, "nodes", nodeId, "customers")),
          getDocs(collection(db, "nodes", nodeId, "bookings")),
        ]);

        const customersData = customerDocs.docs.map((customer) => customer.id);
        allCustomers.push(customersData);

        const nodeBookings = bookingDocs.docs.map((booking) => {
          const bookingData = booking.data();
          const dateStart = new Date(bookingData.start.toDate());
          dateStart.setHours(dateStart.getHours() + 7);
          bookingData.start = dateStart.toISOString().slice(0, 10);
          return bookingData;
        });

        bookingEvent = [...bookingEvent, ...nodeBookings];
      };

      const BATCH_SIZE = 5;
      const processNodesInBatches = async (nodes) => {
        for (let i = 0; i < nodes.length; i += BATCH_SIZE) {
          const batch = nodes.slice(i, i + BATCH_SIZE);
          await Promise.all(batch.map((nodeId) => processNode(nodeId)));
        }
      };

      if (nodeName !== "All Node") {
        const node = nodesDocs.docs.find(
          (doc) => doc.data().nodename === nodeName
        );
        if (node) {
          allnodeIdDocs = [node.id];
          await processNodesInBatches(allnodeIdDocs);

          if (techValue != "กรุณาเลือก") {
            bookingEvent = bookingEvent.filter(
              (bookingData) =>
                (bookingData.extendedProps &&
                  bookingData.extendedProps.techrecord === techValue) ||
                bookingData.extendedProps.technician === techValue
            );
          }

          if (currentUserRole === "sleep_physician") {
            bookingEvent = bookingEvent.filter(
              (bookingData) =>
                bookingData.extendedProps &&
                bookingData.extendedProps.doctor === currentUserName
            );
          } else if (currentUserRole === "sleep_technician") {
            bookingEvent = bookingEvent.filter(
              (bookingData) =>
                (bookingData.extendedProps &&
                  bookingData.extendedProps.techrecord === currentUserName) ||
                bookingData.extendedProps.technician === currentUserName
            );
          }

          allItems = itemsDocs.docs.map((doc) => ({
            ...doc.data(),
            itemcode: doc.id,
          }));

          this.setState({
            calendarEvents: bookingEvent,
            customerList: allCustomers[0],
          });
        }
      } else {
        allnodeIdDocs = nodesDocs.docs.map((doc) => doc.id);

        await processNodesInBatches(allnodeIdDocs);

        if (techValue != "กรุณาเลือก") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              (bookingData.extendedProps &&
                bookingData.extendedProps.techrecord === techValue) ||
              bookingData.extendedProps.technician === techValue
          );
        }

        if (currentUserRole === "sleep_physician") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              bookingData.extendedProps &&
              bookingData.extendedProps.doctor === currentUserName
          );
        } else if (currentUserRole === "sleep_technician") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              (bookingData.extendedProps &&
                bookingData.extendedProps.techrecord === currentUserName) ||
              bookingData.extendedProps.technician === currentUserName
          );
        }

        allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));

        this.setState({
          calendarEvents: bookingEvent,
          customerList: allCustomers.flat(),
        });
      }
    } catch (error) {
      console.error("Error changing node:", error);
    }
  };

  onChangeTech = async (techValue) => {
    try {
      this.setState({ calendarEvents: [] });
      const techName = techValue.target.value;

      const [nodesDocs, itemsDocs] = await Promise.all([
        getDocs(collection(db, "nodes")),
        getDocs(collection(db, "items")),
      ]);

      const nodeName = this.nodeSelectRef.current.value;

      let allCustomers = [];
      let bookingEvent = [];
      let allnodeIdDocs = [];
      let allItems = [];

      const currentUserRole = this.context.currentUser.roles[0];
      const currentUserName = this.context.currentUser.displayName;

      const processNode = async (nodeId) => {
        const [customerDocs, bookingDocs] = await Promise.all([
          getDocs(collection(db, "nodes", nodeId, "customers")),
          getDocs(collection(db, "nodes", nodeId, "bookings")),
        ]);

        const customersData = customerDocs.docs.map((customer) => customer.id);
        allCustomers.push(customersData);

        const nodeBookings = bookingDocs.docs.map((booking) => {
          const bookingData = booking.data();
          const dateStart = new Date(bookingData.start.toDate());
          dateStart.setHours(dateStart.getHours() + 7);
          bookingData.start = dateStart.toISOString().slice(0, 10);
          return bookingData;
        });

        bookingEvent = [...bookingEvent, ...nodeBookings];
      };

      const BATCH_SIZE = 5;
      const processNodesInBatches = async (nodes) => {
        for (let i = 0; i < nodes.length; i += BATCH_SIZE) {
          const batch = nodes.slice(i, i + BATCH_SIZE);
          await Promise.all(batch.map((nodeId) => processNode(nodeId)));
        }
      };

      if (nodeName !== "All Node") {
        const node = nodesDocs.docs.find(
          (doc) => doc.data().nodename === nodeName
        );
        if (node) {
          allnodeIdDocs = [node.id];
          await processNodesInBatches(allnodeIdDocs);
          if (techName != "กรุณาเลือก") {
            bookingEvent = bookingEvent.filter(
              (bookingData) =>
                (bookingData.extendedProps &&
                  bookingData.extendedProps.techrecord === techName) ||
                bookingData.extendedProps.technician === techName
            );
          }
          if (currentUserRole === "sleep_physician") {
            bookingEvent = bookingEvent.filter(
              (bookingData) =>
                bookingData.extendedProps &&
                bookingData.extendedProps.doctor === currentUserName
            );
          } else if (currentUserRole === "sleep_technician") {
            bookingEvent = bookingEvent.filter(
              (bookingData) =>
                (bookingData.extendedProps &&
                  bookingData.extendedProps.techrecord === currentUserName) ||
                bookingData.extendedProps.technician === currentUserName
            );
          }

          allItems = itemsDocs.docs.map((doc) => ({
            ...doc.data(),
            itemcode: doc.id,
          }));

          this.setState({
            calendarEvents: bookingEvent,
            customerList: allCustomers[0],
          });
        }
      } else {
        allnodeIdDocs = nodesDocs.docs.map((doc) => doc.id);

        await processNodesInBatches(allnodeIdDocs);
        // techSelectRef
        if (techName != "กรุณาเลือก") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              (bookingData.extendedProps &&
                bookingData.extendedProps.techrecord === techName) ||
              bookingData.extendedProps.technician === techName
          );
        }

        if (currentUserRole === "sleep_physician") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              bookingData.extendedProps &&
              bookingData.extendedProps.doctor === currentUserName
          );
        } else if (currentUserRole === "sleep_technician") {
          bookingEvent = bookingEvent.filter(
            (bookingData) =>
              (bookingData.extendedProps &&
                bookingData.extendedProps.techrecord === currentUserName) ||
              bookingData.extendedProps.technician === currentUserName
          );
        }

        allItems = itemsDocs.docs.map((doc) => ({
          ...doc.data(),
          itemcode: doc.id,
        }));

        this.setState({
          calendarEvents: bookingEvent,
          customerList: allCustomers.flat(),
        });
      }
    } catch (error) {
      console.error("Error changing tech:", error);
    }
  };

  onRemoveEvent = async (isExists) => {
    // console.log(this.state.eventTmp.extendedProps.uuid);
    if (
      this.state.eventTmp.extendedProps.uuid != "" &&
      this.state.eventTmp.extendedProps.uuid != null &&
      this.state.eventTmp.extendedProps.uuid != undefined
    ) {
      const nodeIndex = this.state.nodeList.indexOf(
        this.state.eventTmp.extendedProps.node
      );
      const nodeId = this.state.nodeIdDocs[nodeIndex];

      const refDelete = doc(
        db,
        "nodes",
        // this.nodeName,
        nodeId,
        "bookings",
        this.state.eventTmp.extendedProps.uuid
      );
      await deleteDoc(refDelete)
        .then(async (res) => {
          // notifyTopRight("Delete Success!", res);
          swal("Your booking event has been deleted!", {
            icon: "success",
          });
        })
        .catch((err) => {
          notifyError("Detele Error ", err);
        });

      // check sleep study for doctor before delete event
      const titleToCheck = this.state.eventTmp.title;
      // Create a collection group query to find sleep study cases with the same title
      const matchingTitlesQuery = query(
        collectionGroup(db, "sleepStudyCases"),
        where("title", "==", titleToCheck)
      );
      const querySnapshot = await getDocs(matchingTitlesQuery);
      if (querySnapshot.empty) {
        console.log("no matching documents found !");
      } else {
        for (const doc of querySnapshot.docs) {
          // Delete the document
          await deleteDoc(doc.ref);
          console.log(`Deleted sleep study case with title "${titleToCheck}" `);
        }
      }
    }
    this.state.eventTmp.remove();
    // this.forceUpdate();
  };
  onSearchPatient = async (e) => {
    const q = query(
      collection(db, "patients"),
      where("hn", "==", this.hnRef.current.value)
    );
    const querySnapshot = await getDocs(q);
    let listPatient = [];
    // console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listPatient.push(doc.data());
    });
    if (listPatient[0] == undefined) {
      this.setState({ isNewPatient: true });
    } else {
      console.log(listPatient[0]);
      // console.log(this.state.eventTmp.extendedProps.hn);
      try {
        this.firstnameRef.current.value = listPatient[0].firstnameTh;
        this.lastnameRef.current.value = listPatient[0].lastnameTh;
        this.nationalIdRef.current.value = listPatient[0].nationalId;
        this.phoneRef.current.value = listPatient[0].mobile;
        this.hospitalNumberRef.current.value = listPatient[0].refHn;
        this.genderRef.current.value = listPatient[0].gender;
        if (
          listPatient[0].healthInsurance == "" ||
          listPatient[0].healthInsurance == "please-select"
        ) {
          this.healthInsuranceRef.current.value = "กรุณาเลือก";
        } else {
          this.healthInsuranceRef.current.value =
            listPatient[0].healthInsurance;
        }
      } catch (err) {
        var getTmp = this.state.eventTmp;
        // console.log(getTmp);
        getTmp.setExtendedProp("firstname", listPatient[0].firstnameTh);
        getTmp.setExtendedProp("lastname", listPatient[0].lastnameTh);
        getTmp.setExtendedProp("nationalId", listPatient[0].nationalId);
        getTmp.setExtendedProp("phone", listPatient[0].mobile);
        getTmp.setExtendedProp("hospitalNumber", listPatient[0].refHn);
        getTmp.setExtendedProp("gender", listPatient[0].gender);
        if (
          listPatient[0].healthInsurance == "" ||
          listPatient[0].healthInsurance == "please-select"
        ) {
          getTmp.setExtendedProp("healthInsurance", "กรุณาเลือก");
        } else {
          getTmp.setExtendedProp(
            "healthInsurance",
            listPatient[0].healthInsurance
          );
        }
        this.setState({ eventTmp: getTmp });
      }
      this.setState({ isNewPatient: false });
    }
  };
  handleDate = (moment, props) => {
    let storeMoment = moment.toISOString().slice(0, 10);
    let evtmp = this.state.eventTmp;
    evtmp.setStart(moment);
    evtmp.setEnd(moment.getDate() + 1);
    this.setState({ dateTmp: storeMoment });
    this.setState({ eventTmp: evtmp });
  };
  handleTime = (moment, props) => {
    // console.log(moment.target.value);
    var evtmp = this.state.eventTmp;
    evtmp.setExtendedProp("time", moment.target.value);
    this.setState({ eventTmp: evtmp });
  };
  onHideAddEventModal = () => {
    this.setState({ items: [] }, () => {
      this.setState({ totalValue: 0 });
    });
    this.setState({ isAddEventModal: false });
    this.setState({ totalValue: 0 });
    this.onRemoveEvent(false);
  };
  onHideEditEventModal = () => {
    this.setState({ items: [] }, () => {
      this.setState({ totalValue: 0 });
      this.setState({ isEditEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isEditBookingDetail: false });
      // this.setState({ isEditBookingDetail: true });
      console.log("eventTmp: ", this.state.eventTmp);
      // console.log("items: ", this.state.items);
    });

    // console.log("Hide EditEvent");
  };

  onHideNoti = () => {
    this.setState({ items: [] }, () => {
      this.setState({ totalValue: 0 });
    });
    this.setState({ isNoti: false });
    this.setState({ isEditBookingDetail: false });
    // this.setState({ isEditBookingDetail: true });
  };

  onAddEvent = async () => {
    // e.preventDefault();
    let errStatus = false;
    let eventAdd = this.state.eventTmp;
    // if (this.state.eventTmp.extendedProps.hn == "") {
    //   eventAdd.setExtendedProp("order", eventAdd.title);
    // } else {
    //   eventAdd.setExtendedProp("order", eventAdd.extendedProps.order);
    // }
    const currentUserName = this.context.currentUser.displayName;
    eventAdd.setExtendedProp("createBy", currentUserName);

    eventAdd.setExtendedProp("order", this.orderRef.current.value);
    eventAdd.setExtendedProp("hn", this.hnRef.current.value);
    eventAdd.setExtendedProp("type", this.typeRef.current.value);
    eventAdd.setExtendedProp("node", this.nodeRef.current.value);
    eventAdd.setExtendedProp("customer", this.customerRef.current.value);
    eventAdd.setExtendedProp("technician", this.techRef.current.value);
    eventAdd.setExtendedProp("doctor", this.docRef.current.value);
    eventAdd.setExtendedProp("referDoctor", this.referDocRef.current.value);
    eventAdd.setExtendedProp("techrecord", this.techRecordRef.current.value);
    eventAdd.setProp("id", 6);

    // set email
    eventAdd.setExtendedProp("email", false);

    try {
      eventAdd.setExtendedProp("nationalId", this.nationalIdRef.current.value);
      eventAdd.setExtendedProp("firstname", this.firstnameRef.current.value);
      eventAdd.setExtendedProp("lastname", this.lastnameRef.current.value);
      eventAdd.setExtendedProp("phone", this.phoneRef.current.value);
      eventAdd.setExtendedProp("gender", this.genderRef.current.value);
      eventAdd.setExtendedProp(
        "hospitalNumber",
        this.hospitalNumberRef.current.value
      );
    } catch (err) {
      eventAdd.setExtendedProp("nationalId", eventAdd.extendedProps.nationalId);
      eventAdd.setExtendedProp("firstname", eventAdd.extendedProps.firstname);
      eventAdd.setExtendedProp("lastname", eventAdd.extendedProps.lastname);
      eventAdd.setExtendedProp("phone", eventAdd.extendedProps.phone);
      eventAdd.setExtendedProp("gender", eventAdd.extendedProps.gender);
      eventAdd.setExtendedProp(
        "hospitalNumber",
        eventAdd.extendedProps.hospitalNumber
      );
    }
    eventAdd.setProp(
      "title",
      eventAdd.extendedProps.order // + " HN" + this.hnRef.current.value
    );
    eventAdd.setExtendedProp(
      "healthInsurance",
      this.healthInsuranceRef.current.value
    );
    eventAdd.setExtendedProp("note", this.noteRef.current.value);
    eventAdd.setExtendedProp("items", this.state.items);
    eventAdd.setExtendedProp("totalValue", this.state.totalValue);

    var indexOfStatusList = this.statusList.findIndex(
      (obj) => obj === this.statusRef.current.value
    );
    eventAdd.setExtendedProp("status", this.statusRef.current.value);
    eventAdd.setProp("color", this.colorList[indexOfStatusList]);

    if (this.statusRef.current.value === "Sleep Test Scheduled") {
      eventAdd.setExtendedProp("latestSleepStudyDate", eventAdd.startStr);
    }

    if (this.statusRef.current.value != "Post-Consult") {
      eventAdd.setExtendedProp("time", "");
    }

    eventAdd.setExtendedProp("ahi", this.ahiRef.current.value);

    if (this.statusRef.current.value != "ไม่ว่าง") {
      try {
        const refPatient = doc(db, "patients", eventAdd.extendedProps.hn);
        const docSnap = await getDoc(refPatient);
        if (docSnap.exists()) {
          this.setEvent(eventAdd);
          this.setState({ eventTmp: eventAdd });
          this.setState({ isAddEventModal: false });
          this.setState({ isNewPatient: false });
          this.setState({ isNoti: true });
          notifyTopRight("Create success ");
        } else {
          // console.log("eventAdd: ", eventAdd);
          this.setEvent(eventAdd);
          this.setState({ eventTmp: eventAdd });
          this.setState({ isAddEventModal: false });
          this.setState({ isNewPatient: false });
          this.setState({ isNoti: true });
          notifyTopRight("Create success ");
        }
      } catch (err) {
        console.log(err);
        this.setState({ isAddEventModal: false });
        this.setState({ isNewPatient: false });
        notifyError("Error, Please fill out the booking form");
        // this.onCancel(true);
        this.onRemoveEvent();
      }
    } else {
      this.setEvent(eventAdd);
      this.setState({ eventTmp: eventAdd });
      this.setState({ isAddEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isNoti: true });
    }
  };

  onEditEvent = async () => {
    // console.log("onEditEvent");
    // e.preventDefault();
    let errStatus = false;
    // console.log(this.state.eventTmp.extendedProps.node);
    // console.log(this.nodeRef.current.value);
    let eventAdd = this.state.eventTmp;
    let eventOld = JSON.parse(JSON.stringify(this.state.eventTmp));
    if (this.state.isEditBookingDetail == true) {
      const currentUserName = this.context.currentUser.displayName;
      eventAdd.setExtendedProp("createBy", currentUserName);

      eventAdd.setExtendedProp("order", this.orderRef.current.value);
      eventAdd.setExtendedProp("hn", this.hnRef.current.value);
      eventAdd.setExtendedProp("type", this.typeRef.current.value);
      eventAdd.setExtendedProp("node", this.nodeRef.current.value);
      eventAdd.setExtendedProp("customer", this.customerRef.current.value);
      eventAdd.setExtendedProp("items", this.state.items);
      eventAdd.setExtendedProp("totalValue", this.state.totalValue);
      eventAdd.setExtendedProp("technician", this.techRef.current.value);
      eventAdd.setExtendedProp("doctor", this.docRef.current.value);
      eventAdd.setExtendedProp("referDoctor", this.referDocRef.current.value);
      eventAdd.setExtendedProp("techrecord", this.techRecordRef.current.value);
      eventAdd.setProp("id", 6);

      try {
        // console.log("HN == ว่าง: ", eventAdd.extendedProps.hn);
        eventAdd.setExtendedProp(
          "nationalId",
          this.nationalIdRef.current.value
        );
        eventAdd.setExtendedProp("firstname", this.firstnameRef.current.value);
        eventAdd.setExtendedProp("lastname", this.lastnameRef.current.value);
        eventAdd.setExtendedProp("phone", this.phoneRef.current.value);
        eventAdd.setExtendedProp("gender", this.genderRef.current.value);
        eventAdd.setExtendedProp(
          "hospitalNumber",
          this.hospitalNumberRef.current.value
        );
      } catch (err) {
        // console.log("HN == ไม่ว่าง: ", eventAdd.extendedProps.hn);
        eventAdd.setExtendedProp(
          "nationalId",
          eventAdd.extendedProps.nationalId
        );
        eventAdd.setExtendedProp("firstname", eventAdd.extendedProps.firstname);
        eventAdd.setExtendedProp("lastname", eventAdd.extendedProps.lastname);
        eventAdd.setExtendedProp("phone", eventAdd.extendedProps.phone);
        if (
          eventAdd.extendedProps.hospitalNumber == undefined ||
          eventAdd.extendedProps.hospitalNumber == "" ||
          eventAdd.extendedProps.hospitalNumber == null
        ) {
          eventAdd.setExtendedProp("hospitalNumber", "");
        } else {
          eventAdd.setExtendedProp(
            "hospitalNumber",
            eventAdd.extendedProps.hospitalNumber
          );
        }
        if (eventAdd.extendedProps.gender) {
          eventAdd.setExtendedProp("gender", eventAdd.extendedProps.gender);
        } else {
          eventAdd.setExtendedProp("gender", "");
        }
      }
      eventAdd.setProp(
        "title",
        eventAdd.extendedProps.order // + " HN" + this.hnRef.current.value
      );
      eventAdd.setExtendedProp(
        "healthInsurance",
        this.healthInsuranceRef.current.value
      );
      eventAdd.setExtendedProp("note", this.noteRef.current.value);

      var indexOfStatusList = this.statusList.findIndex(
        (obj) => obj === this.statusRef.current.value
      );
      eventAdd.setExtendedProp("status", this.statusRef.current.value);
      eventAdd.setProp("color", this.colorList[indexOfStatusList]);
      if (this.statusRef.current.value != "Post-Consult") {
        eventAdd.setExtendedProp("time", "");
      }

      if (this.statusRef.current.value === "Sleep Test Scheduled") {
        eventAdd.setExtendedProp("latestSleepStudyDate", eventAdd.startStr);
      }

      eventAdd.setExtendedProp("ahi", this.ahiRef.current.value);

      // add email
      eventAdd.setExtendedProp(
        "email",
        eventAdd.extendedProps.email ? eventAdd.extendedProps.email : false
      );

      try {
        if (eventAdd.extendedProps.node != eventOld.extendedProps.node) {
          // console.log("change node");
          // change node
          this.setEvent(eventAdd, eventOld);
          this.setState({ eventTmp: eventAdd });
          this.setState({ isEditEventModal: false });
          this.setState({ isNewPatient: false });
          this.setState({ isNoti: true });
          notifyTopRight("Create success ");
        } else {
          // console.log("same node");
          // same node
          // console.log(this.state.eventTmp.extendedProps.node);
          // console.log(eventAdd.extendedProps.node);
          // console.log(eventOld.extendedProps.node);

          this.setEvent(eventAdd);
          this.setState({ eventTmp: eventAdd });
          this.setState({ isEditEventModal: false });
          this.setState({ isNewPatient: false });
          this.setState({ isNoti: true });
          notifyTopRight("Create success ");
        }
      } catch (err) {
        console.log(err);
        this.setState({ isEditEventModal: false });
        this.setState({ isNewPatient: false });
        // this.setDefaultEvent(eventAdd);
        notifyError("Error, Please fill out the booking form");
        this.onCancel(true);
      }
    } else {
      this.setState({ eventTmp: eventAdd });
      this.setState({ isEditEventModal: false });
      this.setState({ isNewPatient: false });
      this.setState({ isNoti: true });
    }
  };

  setEvent = async (eventAdd, oldEvent = undefined) => {
    if (oldEvent == undefined) {
      oldEvent = eventAdd;
    } else {
      console.log("oldEvent not undefined");
    }
    try {
      if (
        eventAdd.extendedProps.uuid == undefined ||
        eventAdd.extendedProps.uuid == null ||
        eventAdd.extendedProps.node != oldEvent.extendedProps.node
      ) {
        const nodeIndex = this.state.nodeList.indexOf(
          eventAdd.extendedProps.node
        );
        const nodeId = this.state.nodeIdDocs[nodeIndex];
        const ref = doc(collection(db, "nodes", nodeId, "bookings"));
        // console.log("nodeId: ", nodeId);
        await setDoc(ref, {
          title: eventAdd.title,
          id: eventAdd.id,
          start: new Date(eventAdd.startStr),
          end: eventAdd.end,
          color: eventAdd.backgroundColor,
          extendedProps: {
            order: eventAdd.extendedProps.order,
            hn: eventAdd.extendedProps.hn,
            type: eventAdd.extendedProps.type,
            status: eventAdd.extendedProps.status,
            firstname: eventAdd.extendedProps.firstname,
            lastname: eventAdd.extendedProps.lastname,
            nationalId: eventAdd.extendedProps.nationalId,
            healthInsurance: eventAdd.extendedProps.healthInsurance,
            phone: eventAdd.extendedProps.phone,
            node: eventAdd.extendedProps.node,
            customer: eventAdd.extendedProps.customer,
            note: eventAdd.extendedProps.note,
            items: eventAdd.extendedProps.items,
            totalValue: eventAdd.extendedProps.totalValue,
            time: eventAdd.extendedProps.time,
            doctor: eventAdd.extendedProps.doctor,
            referDoctor: eventAdd.extendedProps.referDoctor,
            technician: eventAdd.extendedProps.technician,
            techrecord: eventAdd.extendedProps.techrecord,
            latestSleepStudyDate: eventAdd.extendedProps.latestSleepStudyDate,
            ahi: eventAdd.extendedProps.ahi,
            hospitalNumber: eventAdd.extendedProps.hospitalNumber,
            createBy: eventAdd.extendedProps.createBy,
            gender: eventAdd.extendedProps.gender
              ? eventAdd.extendedProps.gender
              : "",
            email: eventAdd.extendedProps.email
              ? eventAdd.extendedProps.email
              : false,
          },
        });
        eventAdd.setExtendedProp("uuid", ref.id);
        const refUpdate = doc(
          db,
          "nodes",
          nodeId,
          "bookings",
          eventAdd.extendedProps.uuid
        );
        if (
          eventAdd.extendedProps.status == "Sleep Test Scheduled" ||
          eventAdd.extendedProps.status == "Cancel" ||
          eventAdd.extendedProps.status == "Technician Scored"
        ) {
          // this.setNotifyTech(eventAdd);
        }
        await updateDoc(refUpdate, {
          title: eventAdd.title,
          id: eventAdd.id,
          start: new Date(eventAdd.start),
          color: eventAdd.backgroundColor,
          extendedProps: {
            order: eventAdd.extendedProps.order,
            hn: eventAdd.extendedProps.hn,
            type: eventAdd.extendedProps.type,
            status: eventAdd.extendedProps.status,
            uuid: eventAdd.extendedProps.uuid,
            firstname: eventAdd.extendedProps.firstname,
            lastname: eventAdd.extendedProps.lastname,
            nationalId: eventAdd.extendedProps.nationalId,
            healthInsurance: eventAdd.extendedProps.healthInsurance,
            phone: eventAdd.extendedProps.phone,
            node: eventAdd.extendedProps.node,
            customer: eventAdd.extendedProps.customer,
            note: eventAdd.extendedProps.note,
            items: eventAdd.extendedProps.items,
            totalValue: eventAdd.extendedProps.totalValue,
            time: eventAdd.extendedProps.time,
            doctor: eventAdd.extendedProps.doctor,
            referDoctor: eventAdd.extendedProps.referDoctor,
            technician: eventAdd.extendedProps.technician,
            techrecord: eventAdd.extendedProps.techrecord,
            latestSleepStudyDate: eventAdd.extendedProps.latestSleepStudyDate,
            ahi: eventAdd.extendedProps.ahi,
            hospitalNumber: eventAdd.extendedProps.hospitalNumber,
            createBy: eventAdd.extendedProps.createBy,
            gender: eventAdd.extendedProps.gender
              ? eventAdd.extendedProps.gender
              : "",
            email: eventAdd.extendedProps.email
              ? eventAdd.extendedProps.email
              : false,
          },
        });

        if (eventAdd.extendedProps.node != oldEvent.extendedProps.node) {
          const nodeIndexOld = this.state.nodeList.indexOf(
            oldEvent.extendedProps.node
          );
          const nodeIdOld = this.state.nodeIdDocs[nodeIndexOld];

          const refDelete = doc(
            db,
            "nodes",
            // this.nodeName,
            nodeIdOld,
            "bookings",
            oldEvent.extendedProps.uuid
          );
          await deleteDoc(refDelete);
          console.log("deleted from old node !");
          //notify technician
          if (
            eventAdd.extendedProps.status == "Sleep Test Scheduled" ||
            eventAdd.extendedProps.status == "Cancel" ||
            eventAdd.extendedProps.status == "Technician Scored"
          ) {
            // this.setNotifyTech(eventAdd);
          }
          // notify doctor
          if (
            eventAdd.extendedProps.status == "Technician Scored" ||
            eventAdd.extendedProps.status == "Doctor Scored"
          ) {
            this.setNotifyDoctor(eventAdd);
          }
        }
      } else {
        const nodeIndex = this.state.nodeList.indexOf(
          eventAdd.extendedProps.node
        );
        const nodeId = this.state.nodeIdDocs[nodeIndex];
        const refUpdate = doc(
          db,
          "nodes",
          nodeId,
          "bookings",
          eventAdd.extendedProps.uuid
        );

        const docSnap = await getDoc(refUpdate);

        await updateDoc(refUpdate, {
          title: eventAdd.title,
          id: eventAdd.id,
          start: new Date(eventAdd.start),
          color: eventAdd.backgroundColor,
          extendedProps: {
            order: eventAdd.extendedProps.order,
            hn: eventAdd.extendedProps.hn,
            type: eventAdd.extendedProps.type,
            status: eventAdd.extendedProps.status,
            uuid: eventAdd.extendedProps.uuid,
            firstname: eventAdd.extendedProps.firstname,
            lastname: eventAdd.extendedProps.lastname,
            nationalId: eventAdd.extendedProps.nationalId,
            healthInsurance: eventAdd.extendedProps.healthInsurance,
            phone: eventAdd.extendedProps.phone,
            node: eventAdd.extendedProps.node,
            customer: eventAdd.extendedProps.customer,
            note: eventAdd.extendedProps.note,
            items: eventAdd.extendedProps.items,
            totalValue: eventAdd.extendedProps.totalValue,
            time: eventAdd.extendedProps.time,
            doctor: eventAdd.extendedProps.doctor,
            referDoctor: eventAdd.extendedProps.referDoctor,
            technician: eventAdd.extendedProps.technician,
            techrecord: eventAdd.extendedProps.techrecord,
            latestSleepStudyDate:
              eventAdd.extendedProps.latestSleepStudyDate !== undefined
                ? eventAdd.extendedProps.latestSleepStudyDate
                : docSnap.data().extendedProps.latestSleepStudyDate,
            ahi: eventAdd.extendedProps.ahi,
            hospitalNumber: eventAdd.extendedProps.hospitalNumber,
            ahi: eventAdd.extendedProps.ahi,
            hospitalNumber: eventAdd.extendedProps.hospitalNumber,
            createBy: eventAdd.extendedProps.createBy,
            gender: eventAdd.extendedProps.gender
              ? eventAdd.extendedProps.gender
              : "",
            email: eventAdd.extendedProps.email
              ? eventAdd.extendedProps.email
              : false,
          },
        });
        //notify technician
        if (
          eventAdd.extendedProps.status == "Sleep Test Scheduled" ||
          eventAdd.extendedProps.status == "Cancel" ||
          eventAdd.extendedProps.status == "Technician Scored"
        ) {
          // this.setNotifyTech(eventAdd);
        }
        // notify doctor
        if (
          eventAdd.extendedProps.status == "Technician Scored" ||
          eventAdd.extendedProps.status == "Doctor Scored"
        ) {
          this.setNotifyDoctor(eventAdd);
        }
      }
    } catch (err) {
      console.log(err);
      notifyError("Please fill out patient information !");

      let api = this.calendarComponentRef.current.getApi();
      api.addEvent({
        title: oldEvent.title,
        start: oldEvent.startStr,
        id: oldEvent.id,
        allDay: true,
        color: "brown",
        extendedProps: {
          order: oldEvent.extendedProps.order,
          hn: oldEvent.extendedProps.hn,
          type: oldEvent.extendedProps.type,
          status: oldEvent.extendedProps.status,
          firstname: oldEvent.extendedProps.firstname,
          lastname: oldEvent.extendedProps.lastname,
          nationalId: oldEvent.extendedProps.nationalId,
          healthInsurance: oldEvent.extendedProps.healthInsurance,
          phone: oldEvent.extendedProps.phone,
          note: oldEvent.extendedProps.note,
          node: oldEvent.extendedProps.node,
          customer: oldEvent.extendedProps.customer,
          items: oldEvent.extendedProps.items,
          totalValue: oldEvent.extendedProps.totalValue,
          time: oldEvent.extendedProps.time,
          doctor: oldEvent.extendedProps.doctor,
          referDoctor: oldEvent.extendedProps.referDoctor,
          technician: oldEvent.extendedProps.technician,
          techrecord: oldEvent.extendedProps.techrecord,
          ahi: oldEvent.extendedProps.ahi,
          hospitalNumber: oldEvent.extendedProps.hospitalNumber,
          gender: oldEvent.extendedProps.gender
            ? oldEvent.extendedProps.gender
            : "",

          createBy: oldEvent.extendedProps.createBy
            ? oldEvent.extendedProps.createBy
            : "",
          eventAdd: oldEvent.extendedProps.gender
            ? oldEvent.extendedProps.gender
            : "",
          email: oldEvent.extendedProps.email
            ? oldEvent.extendedProps.email
            : false,
        },
      });
      eventAdd.remove();
    }
  };
  setDefaultEvent = (eventInput) => {
    let api = this.calendarComponentRef.current.getApi();
    api.addEvent({
      title: eventInput.title,
      start: eventInput.startStr,
      id: eventInput.id,
      allDay: true,
      color: "brown",
      extendedProps: {
        order: eventInput.extendedProps.order,
        hn: eventInput.extendedProps.hn,
        type: eventInput.extendedProps.type,
        status: eventInput.extendedProps.status,
        firstname: eventInput.extendedProps.firstname,
        lastname: eventInput.extendedProps.lastname,
        nationalId: eventInput.extendedProps.nationalId,
        healthInsurance: eventInput.extendedProps.healthInsurance,
        phone: eventInput.extendedProps.phone,
        note: eventInput.extendedProps.note,
        node: eventInput.extendedProps.node,
        customer: eventInput.extendedProps.customer,
        items: eventInput.extendedProps.items,
        totalValue: eventInput.extendedProps.totalValue,
        time: eventInput.extendedProps.time,
        doctor: eventInput.extendedProps.doctor,
        referDoctor: eventInput.extendedProps.referDoctor,
        technician: eventInput.extendedProps.technician,
        techrecord: eventInput.extendedProps.techrecord,
        ahi: eventInput.extendedProps.ahi,
        hospitalNumber: eventInput.extendedProps.hospitalNumber,
        createBy: eventInput.extendedProps.createBy
          ? eventInput.extendedProps.createBy
          : "",
        gender: eventInput.extendedProps.gender
          ? eventInput.extendedProps.gender
          : "",
        email: eventInput.extendedProps.email
          ? eventInput.extendedProps.email
          : false,
      },
    });
    eventInput.remove();
  };
  setNotifyDoctor = async (event) => {
    const sleepstudyInfo = {
      title: event.title,
      date: event.startStr,
      status: event.extendedProps.status,
      type: event.extendedProps.type,
      firstname: event.extendedProps.firstname,
      lastname: event.extendedProps.lastname,
      doctor: event.extendedProps.doctor,
      note: event.extendedProps.note,
      node: event.extendedProps.node,
      latestSleepStudyDate: event.extendedProps.latestSleepStudyDate,
      createBy: event.extendedProps.createBy,
    };
    const statusToCheck = sleepstudyInfo.status;
    const titleToCheck = sleepstudyInfo.title;
    const currentDoctorId = sleepstudyInfo.doctor; // Assuming this contains the current doctor's ID

    // Create a collection group query to find sleep study cases with the same title
    const matchingTitlesQuery = query(
      collectionGroup(db, "sleepStudyCases"),
      where("title", "==", titleToCheck)
    );
    const querySnapshot = await getDocs(matchingTitlesQuery);
    if (statusToCheck == "Doctor Scored") {
      if (querySnapshot.empty) {
        console.log("no matching documents found !");
      } else {
        for (const doc of querySnapshot.docs) {
          // Delete the document
          await deleteDoc(doc.ref);
          console.log(`Deleted sleep study case with title "${titleToCheck}" `);
        }
      }
    } else if (statusToCheck == "Technician Scored") {
      if (querySnapshot.empty) {
        console.log("no matching documents found !");
      } else {
        for (const doc of querySnapshot.docs) {
          // Check if the document is under a different doctor
          const docDoctorId = doc.ref.path.split("/")[1]; // Extracting doctor ID from the document reference path
          console.log("currentId: ", currentDoctorId);
          console.log("docDoctorId: ", docDoctorId);
          if (docDoctorId !== currentDoctorId) {
            // Delete the document
            await deleteDoc(doc.ref);
            console.log(
              `Deleted sleep study case with title "${titleToCheck}" under a different doctor (ID: ${docDoctorId}).`
            );
          }
        }
      }
      const DoctorsQuery = query(
        collection(db, "doctors"),
        where("name", "==", sleepstudyInfo.doctor)
      );
      const DoctorDocuments = await getDocs(DoctorsQuery);
      var DoctorList = [];
      DoctorDocuments.forEach(async (document) => {
        const doctorId = document.id;
        const doctorInfo = document.data();
        // console.log(doctorId)
        console.log(doctorInfo);

        const sleepStudyCasesCollectionRef = doc(
          collection(db, `doctors/${doctorId}/sleepStudyCases`)
        );
        await setDoc(sleepStudyCasesCollectionRef, sleepstudyInfo);
        const message = `\n${sleepstudyInfo.title} : ${sleepstudyInfo.firstname} ${sleepstudyInfo.lastname} \nStudy Date : ${sleepstudyInfo.latestSleepStudyDate} \n${sleepstudyInfo.type} \n${sleepstudyInfo.status} \nBy: ${sleepstudyInfo.createBy}`;
        const accessToken = doctorInfo.accessToken;
        console.log("querySnapshot: ", querySnapshot);
        if (querySnapshot.empty) {
          console.log("send noti");
          this.sendNotification({ accessToken, message });
        }
      });
    }
  };

  setNotifyTech = async (event) => {
    const sleepstudyInfo = {
      title: event.title,
      date: event.start.toISOString().slice(0, 10),
      status: event.extendedProps.status,
      type: event.extendedProps.type,
      firstname: event.extendedProps.firstname,
      lastname: event.extendedProps.lastname,
      techrecord: event.extendedProps.techrecord,
      note: event.extendedProps.note,
      node: event.extendedProps.node,
      customer: event.extendedProps.customer,
      healthInsurance: event.extendedProps.healthInsurance,
      phone: event.extendedProps.phone,
    };
    const statusToCheck = sleepstudyInfo.status;
    const titleToCheck = sleepstudyInfo.title;
    const currentTechId = sleepstudyInfo.techrecord; // Assuming this contains the current doctor's ID
    //Create a collection group query to find sleep study cases with the same title
    const matchingTitlesQuery = query(
      collectionGroup(db, "sleepStudyCases"),
      where("title", "==", titleToCheck)
    );
    const querySnapshot = await getDocs(matchingTitlesQuery);
    if (statusToCheck == "Technician Scored") {
      if (querySnapshot.empty) {
        console.log("no matching documents found !");
      } else {
        for (const doc of querySnapshot.docs) {
          // Delete the document
          await deleteDoc(doc.ref);
          console.log(`Deleted sleep study case with title "${titleToCheck}" `);
        }
      }
    } else if (statusToCheck == "Sleep Test Scheduled") {
      if (querySnapshot.empty) {
        console.log("no matching documents found !");
      } else {
        for (const doc of querySnapshot.docs) {
          // Check if the document is under a different doctor
          const docTechId = doc.ref.path.split("/")[1]; // Extracting doctor ID from the document reference path
          if (docTechId !== currentTechId) {
            // Delete the document
            await deleteDoc(doc.ref);
            console.log(
              `Deleted sleep study case with title "${titleToCheck}" under a different doctor (ID: ${docTechId}).`
            );
          }
        }
      }
      const TechQuery = query(
        collection(db, "sleeptechnicians"),
        where("name", "==", sleepstudyInfo.techrecord)
      );
      const TechDocuments = await getDocs(TechQuery);
      var TechList = [];
      TechDocuments.forEach(async (document) => {
        const techId = document.id;
        const techInfo = document.data();

        const sleepStudyCasesCollectionRef = doc(
          collection(db, `sleeptechnicians/${techId}/sleepStudyCases`)
        );
        await setDoc(sleepStudyCasesCollectionRef, sleepstudyInfo);
        const message = `\nเคสตรวจการนอนหลับที่ ${sleepstudyInfo.customer} \nStudy Date: ${sleepstudyInfo.date}\nSleep Technician: ${sleepstudyInfo.techrecord}
         \n${sleepstudyInfo.title}: ${sleepstudyInfo.firstname} ${sleepstudyInfo.lastname} \nTel: ${sleepstudyInfo.phone} \nHealth Insurance: ${sleepstudyInfo.healthInsurance} \n${sleepstudyInfo.type} \n${sleepstudyInfo.status}`;
        const accessToken = techInfo.accessToken;
        if (querySnapshot.empty) {
          this.sendNotification({ accessToken, message });
        }
      });
    } else if (statusToCheck == "Cancel") {
      for (const doc of querySnapshot.docs) {
        // Delete the document
        await deleteDoc(doc.ref);
        console.log(`Deleted sleep study case with title "${titleToCheck}" `);
      }
      const TechQuery = query(
        collection(db, "sleeptechnicians"),
        where("name", "==", sleepstudyInfo.techrecord)
      );
      const TechDocuments = await getDocs(TechQuery);
      TechDocuments.forEach(async (document) => {
        const techInfo = document.data();
        const accessToken = techInfo.accessToken;
        const message =
          "Cancel เคสตรวจการนอนหลับที่ " +
          sleepstudyInfo.customer +
          "\nวันที่ " +
          sleepstudyInfo.date +
          "\n" +
          sleepstudyInfo.title +
          ": " +
          sleepstudyInfo.firstname +
          " " +
          sleepstudyInfo.lastname;
        this.sendNotification({ accessToken, message });
      });
    }
  };

  eventDrop = (eventDrop) => {
    this.setState({ eventTmp: eventDrop.event });
    if (this.state.eventTmp.extendedProps.hn == "") {
      this.setState({ isEditBookingDetail: true });
    }
    this.setState({ isAddEventModal: true });
    this.setState({ isNewPatient: false });
  };

  eventDrag = (eventDrag) => {
    let api = this.calendarComponentRef.current.getApi();
    var allEvents = api.getEvents().filter((obj) => {
      return (
        obj.startStr === eventDrag.event.startStr && obj.extendedProps.hn !== ""
      );
    });
    let listHNEvent = allEvents.filter((element) => {
      return element.extendedProps.hn === eventDrag.event.extendedProps.hn;
    });
    // let isExists = false;
    // if (listHNEvent.length > 1) {
    //   isExists = true;
    // }
    if (listHNEvent.length > 1) {
      swal({
        title: `HN${eventDrag.event.extendedProps.hn} is already booking on this day !`,
        text: `Please recheck booking on ${eventDrag.event.startStr}`,
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      }).then((willBooking) => {
        if (willBooking) {
          this.setState({ eventTmp: eventDrag.event });
          this.setEvent(this.state.eventTmp, eventDrag.oldEvent);
        } else {
          var colorEvent = "";
          var indexOfStatusList = this.statusList.findIndex(
            (obj) => obj === eventDrag.oldEvent.extendedProps.status
          );
          colorEvent = this.colorList[indexOfStatusList];
          api.addEvent({
            title: eventDrag.oldEvent.title,
            start: eventDrag.oldEvent.startStr,
            id: eventDrag.oldEvent.id,
            allDay: true,
            color: colorEvent,
            extendedProps: {
              order: eventDrag.oldEvent.extendedProps.order,
              hn: eventDrag.oldEvent.extendedProps.hn,
              type: eventDrag.oldEvent.extendedProps.type,
              status: eventDrag.oldEvent.extendedProps.status,
              firstname: eventDrag.oldEvent.extendedProps.firstname,
              lastname: eventDrag.oldEvent.extendedProps.lastname,
              nationalId: eventDrag.oldEvent.extendedProps.nationalId,
              healthInsurance: eventDrag.oldEvent.extendedProps.healthInsurance,
              phone: eventDrag.oldEvent.extendedProps.phone,
              note: eventDrag.oldEvent.extendedProps.note,
              node: eventDrag.oldEvent.extendedProps.node,
              customer: eventDrag.oldEvent.extendedProps.customer,
              items: eventDrag.oldEvent.extendedProps.items,
              totalValue: eventDrag.oldEvent.extendedProps.totalValue,
              time: eventDrag.oldEvent.extendedProps.time,
              doctor: eventDrag.oldEvent.extendedProps.doctor,
              referDoctor: eventDrag.oldEvent.extendedProps.referDoctor,
              technician: eventDrag.oldEvent.extendedProps.technician,
              techrecord: eventDrag.oldEvent.extendedProps.techrecord,
              ahi: eventDrag.oldEvent.extendedProps.ahi,
              hospitalNumber: eventDrag.oldEvent.extendedProps.hospitalNumber,
              createBy: eventDrag.oldEvent.extendedProps.createBy
                ? eventDrag.oldEvent.extendedProps.createBy
                : "",
              gender: eventDrag.oldEvent.extendedProps.gender
                ? eventDrag.oldEvent.extendedProps.gender
                : "",
              email: eventDrag.oldEvent.extendedProps.email
                ? eventDrag.oldEvent.extendedProps.email
                : false,
            },
          });
          eventDrag.event.remove();
        }
      });
    } else {
      this.setState({ eventTmp: eventDrag.event });
      this.setEvent(this.state.eventTmp, eventDrag.oldEvent);
    }
  };

  CustomerList = (nodename) => {
    const index = this.state.nodeList.indexOf(nodename);
    return this.state.customerAllList[index];
  };

  eventClick = async (eventClick) => {
    //get report
    const docRef = collection(
      db,
      "patients",
      eventClick.event.extendedProps.hn,
      "report"
    );

    const docSnap = await getDocs(docRef).then((snapshot) => {
      const reports = [];
      snapshot.forEach(async (childSnapshot) => {
        reports.push({
          id: childSnapshot.id,
          ...childSnapshot.data(),
        });
      });

      if (reports.length > 0) {
        eventClick.event.setExtendedProp("reports", reports);
      } else {
        eventClick.event.setExtendedProp("reports", null);
      }

      this.setState({ eventTmp: eventClick.event }, () => {
        this.setState({ dateTmp: this.state.eventTmp.startStr });
        this.setState({ resetDateTmp: this.state.eventTmp.startStr });
      });
      this.setState({
        customerList: this.CustomerList(eventClick.event.extendedProps.node),
      });

      if (this.state.eventTmp.extendedProps.hn == "") {
        this.setState({ isEditBookingDetail: true });
      }
      if (this.state.eventTmp.extendedProps.status == "Post-Consult") {
        this.setState({ isDatetime: true });
      } else {
        this.setState({ isDatetime: false });
      }
      this.setState({ isEditEventModal: true });
      this.setState({ isNewPatient: false });
      const itemsTmp = JSON.parse(
        JSON.stringify(this.state.eventTmp.extendedProps.items)
      );
      const totalValueTmp = this.state.eventTmp.extendedProps.totalValue;
      this.setState({ items: itemsTmp });
      this.setState({ totalValue: totalValueTmp });
    });
    const customersRef = collectionGroup(
      db,
      "customers"
      // eventClick.event.extendedProps.customer
    );
    const customerSnap = await getDocs(customersRef);
    // console.log(customerSnap)

    if (!customerSnap.empty) {
      let found = false;
      customerSnap.forEach((customer) => {
        // console.log("customer data", customer.data());
        if (customer.id === eventClick.event.extendedProps.customer) {
          console.log("customer data", customer.data());

          const customerData = customer.data();
          const customerEmail = customerData.emailList;
          console.log("customerEmail: ", customerEmail);

          if (customerEmail) {
            this.setState({ emailList: customerEmail });
          } else {
            this.setState({ emailList: [] });
          }

          found = true;
          return; // Exit the forEach loop once the matching customer is found
        }
      });

      if (!found) {
        this.setState({ emailList: [] });
      }
    } else {
      this.setState({ emailList: [] });
    }

    const mailRef = collection(db, "mail");
    const querySnapshot = query(
      mailRef,
      where("order", "==", this.state.eventTmp.extendedProps.order),
      where("hn", "==", this.state.eventTmp.extendedProps.hn),
      orderBy("delivery.endTime", "desc")
    );
    const mailSnapshot = await getDocs(querySnapshot);

    if (!mailSnapshot.empty) {
      // Access the first document in the snapshot (index 0)
      const firstMail = mailSnapshot.docs[0];
      const mailData = firstMail.data();
      const endTimeDate = mailData.delivery.endTime.toDate();

      // Extract day, month, and year
      const day = String(endTimeDate.getDate()).padStart(2, "0");
      const month = String(endTimeDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
      const year = endTimeDate.getFullYear();

      const formattedDate = `${year}-${month}-${day}`;

      // Update eventTmp with the latest mail info
      var eventData = this.state.eventTmp;
      eventData.setExtendedProp("email", true);
      eventData.setExtendedProp("lastestMail", formattedDate);
      console.log(mailData);

      // Update the state with the modified event
      this.setState({ eventTmp: eventData });
    }
    // if (customerSnap.exists()) {
    //   const customerData = customerSnap.data();
    //   // You can access specific fields like this:
    //   const customerEmail = customerData.emailList;
    //   if (customerEmail) {
    //     this.setState({ emailList: customerEmail });
    //   } else {
    //     this.setState({ emailList: [] });
    //   }
    //   // And so on...
    // } else {
    //   this.setState({ emailList: [] });
    // }
    // console.log(this.state.eventTmp);
  };

  onCancel = (isClear = false) => {
    this.setState({ isEditBookingDetail: false });
    // this.setState({ isEditBookingDetail: true });
    let evtmp = this.state.eventTmp;
    if (isClear) {
      evtmp.setProp("title", this.state.eventTmp.extendedProps.order);
      evtmp.setProp("id", this.state.eventTmp.id);
      evtmp.setExtendedProp("order", this.state.eventTmp.extendedProps.order);
      evtmp.setExtendedProp("hn", "");
      evtmp.setExtendedProp("firstname", "");
      evtmp.setExtendedProp("lastname", "");
      evtmp.setExtendedProp("nationalId", "");
      evtmp.setExtendedProp("healthInsurance", "กรุณาเลือก");
      evtmp.setExtendedProp("phone", "");
      evtmp.setProp("color", "green");
    }

    evtmp.setStart(new Date(this.state.resetDateTmp));
    evtmp.setEnd(new Date(this.state.resetDateTmp).getDate() + 1);
    this.setState({ eventTmp: evtmp });
  };
  onUpdateItemValue = (key, index, value) => {
    var tmpItems = this.state.items;
    tmpItems[index][key] = value.target.value;
    tmpItems[index].total =
      tmpItems[index].unitcost * tmpItems[index].qty - tmpItems[index].discount;

    this.setState({ items: tmpItems }, () => {
      this.getTotalValue();
    });
  };
  getTotalValue = () => {
    var totalValueTmp = 0;
    var tmpItems = this.state.items;
    for (let i = 0; i < tmpItems.length; i++) {
      totalValueTmp = parseInt(totalValueTmp) + parseInt(tmpItems[i].total);
    }
    this.setState({ totalValue: totalValueTmp });
  };
  checkStatus = (e) => {
    if (e.target.value == "Post-Consult") {
      this.setState({ isDatetime: true });
    } else {
      this.setState({ isDatetime: false });
    }
  };

  handleOnClick = (hn, id, file) => {
    this.getSignedUrl({
      folder: "reports",
      hn,
      id,
      filename: file,
    })
      .then((result) => {
        // console.log("url", result.data);
        this.setState({ fileUrl: result.data });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ createModal: true });
  };

  handleButtonClick = () => {
    this.fileInputRef.current.click();
  };

  // Function to handle file selection
  handleFileChange = (e) => {
    console.log("file change");
    // const file = event.target.files[0];
    // console.log('Selected file:', file.name);  // Process the file as needed
    let files = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const f = e.target.files[i];
      files.push(f);
      // setSelectedFiles((prevState) => [...prevState, f]);
      // setFilenames((prevState) => [...prevState, f.name]);
    }
    const report = {
      hn: this.state.eventTmp.extendedProps.hn,
      createBy: this.context.currentUser.displayName,
      createAt: new Date(),
    };
    this.startAddReport(report, files);
  };

  handleSendEmail = (event) => {
    // console.log(event);
    this.setState({ modalEmail: true });
  };

  startAddReport = async (reportData = {}, files, type = "report") => {
    console.log("start add report");
    const { hn, createBy, createAt } = reportData;
    const report = { hn, createBy, createAt };
    console.log("report: ", report);

    const docRef = doc(collection(db, "patients", report.hn, type));
    const docId = docRef.id;
    console.log("docRef: ", docRef);

    await setDoc(docRef, report)
      .then(async () => {
        console.log("docRef inside: ", docRef);

        const promises = [];
        const f = [];
        files.map((file) => {
          const storageRef = ref(
            storage,
            `${type}s/${report.hn}/${docId}/${file.name}`
          );
          f.push(file.name);
          const uploadTask = uploadBytes(storageRef, file);
          promises.push(uploadTask);
        });
        await Promise.all(promises);
        await updateDoc(doc(db, "patients", report.hn, type, docId), {
          reportFile: f,
        });

        var eventTmp = this.state.eventTmp;
        const collectionRef = collection(
          db,
          "patients",
          eventTmp.extendedProps.hn,
          "report"
        );
        const docSnap = await getDocs(collectionRef).then((snapshot) => {
          const reports = [];
          snapshot.forEach(async (childSnapshot) => {
            reports.push({
              id: childSnapshot.id,
              ...childSnapshot.data(),
            });
          });

          if (reports.length > 0) {
            eventTmp.setExtendedProp("reports", reports);
          } else {
            eventTmp.setExtendedProp("reports", null);
          }
          this.setState({ eventTmp: eventTmp });
        });

        console.log("UPLOAD SUCCESSFULLY !");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <Fragment>
        <Toast />
        <Modal
          className="fade bd-example-modal-sm"
          show={this.state.modalEmail}
          onHide={() => this.setState({ modalEmail: false, emailList: [] })}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-5">
            <this.PatientTextInfo
              title="Order"
              value={this.state.eventTmp.extendedProps.order}
              bold={true}
            />
            <this.PatientTextInfo
              title="Full Name"
              value={
                this.state.eventTmp.extendedProps.firstname +
                " " +
                this.state.eventTmp.extendedProps.lastname
              }
              bold={true}
            />
            <this.PatientDropDownForm
              title="Email"
              id="type"
              refvalue={this.currentEmailRef}
              // selected={this.state.eventTmp.extendedProps.type}
              options={this.state.emailList}
            ></this.PatientDropDownForm>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button
              variant=""
              type="button"
              className="btn btn-info"
              onClick={() => {
                swal({
                  title: `Are you sure to send email to ${this.currentEmailRef.current.value} ?`,
                  text: `${this.state.eventTmp.extendedProps.order} ${this.state.eventTmp.extendedProps.firstname} ${this.state.eventTmp.extendedProps.lastname}`,
                  icon: "warning",
                  buttons: ["Cancel", "Confirm"],
                  dangerMode: true,
                }).then(async (willSendEmail) => {
                  if (willSendEmail) {
                    console.log("SENT EMAIL HERE!");
                    // ***********************
                    // this.sleepStudyReportMail({
                    //   to: "pisanu@braindynamics.co.th",
                    //   subject: "test123",
                    //   text: "test567",
                    // });
                    // console.log("eventTmp: ",this.state.eventTmp.extendedProps)
                    const fullName = `${this.state.eventTmp.extendedProps.firstname} ${this.state.eventTmp.extendedProps.lastname}`;
                    const order = this.state.eventTmp.extendedProps.order;
                    const customer = this.state.eventTmp.extendedProps.customer;
                    const hn = this.state.eventTmp.extendedProps.hn;
                    const refHn = this.state.eventTmp.extendedProps.refHn
                      ? this.state.eventTmp.extendedProps.refHn
                      : "";
                    const latestSS = this.state.eventTmp.extendedProps
                      .latestSleepStudyDate
                      ? this.state.eventTmp.extendedProps.latestSleepStudyDate
                      : "";
                    const mailTest = {
                      to: this.state.emailList,
                      message: {
                        subject: `Sleep Study Report ${order}`,
                        html: `<p>เรียน ${customer},</p>
                        <p>${order}<br>
                        ${fullName}<br>
                        ${refHn}<br>
                        ตรวจเมื่อวันที่ ${latestSS}</p>
                        <p>ดูผลได้ที่ https://study.bdsleep.tech/patient-page/${hn}<p>
                        <p>ขอแสดงความนับถือ,<br>
                        BDSleep</p>`,
                      },
                      order: order,
                      fullName: fullName,
                      refHn: refHn,
                      sleepStudyDate: latestSS,
                      hn: hn,
                    };
                    const endTimeDate = new Date();

                    // Extract day, month, and year
                    const day = String(endTimeDate.getDate()).padStart(2, "0");
                    const month = String(endTimeDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    ); // Months are zero-based, so add 1
                    const year = endTimeDate.getFullYear();

                    const formattedDate = `${year}-${month}-${day}`;

                    const mailRef = doc(collection(db, "mail"));
                    const mail = await setDoc(mailRef, mailTest);
                    // ***********************
                    var eventTmpData = this.state.eventTmp;
                    eventTmpData.setExtendedProp("email", true);
                    eventTmpData.setExtendedProp("lastestMail", formattedDate);
                    // console.log(eventTmpData)

                    this.setEvent(eventTmpData);

                    this.setState({ eventTmp: eventTmpData });
                    this.setState({ modalEmail: false, emailList: [] });
                  }
                });
              }}
            >
              Send All Email
            </Button>
            <div>
              <Button
                variant="light"
                onClick={() =>
                  this.setState({ modalEmail: false, emailList: [] })
                }
              >
                Cancel
              </Button>
              <Button
                variant=""
                type="button"
                className="btn btn-danger ml-3"
                onClick={() => {
                  swal({
                    title: `Are you sure to send email to ${this.currentEmailRef.current.value} ?`,
                    text: `${this.state.eventTmp.extendedProps.order} ${this.state.eventTmp.extendedProps.firstname} ${this.state.eventTmp.extendedProps.lastname}`,
                    icon: "warning",
                    buttons: ["Cancel", "Confirm"],
                    dangerMode: true,
                  }).then(async (willSendEmail) => {
                    if (willSendEmail) {
                      console.log("SENT EMAIL HERE!");
                      // ***********************
                      // this.sleepStudyReportMail({
                      //   to: "pisanu@braindynamics.co.th",
                      //   subject: "test123",
                      //   text: "test567",
                      // });
                      // console.log("eventTmp: ",this.state.eventTmp.extendedProps)
                      const fullName = `${this.state.eventTmp.extendedProps.firstname} ${this.state.eventTmp.extendedProps.lastname}`;
                      const order = this.state.eventTmp.extendedProps.order;
                      const customer =
                        this.state.eventTmp.extendedProps.customer;
                      const hn = this.state.eventTmp.extendedProps.hn;
                      const refHn = this.state.eventTmp.extendedProps.refHn
                        ? this.state.eventTmp.extendedProps.refHn
                        : "";
                      const latestSS = this.state.eventTmp.extendedProps
                        .latestSleepStudyDate
                        ? this.state.eventTmp.extendedProps.latestSleepStudyDate
                        : "";
                      const mailTest = {
                        to: this.currentEmailRef.current.value,
                        message: {
                          subject: `Sleep Study Report ${order}`,
                          html: `<p>เรียน ${customer},</p>
                        <p>${order}<br>
                        ${fullName}<br>
                        ${refHn}<br>
                        ตรวจเมื่อวันที่ ${latestSS}</p>
                        <p>ดูผลได้ที่ https://study.bdsleep.tech/patient-page/${hn}<p>
                        <p>ขอแสดงความนับถือ,<br>
                        BDSleep</p>`,
                        },
                        order: order,
                        fullName: fullName,
                        refHn: refHn,
                        sleepStudyDate: latestSS,
                        hn: hn,
                      };
                      const endTimeDate = new Date();

                      // Extract day, month, and year
                      const day = String(endTimeDate.getDate()).padStart(
                        2,
                        "0"
                      );
                      const month = String(endTimeDate.getMonth() + 1).padStart(
                        2,
                        "0"
                      ); // Months are zero-based, so add 1
                      const year = endTimeDate.getFullYear();

                      const formattedDate = `${year}-${month}-${day}`;

                      const mailRef = doc(collection(db, "mail"));
                      const mail = await setDoc(mailRef, mailTest);
                      // ***********************
                      var eventTmpData = this.state.eventTmp;
                      eventTmpData.setExtendedProp("email", true);
                      eventTmpData.setExtendedProp(
                        "lastestMail",
                        formattedDate
                      );
                      // console.log(eventTmpData)

                      this.setEvent(eventTmpData);

                      this.setState({ eventTmp: eventTmpData });
                      this.setState({ modalEmail: false, emailList: [] });
                    }
                  });
                }}
              >
                Send Email
              </Button>
            </div>
          </Modal.Footer>{" "}
        </Modal>
        <Modal
          className="fade bd-example-modal-lg"
          show={this.state.createModal}
          onHide={() => this.setState({ createModal: false })}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>Report</Modal.Title>
            <Button
              variant=""
              className="close"
              onClick={() => this.setState({ createModal: false })}
            >
              {" "}
              <span>&times;</span>
            </Button>
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div
              className="card-body mx-0 p-0 col-lg-12"
              style={{ height: "85vh" }}
            >
              <iframe
                className="col-lg-12 m-0 p-0"
                src={this.state.fileUrl + "#toolbar=0"}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <div className="animated fadeIn demo-app ">
          <Row>
            {!this.state.isLoading ? (
              <Col xl={10}>
                <Card>
                  {" "}
                  {["super_admin", "sale"].includes(
                    this.context.currentUser.roles[0]
                  ) && (
                    <div className="d-flex justify-content-center align-items-center p-2">
                      <div
                        className="mx-2 me-5"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label
                          htmlFor="node1"
                          style={{
                            marginRight: "10px", // Adjusts space between label and select
                            textAlign: "center",
                          }}
                        >
                          Node
                        </label>
                        <select
                          className="form-control text-center"
                          id="node1"
                          name="node1"
                          ref={this.nodeSelectRef}
                          onChange={this.onChangeNode}
                          style={{
                            textAlignLast: "center",
                            paddingTop: "0.25rem",
                          }}
                        >
                          {[
                            "super_admin",
                            "sale",
                            "sleep_technician",
                            "sleep_physician",
                          ].includes(this.context.currentUser.roles[0]) && (
                            <option value={"All Node"}>{"All Node"}</option>
                          )}
                          {this.state.nodeList.map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                      {["super_admin", "sale"].includes(
                        this.context.currentUser.roles[0]
                      ) && (
                        <div
                          className="mx-2"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="node1"
                            style={{
                              marginRight: "10px", // Adjusts space between label and select
                              textAlign: "center",
                            }}
                          >
                            Technician
                          </label>
                          <select
                            className="form-control text-center"
                            id="node2"
                            name="node2"
                            ref={this.techSelectRef}
                            onChange={this.onChangeTech}
                          >
                            {/* {["super_admin"].includes(
                            this.context.currentUser.roles[0]
                          ) && <option value={"All Tech"}>{"All Tech"}</option>} */}
                            {this.state.technicianList.map((value) => (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  )}
                  <Card.Body
                    className="border p-2 my-0"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="demo-app-calendar" id="mycalendartest">
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          interactionPlugin,
                          // listPlugin,
                          multiMonthPlugin,
                        ]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                          center: "title",
                          left: "dayGridWeek,dayGridMonth,multiMonthYear",
                          right: "prev,next today",
                        }}
                        // ,listMonth
                        rerenderDelay={10}
                        eventDurationEditable={false}
                        editable={false}
                        droppable={false}
                        ref={this.calendarComponentRef}
                        weekends={this.state.calendarWeekends}
                        eventDrop={this.eventDrag}
                        eventReceive={this.eventDrop}
                        eventClick={this.eventClick}
                        events={this.state.calendarEvents}
                        eventOrder={(a, b) => {
                          const statusA = a.extendedProps.status;
                          const statusB = b.extendedProps.status;

                          return (
                            (this.statusOrder[statusA] || 99) -
                            (this.statusOrder[statusB] || 99)
                          );
                        }}
                        // // Get the event description from the event object
                        // const description = info.event.extendedProps.description;

                        // // Create a new element for the event title and description
                        // const titleElement = document.createElement("div");
                        // titleElement.classList.add("fc-title");
                        // titleElement.classList.add("left-align"); // Add the CSS class for text alignment

                        // // Create a <span> element to wrap the description for bold styling
                        // const descriptionElement = document.createElement("span");
                        // descriptionElement.classList.add("bold-description");
                        // descriptionElement.innerText = description;

                        // // Set the innerHTML of the titleElement with the title and the wrapped description
                        // titleElement.innerHTML = `${info.event.title}<br>${descriptionElement.outerHTML}`;

                        // return { domNodes: [titleElement] };

                        eventContent={function (info) {
                          // console.log(info.event.extendedProps);

                          var fullname =
                            info.event.extendedProps.firstname +
                            " " +
                            info.event.extendedProps.lastname;
                          var type = info.event.extendedProps.type;
                          var status = info.event.extendedProps.status;
                          var total = info.event.extendedProps.totalValue;
                          var time = info.event.extendedProps.time;
                          var note = info.event.extendedProps.note;
                          var order = info.event.extendedProps.order;
                          var stech = info.event.extendedProps.technician;
                          var rtech = info.event.extendedProps.techrecord;
                          var doctor = info.event.extendedProps.doctor;
                          var referDoctor =
                            info.event.extendedProps.referDoctor;
                          var email = info.event.extendedProps.email; // New email variable

                          var typeSymbol = "";
                          if (type === "Split Night Sleep Study") {
                            typeSymbol = "[SN]";
                          } else if (type === "Full Night Sleep Study") {
                            typeSymbol = "[FN]";
                          } else if (type === "Home Full Night Sleep Study") {
                            typeSymbol = "[HN]";
                          } else if (
                            type === "CPAP Titration" ||
                            type === "CPAP Titration Study"
                          ) {
                            typeSymbol = "[CT]";
                          }

                          // var txt = `${fullname}\n${type}`;
                          var txt = `${fullname}`;

                          var description = "";
                          if (type !== "" || type !== "กรุณาเลือก") {
                            description = description + `${type}`;
                          }
                          if (
                            rtech !== "" ||
                            rtech !== "กรุณาเลือก" ||
                            rtech !== "undefined" ||
                            !rtech
                          ) {
                            description = description + "\nRT: " + `${rtech}`;
                          }
                          if (
                            stech !== "" ||
                            stech !== "กรุณาเลือก" ||
                            stech !== "undefined"
                          ) {
                            description = description + "\nST: " + `${stech}`;
                          }
                          if (doctor !== "" || doctor !== "กรุณาเลือก") {
                            description = description + "\nD: " + `${doctor}`;
                          }
                          if (referDoctor !== "") {
                            description =
                              description + "\nR: " + `${referDoctor}`;
                          }
                          if (note !== "") {
                            description = description + `\nN: ${note}`;
                          }
                          if (total == 0) {
                            description = description + "\n฿ -";
                          } else {
                            description = description + `\n฿${total}`;
                          }

                          if (status == "Post-Consult" && time !== "") {
                            // txt = `${fullname}\n${type}\nTime: ${time}`;
                            description = description + `\nTime: ${time}`;
                            // txt = `${fullname}\nTime: ${time}`;
                            txt = `${fullname}`;
                          }

                          const orderElement = document.createElement("div");
                          var elemDiv = document.createElement("div");
                          var orderDiv = document.createElement("div");

                          orderElement.classList.add(
                            "center-align",
                            "border-bottom",
                            "border-white"
                          );
                          orderElement.innerText = order + " " + typeSymbol;
                          orderElement.title = description;

                          if (email) {
                            const iconElement = document.createElement("i");
                            iconElement.classList.add(
                              "flaticon-381-compass-2",
                              "pl-2"
                            );
                            iconElement.style.color = "#fffff";
                            orderElement.appendChild(iconElement); // Append the icon to the order element
                          }

                          // Create a new element for the event title and description
                          const titleElement = document.createElement("div");
                          titleElement.classList.add("left-align");
                          titleElement.innerText = txt;
                          titleElement.title = description;

                          const statusElement = document.createElement("div");
                          statusElement.classList.add(
                            "text-truncate",
                            "left-align"
                          );
                          statusElement.innerText = status;
                          statusElement.title = description;

                          return {
                            domNodes: [
                              orderElement,
                              titleElement,
                              statusElement,
                            ],
                          };
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              <Col xl={10}>
                <div class="media d-flex align-items-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              </Col>
            )}

            <Col xl={2}>
              <Card>
                <Card.Body className="p-2">
                  <div className="col p-0 m-0">
                    {this.statusList.map((element, index) => {
                      return (
                        <div className="d-flex p-1 align-items-center">
                          <span
                            className="badge width30 height30 text-white"
                            style={{ backgroundColor: this.colorList[index] }}
                          >
                            {" "}
                          </span>
                          <text className="mb-2 mt-1 ml-1 pl-2 small">
                            {element}
                          </text>
                        </div>
                      );
                    })}
                  </div>
                  {!["sale", "staff", "sleep_technician"].includes(
                    this.context.currentUser.roles[0]
                  ) && (
                    <>
                      {" "}
                      <div className="row px-0 ml-2 mt-4">
                        <h4 className="text-black">
                          <b>Add Booking</b>
                        </h4>
                      </div>
                      <div className="mt-2" id="external-events">
                        {this.state.events.map((event) => (
                          <div
                            className="fc-event mt-0 ml-0 mb-2 btn btn-block btn-primary"
                            title={event.title}
                            data={event.id}
                            key={event.id}
                          >
                            {event.title}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* addEventModal */}
          <Modal
            className="fade bd-example-modal-lg"
            show={this.state.isAddEventModal}
            onHide={() => this.onHideAddEventModal()}
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>Add Event</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => this.onHideAddEventModal()}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    {/* <this.PatientDropDownForm
                      title="order"
                      id="order"
                      refvalue={this.orderRef}
                      selected={
                        this.state.eventTmp.extendedProps.order == "" ||
                        this.state.eventTmp.extendedProps.order == null ||
                        this.state.eventTmp.extendedProps.order == undefined
                          ? "จองคิว"
                          : this.state.eventTmp.extendedProps.order
                      }
                      options={this.orderList}
                    ></this.PatientDropDownForm> */}
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Order
                      </label>

                      <div className="col-lg-5">
                        <input
                          type="text"
                          className="form-control"
                          id="val-hn"
                          name="val-hn"
                          placeholder="Order"
                          ref={this.orderRef}
                          defaultValue={this.state.eventTmp.extendedProps.order}
                          // ref={emailRef}
                          // value=""
                        />
                      </div>
                    </div>
                    <this.PatientDropDownForm
                      title="Node"
                      id="node"
                      refvalue={this.nodeRef}
                      selected={this.state.eventTmp.extendedProps.node}
                      options={this.state.nodeList}
                      onChange={(e) => {
                        this.setState({
                          customerList: this.CustomerList(e.target.value),
                        });
                      }}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Customer"
                      id="customer"
                      refvalue={this.customerRef}
                      selected={this.state.eventTmp.extendedProps.customer}
                      options={this.state.customerList}
                    ></this.PatientDropDownForm>

                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        HN (Company Number)
                      </label>

                      <div className="col-lg-5">
                        <input
                          type="text"
                          className="form-control"
                          id="val-hn"
                          name="val-hn"
                          placeholder="HN"
                          ref={this.hnRef}
                          defaultValue={this.state.eventTmp.extendedProps.hn}
                          // ref={emailRef}
                          // value=""
                        />
                      </div>
                      <div className="col-lg-2">
                        <Button
                          variant=""
                          type="button"
                          className="btn btn-secondary"
                          onClick={this.onSearchPatient}
                          size="sm"
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                    {this.state.isNewPatient ? (
                      <div className="form-group row">
                        <label
                          className="col-lg-3 col-form-label"
                          htmlFor="val-username"
                        ></label>

                        <div className="col-lg-4">
                          <label className="text-danger">* New Patient</label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {this.state.eventTmp.extendedProps.hn == "" ||
                    this.state.eventTmp.extendedProps.hn == null ||
                    this.state.eventTmp.extendedProps.hn == undefined ? (
                      <>
                        {" "}
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Full Name
                          </label>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="Firstname"
                              ref={this.firstnameRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.firstname
                              }
                            />
                          </div>
                          <div className="col-lg-4">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="Lastname"
                              ref={this.lastnameRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.lastname
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Gender
                          </label>

                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="Gender"
                              ref={this.genderRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.gender
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Phone
                          </label>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="val-phone"
                              name="val-phone"
                              placeholder="Phone"
                              ref={this.phoneRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.phone
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Referring Hospital Number
                          </label>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="val-phone"
                              name="val-phone"
                              placeholder=""
                              ref={this.hospitalNumberRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.hospitalNumber
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <this.PatientTextInfo
                          title="Full Name"
                          value={
                            this.state.eventTmp.extendedProps.firstname +
                            " " +
                            this.state.eventTmp.extendedProps.lastname
                          }
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Gender"
                          value={this.state.eventTmp.extendedProps.gender}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Phone"
                          value={this.state.eventTmp.extendedProps.phone}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Referring Hospital Number"
                          value={
                            this.state.eventTmp.extendedProps.hospitalNumber
                          }
                          bold={true}
                        />
                      </>
                    )}

                    <this.PatientDropDownForm
                      title="Health Insurance"
                      id="healthInsurance"
                      refvalue={this.healthInsuranceRef}
                      selected={
                        this.state.eventTmp.extendedProps.healthInsurance
                      }
                      options={this.paymentPrivilages}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Type"
                      id="type"
                      refvalue={this.typeRef}
                      selected={this.state.eventTmp.extendedProps.type}
                      options={this.typeList}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Status"
                      id="status"
                      refvalue={this.statusRef}
                      selected={this.state.eventTmp.extendedProps.status}
                      options={this.statusList}
                      onChange={this.checkStatus}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Recording Tech"
                      id="techrecord"
                      refvalue={this.techRecordRef}
                      selected={this.state.eventTmp.extendedProps.techrecord}
                      options={this.state.techrecordList}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Scoring Tech"
                      id="technician"
                      refvalue={this.techRef}
                      selected={this.state.eventTmp.extendedProps.technician}
                      options={this.state.technicianList}
                    ></this.PatientDropDownForm>
                    <this.PatientDropDownForm
                      title="Doctor"
                      id="doctor"
                      refvalue={this.docRef}
                      selected={this.state.eventTmp.extendedProps.doctor}
                      options={this.state.doctorList}
                    ></this.PatientDropDownForm>
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Referring Doctor
                      </label>
                      <div className="col-lg-5">
                        <input
                          type="text"
                          className="form-control"
                          id="val-refDoctor"
                          name="val-refDoctor"
                          placeholder="Referring Doctor"
                          ref={this.referDocRef}
                          defaultValue={
                            this.state.eventTmp.extendedProps.referDoctor
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        AHI
                      </label>
                      <div className="col-lg-5">
                        <input
                          type="text"
                          className="form-control"
                          id="val-refDoctor"
                          name="val-refDoctor"
                          placeholder=""
                          ref={this.ahiRef}
                          defaultValue={this.state.eventTmp.extendedProps.ahi}
                        />
                      </div>
                    </div>
                    {this.state.isDatetime && (
                      <>
                        {" "}
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Time
                          </label>
                          <div className="col-lg-2 mt-2">
                            <input
                              type={"time"}
                              className="form-control"
                              defaultValue={
                                this.state.eventTmp.extendedProps.time
                              }
                              // defaultValue={new Date(this.state.dateTmp + "T00:00")}
                              onChange={(event, props) =>
                                this.handleTime(event, props)
                              }
                            ></input>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Date
                      </label>
                      <div className="col-lg-8 mt-2">
                        <b>{this.state.eventTmp.startStr}</b>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-lg-3 col-form-label"
                        htmlFor="val-username"
                      >
                        Note
                      </label>
                      <div className="col-lg-5">
                        <textarea
                          type="area"
                          className="form-control"
                          id="val-phone"
                          name="val-phone"
                          rows="4"
                          placeholder="Note"
                          ref={this.noteRef}
                          defaultValue={this.state.eventTmp.extendedProps.note}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-lg-5 col-form-label mt-2">
                        <b htmlFor="val-username">Add Item</b>
                        <Button
                          variant="secondary"
                          size="xs"
                          className="ml-2"
                          onClick={() => {
                            var tmpItems = this.state.items;
                            var tmpItemList = this.state.itemList;
                            var tmp = {
                              id: tmpItems.length + 1,
                              itemcode: tmpItemList[0].itemcode,
                              item: tmpItemList[0].item,
                              unitcost: tmpItemList[0].unitcost,
                              qty: 1,
                              discount: 0,
                              total: tmpItemList[0].unitcost,
                            };
                            tmpItems = [...tmpItems, tmp];
                            this.setState(
                              {
                                items: tmpItems,
                              },
                              () => {
                                this.getTotalValue();
                              }
                            );
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="center">No.</th>
                            <th className="center width300">Item Name</th>
                            <th className="center width100">Item Code</th>
                            <th className="center width150">Unit Cost (THB)</th>
                            <th className="center width100">Qty</th>
                            <th className="center">Discount (THB)</th>
                            <th className="center">Total (THB)</th>
                            <th className="center"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.items.map((element, index) => {
                            return (
                              <tr>
                                <td className="">{element.id}</td>
                                <td>
                                  <select
                                    className="form-control"
                                    id={"item" + String(index)}
                                    name={"item" + String(index)}
                                    onChange={(evt) => {
                                      var item = evt.target.value;
                                      var tmpItems = this.state.items;

                                      var indexOfItemList =
                                        this.state.itemList.findIndex(
                                          (obj) => obj.item === item
                                        );
                                      // console.log(
                                      //   "selected item: ",
                                      //   this.state.itemList[indexOfItemList]
                                      // );
                                      // console.log(
                                      //   "unitcost: ",
                                      //   this.state.itemList[indexOfItemList]
                                      //     .unitcost
                                      // );
                                      tmpItems[index].itemcode =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].itemcode;
                                      tmpItems[index].item =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].item;
                                      tmpItems[index].unitcost =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].unitcost;
                                      tmpItems[index].qty = 1;
                                      tmpItems[index].discount = 0;
                                      tmpItems[index].total =
                                        this.state.itemList[
                                          indexOfItemList
                                        ].unitcost;
                                      this.setState({ items: tmpItems }, () => {
                                        this.getTotalValue();
                                      });
                                    }}
                                  >
                                    {this.state.itemList.map((value) => (
                                      <option
                                        value={value.item}
                                        selected={
                                          this.state.items[index].item ==
                                          value.item
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {value.item}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td className="left strong">
                                  {" "}
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    id={"val-item" + String(index)}
                                    name={"val-item" + String(index)}
                                    placeholder="Item"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "item",
                                        index,
                                        value
                                      )
                                    }
                                    defaultValue={this.state.items[index].item}
                                  /> */}
                                  <b>{this.state.items[index].itemcode}</b>
                                </td>
                                <td className="left">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-unitcost" + String(index)}
                                    name={"val-unitcost" + String(index)}
                                    placeholder="Unit Cost"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "unitcost",
                                        index,
                                        value
                                      )
                                    }
                                    // defaultValue={
                                    //   this.state.items[index].unitcost
                                    // }
                                    value={this.state.items[index].unitcost}
                                  />
                                </td>
                                <td className="right">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-qty" + String(index)}
                                    name={"val-qty" + String(index)}
                                    placeholder="QTY"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "qty",
                                        index,
                                        value
                                      )
                                    }
                                    // defaultValue={this.state.items[index].qty}
                                    value={this.state.items[index].qty}
                                  />
                                </td>
                                <td className="center">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-discount" + String(index)}
                                    name={"val-discount" + String(index)}
                                    placeholder="Discount"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "discount",
                                        index,
                                        value
                                      )
                                    }
                                    value={this.state.items[index].discount}
                                  />
                                </td>
                                <td className="right">
                                  {" "}
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={"val-total" + String(index)}
                                    name={"val-total" + String(index)}
                                    placeholder="Total"
                                    onChange={(value) =>
                                      this.onUpdateItemValue(
                                        "total",
                                        index,
                                        value
                                      )
                                    }
                                    value={this.state.items[index].total}
                                  />
                                </td>
                                <td>
                                  <Button
                                    className="btn btn-danger btn-xs sharp"
                                    onClick={() => {
                                      var tmpItems = this.state.items;
                                      // console.log("items: ", tmpItems);
                                      tmpItems = tmpItems.filter(
                                        (item) => item.id != tmpItems[index].id
                                      );
                                      for (
                                        let i = 0;
                                        i < tmpItems.length;
                                        i++
                                      ) {
                                        tmpItems[i].id = i + 1;
                                      }
                                      // console.log("items: ", tmpItems);
                                      this.setState({ items: tmpItems }, () => {
                                        this.getTotalValue();
                                      });
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-sm-5 ml-auto">
                        <table className="table table-clear">
                          <tbody>
                            <tr>
                              <td className="left">
                                <strong>Total</strong>
                              </td>
                              <td className="right">
                                <strong>
                                  {this.state.totalValue + " THB"}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                onClick={() => this.onHideAddEventModal()}
              >
                Cancel
              </Button>
              <Button
                variant=""
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // console.log("ondrop: ", this.state.eventTmp);
                  let api = this.calendarComponentRef.current.getApi();
                  var allEvents = api.getEvents().filter((obj) => {
                    return (
                      obj.startStr === this.state.eventTmp.startStr &&
                      obj.extendedProps.hn !== "" &&
                      obj.extendedProps.hn != null
                    );
                  });
                  // console.log("allEvents:", allEvents);
                  let listHNEvent = allEvents.filter((element) => {
                    return (
                      element.extendedProps.hn === this.hnRef.current.value
                    );
                  });
                  // console.log("listHNEvent 1403", listHNEvent);

                  if (listHNEvent.length >= 1) {
                    swal({
                      title: `HN${this.hnRef.current.value} is already booking on this day !`,
                      text: `Please recheck booking on ${this.state.eventTmp.startStr}`,
                      icon: "warning",
                      buttons: ["Cancel", "Confirm"],
                      dangerMode: true,
                    }).then((willBooking) => {
                      if (willBooking) {
                        this.onAddEvent();
                      }
                    });
                  } else {
                    this.onAddEvent();
                  }
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* NotiModal */}
          <Modal
            className="fade bd-example-modal-lg"
            show={this.state.isNoti}
            onHide={() => this.onHideNoti()}
            size="xl"
          >
            <Modal.Body>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    <this.PatientTextInfo
                      title="Order"
                      value={this.state.eventTmp.extendedProps.order}
                      bold={true}
                    ></this.PatientTextInfo>
                    <this.PatientTextInfo
                      title="Node"
                      value={this.state.eventTmp.extendedProps.node}
                      bold={true}
                    ></this.PatientTextInfo>
                    <this.PatientTextInfo
                      title="Customer"
                      value={this.state.eventTmp.extendedProps.customer}
                      bold={true}
                    ></this.PatientTextInfo>
                    <this.PatientLinkInfo
                      title="HN (Company Number)"
                      value={this.state.eventTmp.extendedProps.hn}
                      bold={true}
                      path={
                        "/patient-page/" + this.state.eventTmp.extendedProps.hn
                      }
                    ></this.PatientLinkInfo>
                    <this.PatientLinkInfo
                      title="Full Name"
                      value={
                        this.state.eventTmp.extendedProps.firstname +
                        " " +
                        this.state.eventTmp.extendedProps.lastname
                      }
                      bold={true}
                      path={
                        "/patient-page/" + this.state.eventTmp.extendedProps.hn
                      }
                    ></this.PatientLinkInfo>
                    <this.PatientTextInfo
                      title="Gender"
                      value={this.state.eventTmp.extendedProps.gender}
                      bold={true}
                    ></this.PatientTextInfo>
                    <this.PatientTextInfo
                      title="Phone"
                      value={this.state.eventTmp.extendedProps.phone}
                      bold={true}
                    ></this.PatientTextInfo>
                    <this.PatientTextInfo
                      title="Referring Hospital Number"
                      value={this.state.eventTmp.extendedProps.hospitalNumber}
                      bold={true}
                    ></this.PatientTextInfo>
                    <this.PatientTextInfo
                      title="Health Insurance"
                      value={this.state.eventTmp.extendedProps.healthInsurance}
                      bold={true}
                    ></this.PatientTextInfo>
                    <this.PatientTextInfo
                      title="Type"
                      value={this.state.eventTmp.extendedProps.type}
                      bold={true}
                    ></this.PatientTextInfo>

                    <this.PatientTextInfo
                      title="Status"
                      value={this.state.eventTmp.extendedProps.status}
                      bold={true}
                    ></this.PatientTextInfo>

                    <this.PatientTextInfo
                      title="Recording Tech"
                      value={this.state.eventTmp.extendedProps.techrecord}
                      bold={true}
                    ></this.PatientTextInfo>

                    <this.PatientTextInfo
                      title="Scoring Tech"
                      value={this.state.eventTmp.extendedProps.technician}
                      bold={true}
                    ></this.PatientTextInfo>

                    <this.PatientTextInfo
                      title="Doctor"
                      value={this.state.eventTmp.extendedProps.doctor}
                      bold={true}
                    ></this.PatientTextInfo>

                    <this.PatientTextInfo
                      title="Date"
                      value={this.state.eventTmp.startStr}
                      bold={true}
                    ></this.PatientTextInfo>
                    {/* <this.PatientTextInfo
                      title="Note"
                      value={this.state.eventTmp.note}
                      bold={true}
                    /> */}
                    <this.PatientTextInfo
                      title="Note"
                      value={this.state.eventTmp.extendedProps.note}
                      bold={true}
                    ></this.PatientTextInfo>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="center">No.</th>
                            <th className="center">Item Name</th>
                            <th className="center">Item Code</th>
                            <th className="center">Unit Cost (THB)</th>
                            <th className="center">Qty</th>
                            <th className="center">Discount (THB)</th>
                            <th className="center">Total (THB)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.items.map((element, index) => {
                            return (
                              <tr>
                                <td className="">
                                  <b>{element.id}</b>
                                </td>
                                <td>
                                  <b>{element.item}</b>
                                </td>
                                <td className="left strong">
                                  <b>{element.itemcode}</b>
                                </td>
                                <td className="left">
                                  {" "}
                                  <b>{element.unitcost}</b>
                                </td>
                                <td className="right">
                                  {" "}
                                  <b>{element.qty}</b>
                                </td>
                                <td className="center">
                                  {" "}
                                  <b>{element.discount}</b>
                                </td>
                                <td className="right">
                                  {" "}
                                  <b>{element.total}</b>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-sm-5 ml-auto">
                        <table className="table table-clear">
                          <tbody>
                            <tr>
                              <td className="left">
                                <strong>Total</strong>
                              </td>
                              <td className="right">
                                <strong>
                                  {this.state.totalValue + " THB"}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-0">
              <Button
                variant=""
                type="button"
                className="btn btn-primary"
                onClick={() => this.onHideNoti()}
              >
                OK
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <EditEvent /> */}
          <Modal
            className="fade bd-example-modal-lg"
            show={this.state.isEditEventModal}
            onHide={() => {
              this.onHideEditEventModal();
              let evtmp = this.state.eventTmp;
              evtmp.setStart(new Date(this.state.resetDateTmp));
              evtmp.setEnd(new Date(this.state.resetDateTmp).getDate() + 1);
              this.setState({ eventTmp: evtmp });
            }}
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>Edit Event</Modal.Title>
              <Button
                variant=""
                className="close"
                onClick={() => {
                  this.onHideEditEventModal();
                  this.onCancel();
                }}
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="ml-4">
                {!["staff", "sleep_technician"].includes(
                  this.context.currentUser.roles[0]
                ) && (
                  <Button
                    variant=""
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      if (this.state.eventTmp.extendedProps.hn != "") {
                        this.setState({
                          isEditBookingDetail: !this.state.isEditBookingDetail,
                        });
                      }
                    }}
                    size="sm"
                  >
                    Edit
                  </Button>
                )}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12">
                    {this.state.eventTmp.extendedProps.hn == "" ||
                    this.state.isEditBookingDetail == true ? (
                      <>
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Order
                          </label>

                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="Order"
                              ref={this.orderRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.order
                              }
                            />
                          </div>
                        </div>
                        <this.PatientDropDownForm
                          title="Node"
                          id="node"
                          refvalue={this.nodeRef}
                          selected={this.state.eventTmp.extendedProps.node}
                          options={this.state.nodeList}
                          onChange={(e) => {
                            this.setState({
                              customerList: this.CustomerList(e.target.value),
                            });
                          }}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Customer"
                          id="customer"
                          refvalue={this.customerRef}
                          selected={this.state.eventTmp.extendedProps.customer}
                          options={this.state.customerList}
                        ></this.PatientDropDownForm>
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            HN (Company Number)
                          </label>

                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="val-hn"
                              name="val-hn"
                              placeholder="HN"
                              ref={this.hnRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.hn
                              }
                              // ref={emailRef}
                              // value=""
                            />
                          </div>
                          <div className="col-lg-2">
                            <Button
                              variant=""
                              type="button"
                              className="btn btn-secondary"
                              onClick={this.onSearchPatient}
                              size="sm"
                            >
                              Search
                            </Button>
                          </div>
                        </div>
                        {this.state.isNewPatient ? (
                          <div className="form-group row">
                            <label
                              className="col-lg-3 col-form-label"
                              htmlFor="val-username"
                            ></label>

                            <div className="col-lg-4">
                              <label className="text-danger">
                                * New Patient
                              </label>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state.eventTmp.extendedProps.hn == "" ? (
                          <>
                            {" "}
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                Full Name
                              </label>
                              <div className="col-lg-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-hn"
                                  name="val-hn"
                                  placeholder="Firstname"
                                  ref={this.firstnameRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.firstname
                                  }
                                />
                              </div>
                              <div className="col-lg-4">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-hn"
                                  name="val-hn"
                                  placeholder="Lastname"
                                  ref={this.lastnameRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.lastname
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                National ID
                              </label>

                              <div className="col-lg-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-hn"
                                  name="val-hn"
                                  placeholder="National ID"
                                  ref={this.nationalIdRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.nationalId
                                  }
                                />
                              </div>
                            </div> */}
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                Gender
                              </label>
                              <div className="col-lg-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-phone"
                                  name="val-phone"
                                  placeholder="Phone"
                                  ref={this.genderRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.gender
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                Phone
                              </label>
                              <div className="col-lg-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-phone"
                                  name="val-phone"
                                  placeholder="Phone"
                                  ref={this.phoneRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.phone
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                Referring Hospital Number
                              </label>
                              <div className="col-lg-5">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="val-phone"
                                  name="val-phone"
                                  placeholder=""
                                  ref={this.hospitalNumberRef}
                                  defaultValue={
                                    this.state.eventTmp.extendedProps
                                      .hospitalNumber
                                  }
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <this.PatientTextInfo
                              title="Full Name"
                              value={
                                this.state.eventTmp.extendedProps.firstname +
                                " " +
                                this.state.eventTmp.extendedProps.lastname
                              }
                              bold={true}
                            />
                            <this.PatientTextInfo
                              title="Gender"
                              value={this.state.eventTmp.extendedProps.gender}
                              bold={true}
                            />
                            <this.PatientTextInfo
                              title="Phone"
                              value={this.state.eventTmp.extendedProps.phone}
                              bold={true}
                            />
                            <this.PatientTextInfo
                              title="Referring Hospital Number"
                              value={
                                this.state.eventTmp.extendedProps.hospitalNumber
                              }
                              bold={true}
                            />
                          </>
                        )}
                        <this.PatientDropDownForm
                          title="Health Insurance"
                          id="healthInsurance"
                          refvalue={this.healthInsuranceRef}
                          selected={
                            this.state.eventTmp.extendedProps.healthInsurance
                          }
                          options={this.paymentPrivilages}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Type"
                          id="type"
                          refvalue={this.typeRef}
                          selected={this.state.eventTmp.extendedProps.type}
                          options={this.typeList}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Status"
                          id="status"
                          refvalue={this.statusRef}
                          selected={this.state.eventTmp.extendedProps.status}
                          options={this.statusList}
                          onChange={this.checkStatus}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Recording Tech"
                          id="technician"
                          refvalue={this.techRecordRef}
                          selected={
                            this.state.eventTmp.extendedProps.techrecord
                          }
                          options={this.state.techRecordList}
                          // onChange={this.checkStatus}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Scoring Tech"
                          id="technician"
                          refvalue={this.techRef}
                          selected={
                            this.state.eventTmp.extendedProps.technician
                          }
                          options={this.state.technicianList}
                          // onChange={this.checkStatus}
                        ></this.PatientDropDownForm>
                        <this.PatientDropDownForm
                          title="Doctor"
                          id="doctor"
                          refvalue={this.docRef}
                          selected={this.state.eventTmp.extendedProps.doctor}
                          options={this.state.doctorList}
                          // onChange={this.checkStatus}
                        ></this.PatientDropDownForm>
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Referring Doctor
                          </label>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="val-refDoctor"
                              name="val-refDoctor"
                              placeholder="Refer Doctor"
                              ref={this.referDocRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.referDoctor
                              }
                            />
                          </div>
                        </div>
                        {/* get report */}
                        <this.PatientTextInfo
                          title="Report"
                          value={
                            this.state.eventTmp.extendedProps.reports ? (
                              this.state.eventTmp.extendedProps.reports.map(
                                (data, index) => {
                                  return (
                                    <div className="col-lg-12">
                                      <div
                                        className="row"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            this.handleOnClick(
                                              data.hn,
                                              data.id,
                                              data.reportFile[0]
                                            )
                                          }
                                        >
                                          <i
                                            className="flaticon-381-file-2 pr-3"
                                            style={{ color: "#dd0102" }}
                                          ></i>
                                          {data.reportFile[0]}
                                          <br />
                                        </Link>
                                        {![
                                          "sale",
                                          "staff",
                                          "sleep_technician",
                                        ].includes(
                                          this.context.currentUser.roles[0]
                                        ) &&
                                          index == 0 && (
                                            <>
                                              <Button
                                                onClick={this.handleButtonClick}
                                                className="btn-xxs btn-primary"
                                                style={{
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                + Upload
                                              </Button>
                                              <input
                                                type="file"
                                                multiple
                                                ref={this.fileInputRef}
                                                style={{ display: "none" }}
                                                onChange={this.handleFileChange}
                                              />
                                              {[
                                                "Doctor Scored",
                                                "Post-Consult",
                                                "Sale Follow Up",
                                                "Ship",
                                                "Ship [CPAP Purchase]",
                                                "Ship [CPAP Trial]",
                                                "Ship [CPAP Rental]",
                                              ].includes(
                                                this.state.eventTmp
                                                  .extendedProps.status
                                              ) &&
                                                !this.state.eventTmp
                                                  .extendedProps.email && (
                                                  <Button
                                                    onClick={
                                                      this.handleSendEmail
                                                    }
                                                    className="btn-xxs btn-danger"
                                                    style={{
                                                      justifyContent:
                                                        "flex-end",
                                                    }}
                                                    // disabled={!createAdmin}
                                                  >
                                                    <i
                                                      className="flaticon-381-compass-2 pr-1"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    ></i>
                                                    Send Email
                                                  </Button>
                                                )}
                                              {this.state.eventTmp.extendedProps
                                                .email && (
                                                <>
                                                  <label>
                                                    {this.state.eventTmp
                                                      .extendedProps.lastestMail
                                                      ? this.state.eventTmp
                                                          .extendedProps
                                                          .lastestMail
                                                      : "LastestMail"}
                                                  </label>{" "}
                                                  <Button
                                                    onClick={
                                                      this.handleSendEmail
                                                    }
                                                    className="btn-xxs btn-danger"
                                                    style={{
                                                      justifyContent:
                                                        "flex-end",
                                                    }}
                                                    // disabled={!createAdmin}
                                                  >
                                                    <i
                                                      className="flaticon-381-compass-2 pr-1"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    ></i>
                                                    Send Email
                                                  </Button>
                                                </>
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div className="col-lg-6">
                                {![
                                  "sale",
                                  "staff",
                                  "sleep_technician",
                                ].includes(
                                  this.context.currentUser.roles[0]
                                ) && (
                                  <div
                                    className="row"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <div>No Report</div>
                                    <Button
                                      onClick={this.handleButtonClick}
                                      className="btn-xxs btn-primary"
                                      style={{
                                        justifyContent: "flex-end",
                                      }}
                                      // disabled={!createAdmin}
                                    >
                                      + Upload
                                    </Button>
                                    <input
                                      type="file"
                                      multiple
                                      ref={this.fileInputRef}
                                      style={{ display: "none" }}
                                      onChange={this.handleFileChange}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          }
                          bold={true}
                        />

                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            AHI
                          </label>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="val-refDoctor"
                              name="val-refDoctor"
                              placeholder=""
                              ref={this.ahiRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.ahi
                              }
                            />
                          </div>
                        </div>
                        {this.state.eventTmp.extendedProps
                          .latestSleepStudyDate ? (
                          <this.PatientTextInfo
                            title="Sleep Study Date"
                            value={
                              this.state.eventTmp.extendedProps
                                .latestSleepStudyDate
                            }
                            bold={true}
                          />
                        ) : (
                          <></>
                        )}
                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Date
                          </label>
                          <div className="col-lg-2 mt-2">
                            {/* <b>{this.state.eventTmp.startStr}</b> */}
                            {/* {this.state.isDatetime ? (
                              <>
                                <input
                                  type={"datetime-local"}
                                  className="form-control"
                                  defaultValue={this.state.dateTmp + "T00:00"}
                                  // defaultValue={new Date(this.state.dateTmp + "T00:00")}
                                  onChange={(event, props) =>
                                    this.handleDateTime(event, props)
                                  }
                                ></input>
                              </>
                            ) : (
                              <>
                                <DatePicker
                                  className="form-control"
                                  dateFormat="yyyy-MM-dd"
                                  selected={new Date(this.state.dateTmp)}
                                  onChange={(event, props) =>
                                    this.handleDate(event, props)
                                  }
                                />
                              </>
                            )} */}
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                              // todayButton="Today"
                              selected={new Date(this.state.dateTmp)}
                              onChange={(event, props) =>
                                this.handleDate(event, props)
                              }
                            />
                          </div>
                        </div>
                        {this.state.isDatetime ? (
                          <>
                            {" "}
                            <div className="form-group row">
                              <label
                                className="col-lg-3 col-form-label"
                                htmlFor="val-username"
                              >
                                Time
                              </label>
                              <div className="col-lg-2 mt-2">
                                <input
                                  type={"time"}
                                  className="form-control"
                                  defaultValue={
                                    this.state.eventTmp.extendedProps.time
                                  }
                                  // defaultValue={new Date(this.state.dateTmp + "T00:00")}
                                  onChange={(event, props) =>
                                    this.handleTime(event, props)
                                  }
                                ></input>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className="form-group row">
                          <label
                            className="col-lg-3 col-form-label"
                            htmlFor="val-username"
                          >
                            Note
                          </label>
                          <div className="col-lg-5">
                            <textarea
                              type="area"
                              className="form-control"
                              id="val-phone"
                              name="val-phone"
                              rows="4"
                              placeholder="Note"
                              ref={this.noteRef}
                              defaultValue={
                                this.state.eventTmp.extendedProps.note
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-5 col-form-label mt-2">
                            <b htmlFor="val-username">Add Item</b>
                            <Button
                              variant="secondary"
                              size="xs"
                              className="ml-2"
                              onClick={() => {
                                var tmpItems = this.state.items;
                                var tmpItemList = this.state.itemList;
                                var tmp = {
                                  id: tmpItems.length + 1,
                                  itemcode: tmpItemList[0].itemcode,
                                  item: tmpItemList[0].item,
                                  unitcost: tmpItemList[0].unitcost,
                                  qty: 1,
                                  discount: 0,
                                  total: tmpItemList[0].unitcost,
                                };
                                tmpItems = [...tmpItems, tmp];
                                this.setState(
                                  {
                                    items: tmpItems,
                                  },
                                  () => {
                                    this.getTotalValue();
                                  }
                                );
                              }}
                            >
                              +
                            </Button>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th className="center">No.</th>
                                <th className="center width300">Item Name</th>
                                <th className="center width100">Item Code</th>
                                <th className="center width150">
                                  Unit Cost (THB)
                                </th>
                                <th className="center width100">Qty</th>
                                <th className="center">Discount (THB)</th>
                                <th className="center">Total (THB)</th>
                                <th className="center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.items.map((element, index) => {
                                return (
                                  <tr>
                                    <td className="">{element.id}</td>
                                    <td>
                                      <select
                                        className="form-control"
                                        id={"item" + String(index)}
                                        name={"item" + String(index)}
                                        onChange={(evt) => {
                                          var item = evt.target.value;
                                          var tmpItems = this.state.items;

                                          var indexOfItemList =
                                            this.state.itemList.findIndex(
                                              (obj) => obj.item === item
                                            );
                                          // console.log(
                                          //   "selected item: ",
                                          //   this.state.itemList[indexOfItemList]
                                          // );
                                          // console.log(
                                          //   "unitcost: ",
                                          //   this.state.itemList[indexOfItemList]
                                          //     .unitcost
                                          // );
                                          tmpItems[index].itemcode =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].itemcode;
                                          tmpItems[index].item =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].item;
                                          tmpItems[index].unitcost =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].unitcost;
                                          tmpItems[index].qty = 1;
                                          tmpItems[index].discount = 0;
                                          tmpItems[index].total =
                                            this.state.itemList[
                                              indexOfItemList
                                            ].unitcost;
                                          this.setState(
                                            { items: tmpItems },
                                            () => {
                                              this.getTotalValue();
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.itemList.map((value) => (
                                          <option
                                            value={value.item}
                                            selected={
                                              this.state.items[index].item ==
                                              value.item
                                                ? "selected"
                                                : ""
                                            }
                                          >
                                            {value.item}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td className="left strong">
                                      <b>{this.state.items[index].itemcode}</b>
                                    </td>
                                    <td className="left">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-unitcost" + String(index)}
                                        name={"val-unitcost" + String(index)}
                                        placeholder="Unit Cost"
                                        onChange={(data) =>
                                          this.onUpdateItemValue(
                                            "unitcost",
                                            index,
                                            data
                                          )
                                        }
                                        // defaultValue={
                                        //   this.state.items[index].unitcost
                                        // }
                                        value={this.state.items[index].unitcost}
                                      />
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-qty" + String(index)}
                                        name={"val-qty" + String(index)}
                                        placeholder="QTY"
                                        onChange={(data) =>
                                          this.onUpdateItemValue(
                                            "qty",
                                            index,
                                            data
                                          )
                                        }
                                        // defaultValue={this.state.items[index].qty}
                                        value={this.state.items[index].qty}
                                      />
                                    </td>
                                    <td className="center">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-discount" + String(index)}
                                        name={"val-discount" + String(index)}
                                        placeholder="Discount"
                                        onChange={(data) => {
                                          this.onUpdateItemValue(
                                            "discount",
                                            index,
                                            data
                                          );
                                        }}
                                        // defaultValue={
                                        //   this.state.items[index].discount
                                        // }
                                        value={this.state.items[index].discount}
                                      />
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <input
                                        type="number"
                                        className="form-control"
                                        id={"val-total" + String(index)}
                                        name={"val-total" + String(index)}
                                        placeholder="Total"
                                        onChange={(data) =>
                                          this.onUpdateItemValue(
                                            "total",
                                            index,
                                            data
                                          )
                                        }
                                        // defaultValue={this.state.items[index].total}
                                        value={this.state.items[index].total}
                                      />
                                    </td>
                                    <td>
                                      <Button
                                        className="btn btn-danger btn-xs sharp"
                                        onClick={() => {
                                          var tmpItems = this.state.items;
                                          // console.log("items: ", tmpItems);
                                          tmpItems = tmpItems.filter(
                                            (item) =>
                                              item.id != tmpItems[index].id
                                          );
                                          for (
                                            let i = 0;
                                            i < tmpItems.length;
                                            i++
                                          ) {
                                            tmpItems[i].id = i + 1;
                                          }
                                          // console.log("items: ", tmpItems);
                                          this.setState(
                                            { items: tmpItems },
                                            () => {
                                              this.getTotalValue();
                                            }
                                          );
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-5 ml-auto">
                            <table className="table table-clear">
                              <tbody>
                                <tr>
                                  <td className="left">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="right">
                                    <strong>
                                      {this.state.totalValue + " THB"}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <this.PatientTextInfo
                          title="Order"
                          value={this.state.eventTmp.extendedProps.order}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Node"
                          value={this.state.eventTmp.extendedProps.node}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Customer"
                          value={this.state.eventTmp.extendedProps.customer}
                          bold={true}
                        />
                        <this.PatientLinkInfo
                          title="HN (Company Number)"
                          value={this.state.eventTmp.extendedProps.hn}
                          bold={true}
                          path={
                            "/patient-page/" +
                            this.state.eventTmp.extendedProps.hn
                          }
                        ></this.PatientLinkInfo>
                        <this.PatientLinkInfo
                          title="Full Name"
                          value={
                            this.state.eventTmp.extendedProps.firstname +
                            " " +
                            this.state.eventTmp.extendedProps.lastname
                          }
                          bold={true}
                          path={
                            "/patient-page/" +
                            this.state.eventTmp.extendedProps.hn
                          }
                        ></this.PatientLinkInfo>
                        <this.PatientTextInfo
                          title="Gender"
                          value={this.state.eventTmp.extendedProps.gender}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Phone"
                          value={this.state.eventTmp.extendedProps.phone}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Referring Hospital Number"
                          value={
                            this.state.eventTmp.extendedProps.hospitalNumber
                          }
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Health Insurance"
                          value={
                            this.state.eventTmp.extendedProps.healthInsurance
                          }
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Type"
                          value={this.state.eventTmp.extendedProps.type}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Status"
                          value={this.state.eventTmp.extendedProps.status}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Recording Tech"
                          value={this.state.eventTmp.extendedProps.techrecord}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Scoring Tech"
                          value={this.state.eventTmp.extendedProps.technician}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Doctor"
                          value={this.state.eventTmp.extendedProps.doctor}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Referring Doctor"
                          value={this.state.eventTmp.extendedProps.referDoctor}
                          bold={true}
                        />
                        {/* get report */}
                        <this.PatientTextInfo
                          title="Report"
                          value={
                            this.state.eventTmp.extendedProps.reports ? (
                              this.state.eventTmp.extendedProps.reports.map(
                                (data, index) => {
                                  const filename = data.reportFile[0];
                                  var reportColor = "";
                                  // console.log(filename);
                                  if (
                                    filename
                                      .toLowerCase()
                                      .includes("medical") ||
                                    filename.includes("cert") ||
                                    filename.includes("ใบรับรองแพทย์")
                                  ) {
                                    reportColor = "#2D573F";
                                  } else {
                                    reportColor = "#dd0102";
                                  }
                                  return (
                                    <div className="col-lg-12">
                                      <div
                                        className="row"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            this.handleOnClick(
                                              data.hn,
                                              data.id,
                                              data.reportFile[0]
                                            )
                                          }
                                        >
                                          <i
                                            className="flaticon-381-file-2 pr-3"
                                            style={{ color: reportColor }}
                                          ></i>
                                          {data.reportFile[0]}
                                          <br />
                                        </Link>
                                        {![
                                          "sale",
                                          "staff",
                                          "sleep_technician",
                                        ].includes(
                                          this.context.currentUser.roles[0]
                                        ) &&
                                          index == 0 && (
                                            <>
                                              <Button
                                                onClick={this.handleButtonClick}
                                                className="btn-xxs btn-primary"
                                                style={{
                                                  justifyContent: "flex-end",
                                                }}
                                                // disabled={!createAdmin}
                                              >
                                                + Upload
                                              </Button>
                                              <input
                                                type="file"
                                                multiple
                                                ref={this.fileInputRef}
                                                style={{ display: "none" }}
                                                onChange={this.handleFileChange}
                                              />
                                              {[
                                                "Doctor Scored",
                                                "Post-Consult",
                                                "Sale Follow Up",
                                                "Ship",
                                                "Ship [CPAP Purchase]",
                                                "Ship [CPAP Trial]",
                                                "Ship [CPAP Rental]",
                                              ].includes(
                                                this.state.eventTmp
                                                  .extendedProps.status
                                              ) &&
                                                !this.state.eventTmp
                                                  .extendedProps.email && (
                                                  <Button
                                                    onClick={
                                                      this.handleSendEmail
                                                    }
                                                    className="btn-xxs btn-danger"
                                                    style={{
                                                      justifyContent:
                                                        "flex-end",
                                                    }}
                                                    // disabled={!createAdmin}
                                                  >
                                                    <i
                                                      className="flaticon-381-compass-2 pr-1"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    ></i>{" "}
                                                    Send Email
                                                  </Button>
                                                )}
                                              {this.state.eventTmp.extendedProps
                                                .email && (
                                                <>
                                                  <label>
                                                    {this.state.eventTmp
                                                      .extendedProps.lastestMail
                                                      ? this.state.eventTmp
                                                          .extendedProps
                                                          .lastestMail
                                                      : "LastestMail"}
                                                  </label>
                                                  <Button
                                                    onClick={
                                                      this.handleSendEmail
                                                    }
                                                    className="btn-xxs btn-danger"
                                                    style={{
                                                      justifyContent:
                                                        "flex-end",
                                                    }}
                                                    // disabled={!createAdmin}
                                                  >
                                                    <i
                                                      className="flaticon-381-compass-2 pr-1"
                                                      style={{
                                                        color: "#ffffff",
                                                      }}
                                                    ></i>
                                                    Send Email
                                                  </Button>
                                                </>
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div className="col-lg-6">
                                {![
                                  "sale",
                                  "staff",
                                  "sleep_technician",
                                ].includes(
                                  this.context.currentUser.roles[0]
                                ) && (
                                  <div
                                    className="row"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <div>No Report</div>
                                    <Button
                                      onClick={this.handleButtonClick}
                                      className="btn-xxs btn-primary"
                                      style={{
                                        justifyContent: "flex-end",
                                      }}
                                      // disabled={!createAdmin}
                                    >
                                      + Upload
                                    </Button>
                                    <input
                                      type="file"
                                      multiple
                                      ref={this.fileInputRef}
                                      style={{ display: "none" }}
                                      onChange={this.handleFileChange}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          }
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="AHI"
                          value={this.state.eventTmp.extendedProps.ahi}
                          bold={true}
                        />
                        {this.state.eventTmp.extendedProps
                          .latestSleepStudyDate ? (
                          <this.PatientTextInfo
                            title="Sleep Study Date"
                            value={
                              this.state.eventTmp.extendedProps
                                .latestSleepStudyDate
                            }
                            bold={true}
                          />
                        ) : (
                          <></>
                        )}
                        <this.PatientTextInfo
                          title="Date"
                          value={this.state.eventTmp.startStr}
                          bold={true}
                        />
                        <this.PatientTextInfo
                          title="Note"
                          value={this.state.eventTmp.extendedProps.note}
                          bold={true}
                        />
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th className="center">No.</th>
                                <th className="center">Item Name</th>
                                <th className="center">Item Code</th>
                                <th className="center">Unit Cost (THB)</th>
                                <th className="center">Qty</th>
                                <th className="center">Discount (THB)</th>
                                <th className="center">Total (THB)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.items.map((element, index) => {
                                return (
                                  <tr>
                                    <td className="">
                                      <b>{element.id}</b>
                                    </td>
                                    <td>
                                      <b>{element.item}</b>
                                    </td>
                                    <td className="left strong">
                                      <b>{element.itemcode}</b>
                                    </td>
                                    <td className="left">
                                      {" "}
                                      <b>{element.unitcost}</b>
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <b>{element.qty}</b>
                                    </td>
                                    <td className="center">
                                      {" "}
                                      <b>{element.discount}</b>
                                    </td>
                                    <td className="right">
                                      {" "}
                                      <b>{element.total}</b>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-5 ml-auto">
                            <table className="table table-clear">
                              <tbody>
                                <tr>
                                  <td className="left">
                                    <strong>Total</strong>
                                  </td>
                                  <td className="right">
                                    <strong>
                                      {this.state.totalValue + " THB"}
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              {!["staff", "sleep_technician"].includes(
                this.context.currentUser.roles[0]
              ) && (
                <>
                  {" "}
                  {!["sale"].includes(this.context.currentUser.roles[0]) ? (
                    <div>
                      <Button
                        variant=""
                        className="btn btn-danger"
                        onClick={() => {
                          swal({
                            title: "Are you sure to delete ?",
                            text: "Once deleted, you will not be able to recover this booking event !",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              this.onRemoveEvent(true);
                              // swal("Your booking event has been deleted!", {
                              //   icon: "success",
                              // });
                            }
                          });
                          this.onHideEditEventModal();
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div>
                    <Button
                      variant="light"
                      onClick={() => {
                        this.onHideEditEventModal();
                        this.onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant=""
                      type="button"
                      className="btn btn-primary ml-2"
                      onClick={() => {
                        let api = this.calendarComponentRef.current.getApi();
                        var allEvents = api.getEvents().filter((obj) => {
                          return (
                            obj.startStr === this.state.eventTmp.startStr &&
                            obj.extendedProps.hn !== ""
                          );
                        });
                        let listHNEvent = allEvents.filter((element) => {
                          return (
                            element.extendedProps.hn ===
                            this.state.eventTmp.extendedProps.hn
                          );
                        });
                        // console.log("listHNEvent 1954", listHNEvent);
                        let isExists = false;
                        // if (listHNEvent.length > 1) {
                        //   isExists = true;
                        // }

                        if (listHNEvent.length > 1) {
                          swal({
                            title: `HN${this.state.eventTmp.extendedProps.hn} is already booking on this day !`,
                            text: `Please recheck booking on ${this.state.eventTmp.startStr}`,
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willBooking) => {
                            if (willBooking) {
                              this.onEditEvent();
                            }
                          });
                        } else {
                          this.onEditEvent();
                        }
                      }}
                    >
                      Done
                    </Button>
                  </div>
                </>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default EventCalendar;
