const CpapRegisterData = {
  SerialNumber: "",
  CpapID: "",
  Brand: "",
  Model: "",
  Type: "",
  Vendor: "",
  DeviceNo: "",
  LotNo: "",
  IncomingDate: "",
  WarrantyStart: "",
  WarrantyEnd: "",
  MaintenancePeriod: "",
  Condition: "",
  CreatedBy: "",
  CreatedDateTime: "",
  UpdatedDateTime: "",
  BrandOther: "",
  TypeOther: "",
  LastHN: "",
  LastBorrowDate: null,
  LastDueDate: null,
  LastBorrowCondition: "",
  LastReturnDate: null,
  LastReturnCondition: "",
  LastNote: "",
  Activity: "",
  Status: "",
  LastTel: "",
  LastFirstName: "",
  LastLastName: "",
};

const Brand = ["ResMed", "Apex", "BMC", "Philip", "Yuwell", "VentMed","Topson", "Other"];
const Type = ["APAP", "BPAP", "CPAP", "Other"];

const CpapRegisterChoices = {
  SerialNumber: "",
  CpapID: "",
  Brand: Brand,
  Model: "",
  Type: Type,
  Vendor: "",
  DeviceNo: "",
  LotNo: "",
  IncomingDate: "",
  WarrantyStart: "",
  WarrantyEnd: "",
  MaintenancePeriod: ["6 Months", "1 Year"],
  Condition: "",
  CreatedBy: "",
  CreatedDateTime: "",
  UpdatedDateTime: "",
  Activity: [
    "ยืมเครื่อง",
    "คืนเครื่อง",
    "ส่งทำความสะอาด",
    "ส่งซ่อม",
    "ลงทะเบียน",
  ],
  Status: ["ว่าง", "ไม่ว่าง", "ยกเลิกใช้งาน", "ขาย"],
  BrandOther: "",
  TypeOther: "",
};

// ว่าง ครั้งแรกสถานะคือลงทะเบียน เมื่อกดส่งคืนเครื่อง
// ไม่ว่าง ตอนเครื่องถูกยืม , ตอนเครื่องส่งซ่อม
// activity : ทำความสะอาด
// ยกเลิกใช้งาน ตอน กด On / Off ว่าให้ใช้งานหรือไม่ใช้งาน

export { CpapRegisterData, CpapRegisterChoices };
